import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { NgbActiveModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { UserSettingsService } from './user-settings.service';
import { environment } from '../environments/environment';

import { Subject, Observable } from 'rxjs';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Component({
  selector: 'app-links-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title"><span class="oi oi-link-intact mr-2"></span>Links</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="form-text">
        This dialog helps you conveniently generate copyable links. You are not required to use it if you prefer
        to generate links yourself.
      </p>
      <p>
        Once you have generated your links you need to set your domains DNS <code>A record</code> to your server IP
        <code>{{node.id}}</code>
      </p>
      <label>Your domain</label>
        <input #tdomain
        [(ngModel)]="domain"
        (ngModelChange)="updateLinks()"
        [ngbTypeahead]="domainSearch"
        (focus)="domainFocus$.next($event.target.value)"
        (click)="domainClick$.next($event.target.value)"
        [resultTemplate]="domainrt"
        (selectItem)="domainSelected($event)"
        name="domainentry"
        type="text"
        class="form-control"
        placeholder="example.com"
        #domainSearchInstance="ngbTypeahead">

      <small class="form-text text-muted">
        Using your IP address to test is fine. For going live, <strong>you should use a domain</strong>.
      </small>
      <hr>
      <label>Campaign link</label>
      <div class="input-group">
        <input #cl type="text" class="form-control" [ngModel]="campLink" (focus)="$event.target.select()" readonly>
        <div class="input-group-append">
          <button [ngxClipboard]="cl" (cbOnSuccess)="sc1=true"
           (mouseleave)="sc1=false"
           class="btn btn-outline-secondary"
           type="button"
           ngbTooltip="Copy to clipboard" container="body">
            <span class="oi oi-clipboard"></span>
          </button>
          <button
           (click)="openLink()"
           class="btn btn-outline-secondary"
           type="button"
           ngbTooltip="Visit" container="body">
            <span class="oi oi-external-link"></span>
          </button>
        </div>
      </div>
      <div *ngIf="sc1" class="float-left">
        <small class="form-text text-success">Copied!</small>
      </div>
      <hr class="mt-4">
      <label>Conversion postback link</label>
      <span *ifVersion="'>=1.3.0';compare:node.version">
        <div class="input-group">
          <input #pbl type="text" class="form-control" [ngModel]="centralpbLink" (focus)="$event.target.select()" readonly>
          <div class="input-group-append">
            <button [ngxClipboard]="pbl" (cbOnSuccess)="sc2=true"
            (mouseleave)="sc2=false"
            class="btn btn-outline-secondary"
            type="button"
            ngbTooltip="Copy to clipboard" container="body">
              <span class="oi oi-clipboard"></span>
            </button>
          </div>
        </div>
      </span>
      <span *ifVersion="'<1.3.0';compare:node.version">
        <div class="input-group">
          <input #pbl type="text" class="form-control" [ngModel]="pbLink" (focus)="$event.target.select()" readonly>
          <div class="input-group-append">
            <button [ngxClipboard]="pbl" (cbOnSuccess)="sc2=true"
            (mouseleave)="sc2=false"
            class="btn btn-outline-secondary"
            type="button"
            ngbTooltip="Copy to clipboard" container="body">
              <span class="oi oi-clipboard"></span>
            </button>
          </div>
        </div>
      </span>
      <div *ngIf="sc2" class="float-left">
        <small class="form-text text-success">Copied!</small>
      </div>

      <span *ifVersion="'>=2.5.0';compare:node.version">
        <hr class="mt-4">
        <label>JS Code</label> <span *ngIf="!camp.noipfraud.enabled" class="badge badge-warning ml-1">Fraud Detection required</span>
        <ul class="nav nav-tabs mb-3">
          <li class="nav-item">
            <a (click)="tab = 0; updateJSLink(); false;" class="nav-link" [ngClass]="{'active': tab == 0}" href="">
              Plain JS
            </a>
          </li>
          <li class="nav-item">
            <a (click)="tab = 1; updateJSLink(); false;" class="nav-link" [ngClass]="{'active': tab == 1}" href="">
              Base64 JS
            </a>
          </li>
        </ul>
        <div class="input-group">
          <input #pbl type="text" class="form-control" [ngModel]="activeJsLink" (focus)="$event.target.select()" readonly>
          <div class="input-group-append">
            <button [ngxClipboard]="pbl" (cbOnSuccess)="sc3=true"
            [disabled]="!camp.noipfraud.enabled"
            (mouseleave)="sc3=false"
            class="btn btn-outline-secondary"
            type="button"
            ngbTooltip="Copy to clipboard" container="body">
              <span class="oi oi-clipboard"></span>
            </button>
          </div>
        </div>
        <small class="form-text text-muted">
          You can use this JS snippet to use N2 on pages such as Shopify.
          <a href="https://docs.n2.app/docs/inject" target="_blank">More Info</a>
        </small>
      </span>
      <div *ngIf="sc3" class="float-left">
        <small class="form-text text-success">Copied!</small>
      </div>
    </div>

    <ng-template #domainrt let-r="result" let-t="term">
      <span class="oi oi-circle-x oi-sm delete-icon" *ngIf="r !== showMoreText" (click)="deleted = true"></span>
      <span [ngClass]="{disabled: r === showMoreText}">
        <ngb-highlight [result]="r" [term]="t"></ngb-highlight>
      </span>
    </ng-template>
  `,
  styles: [`
    .delete-icon {
      margin-top: 4px;
      margin-left: 20px;
      float: right;
    }
    .delete-icon:hover {
      color: #212529;
    }
    .disabled {
      pointer-events: none;
      opacity: 0.65;
    }`]

})
export class LinksModalComponent implements OnInit, OnDestroy {
  @ViewChild('tdomain') private elName: ElementRef;
  @ViewChild('domainSearchInstance') domainSearchInstance: NgbTypeahead;

  domainFocus$ = new Subject<string>();
  domainClick$ = new Subject<string>();

  readonly showMoreText = 'Type to show more...';
  readonly maxListSize = 6;

  deleted: boolean;
  camp: any;
  node: any;
  domain: string;

  sc1: boolean;
  sc2: boolean;
  sc3: boolean;

  campLink: string;
  pbLink: string;
  centralpbLink: string;
  jsLink: string;
  encodedJsLink: string;
  activeJsLink: string;
  tab: number = 0;

  constructor(
    private userSettingsService: UserSettingsService,
    public activeModal: NgbActiveModal
  ) {}

  openLink() {
    window.open(this.campLink);
  }

  updateLinks() {
    let base: string;
    if (this.domain && this.domain.length > 0) {
      base = `https://` + this.domain.replace(/^(https?:\/\/)/g, '').replace(/\/*$/g, '');
    } else {
      base = this.node.id.includes(":") ? `http://[${this.node.id}]` : `http://${this.node.id}`;
    }

    // campaign link
    this.campLink = base + this.camp.route.replace(/\/$/, '');
    // add slash for non-file routes
    if (!this.camp.route.match(/\.[a-zA-Z]{3,4}$/)) {
      this.campLink += '/';
    }

    // postback links
    this.pbLink = base + '/cv?s=NETWORK_SUBID_HERE&payout=';
    this.centralpbLink = environment.nodePostbackUrl + '?s=NETWORK_SUBID_HERE&payout=';

    // javascript iframe deploy link
    this.jsLink = `<script src="${this.campLink}${this.camp.id}.js"></script>`;
    this.encodedJsLink = '<script src="data:text/javascript;base64,' + btoa(`document.open();document.write('${this.jsLink}');document.close();`) + '"></script>';
  }

  updateJSLink() {
    if (this.tab == 0) {
      this.activeJsLink = this.jsLink;
    } else {
      this.activeJsLink = this.encodedJsLink;
    }
  }

  domainSelected(ev: NgbTypeaheadSelectItemEvent) {

    if (ev.item === this.showMoreText) {
      ev.preventDefault();
      return;
    }

    if (this.deleted) {
      ev.preventDefault();
      this.deleted = false;
      this.removeDomainFromStorage(ev.item);
      this.domain = null;
    }
    this.updateLinks();
  }

  domainSearch = (text$: Observable<string>) =>
  text$
    .debounceTime(200)
    .merge(this.domainFocus$)
    .merge(this.domainClick$.filter(() => !this.domainSearchInstance.isPopupOpen()))
    .map(term => {
      term = term.trim();
      let t = this.userSettingsService.cache['domainGeneration'].get(this.node.id) || [];
      if (!term) {
        const tlength = t.length;
        t = t.slice(0, this.maxListSize);
        if (tlength > this.maxListSize) {
          t.push(this.showMoreText);
        }
        return t;
      }
      return t.filter(v => {
        // do not display a domain that is already entered
        if (v.toLowerCase() === term.toLowerCase()) {
          return false;
        }
        if (v.toLowerCase().indexOf(term.toLowerCase()) > -1) {
          return true;
        }
        return false;
      }).slice(0, this.maxListSize);
    })

  ngOnInit() {
    this.camp = this.userSettingsService.cache.campSelected;
    this.node = this.userSettingsService.cache.nodeSelected;

    // prefil input with latest domain entered for this node
    const dom = this.userSettingsService.cache['domainGeneration'].get(this.node.id) || [];
    if (dom.length) {
      this.domain = dom[0];
    }
    this.updateLinks();
    this.updateJSLink();
    setTimeout(() => this.elName.nativeElement.focus(), 200);
  }

  removeDomainFromStorage(domain: string) {
    let arr = this.userSettingsService.cache['domainGeneration'].get(this.node.id) || [];
    arr = arr.filter(v => v.indexOf(domain) === -1);
    this.userSettingsService.cache['domainGeneration'].set(this.node.id, arr);
  }

  ngOnDestroy() {
    // store the current domain if a valid domain
    if (this.domain && this.domain.match(/[\w-]+\.\w+/)) {
      this.removeDomainFromStorage(this.domain);
      // push to front of cache
      const arr = this.userSettingsService.cache['domainGeneration'].get(this.node.id) || [];
      arr.unshift(this.domain);
      this.userSettingsService.cache['domainGeneration'].set(this.node.id, arr);
    }

  }

}
