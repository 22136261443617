import { Component, ViewChild, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { Campaign } from '../../campaigns';
import { TrafficSources, GetTrafficSourceName,
  RuleTypes, GetRuleName, GetRuleType, GetRuleValues } from '../../noipfraud';

import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';

import { countries } from './countries';
import { UserSettingsService } from '../../user-settings.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-campaign-add-edit-v1',
  templateUrl: './campaign-add-edit.component.html',
  styleUrls: ['./campaign-add-edit.component.css']
})
export class V1CampaignAddEditComponent implements OnInit {
  @Input() campaign: Campaign;
  @Input() campSavedEvent: Subject<void>;
  @Output() saved = new EventEmitter<Campaign>();

  @ViewChild('tagSearchInstance') tagSearchInstance: NgbTypeahead;

  oex: boolean;
  tab = 0;
  ruleTypes = RuleTypes;
  getRuleName = GetRuleName;
  getRuleType = GetRuleType;
  getRuleValues = GetRuleValues;
  getTrafficSourceName = GetTrafficSourceName;
  countryInput: any;
  itemInput = {};
  highlightCondition = '';
  countries = countries;
  campaignPagesState: string;
  campaignOfferPagesState: string;
  noipPagesState: string;
  noipOfferPagesState: string;
  ipwl = '';
  saving: boolean;

  sc1: boolean;

  urlfilterOptions = [
    { id: 'isempty', name: 'is empty', showValueField: false },
    { id: 'isequal', name: 'is equal to', showValueField: true },
    { id: 'isnotequal', name: 'is not equal to', showValueField: true }
  ];

  urlfilterInput = {
    param: '',
    value: ''
  };

  urlfilterOption = this.urlfilterOptions[0];

  trafficSources = TrafficSources;

  tagEntry: any;
  tagFocus$ = new Subject<string>();
  tagClick$ = new Subject<string>();

  constructor(
    private apiService: ApiService,
    private userSettingsService: UserSettingsService
  ) { }

  tagSearch = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .merge(this.tagFocus$)
      .merge(this.tagClick$.filter(() => !this.tagSearchInstance.isPopupOpen()))
      .map(term => {
        term = term.trim();
        const tags = this.userSettingsService.cache['tags'].data;
        const add: any[] = [ { action: 'add', actionDesc: `Add new tag`, name: term.toLocaleLowerCase() } ];
        const search = tags.filter(v =>
            v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
        if (term.length > 0 && this.userSettingsService.cache['tags'].data.indexOf(term.toLowerCase()) === -1) {
          return add.concat(search);
        } else {
          return search;
        }
      })

  countrySearch = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .map(term => term === '' ? []
        : countries.filter(v =>
            v.search.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.val.toLowerCase().indexOf(term.toLowerCase()) > -1 )
          .slice(0, 10))

  countryFormatter = (x: { search: string; val: string; }) => `${x.search} (${x.val})`;

  countryRuleSelected(rule: NgbTypeaheadSelectItemEvent, cond: any) {
    rule.preventDefault();
    this.countryInput = null;

    const val = rule.item.val;

    // init if not array (first)
    if (!cond || !cond.value) {
      cond.value = [];
    }

    // check duplicate
    if (cond.value.indexOf(val) > -1) {
      return;
    }

    cond.value.push(val);
  }

  addTag(ev: NgbTypeaheadSelectItemEvent) {
    ev.preventDefault();
    this.tagEntry = null;

    const val = ev.item;

    let name = '';
    if (val.action && val.action === 'add') {
      name = val.name.trim();
      // add to tags list so that new tags appear in the list
      // even though they can't be re-added to the same camp, i think it's more intuitive
      if (name.length > 0 && this.userSettingsService.cache['tags'].data.indexOf(name) === -1) {
        this.userSettingsService.cache['tags'].push(name);
      }
    } else {
      name = val.trim();
    }

    // init array if not found
    if (!this.campaign.user_tags) {
      this.campaign.user_tags = [];
    }

    // exit if duplicate or invalid
    if (name.length === 0 || this.campaign.user_tags.indexOf(name) > -1) {
      return;
    }

    // add tag
    this.campaign.user_tags.push(name);
  }

  getIP() {
    this.apiService.getIPaddress().subscribe(
      ip => this.ipwl = ip.data,
    );
  }

  addIPWL() {
    const ip = this.ipwl.trim();
    this.ipwl = '';
    if (ip === '' || this.campaign.noipfraud.ip_whitelist.indexOf(ip) > -1) {
      return;
    }
    this.campaign.noipfraud.ip_whitelist.push(ip);
  }

  addCondition(type: string) {
    for (const c of this.campaign.noipfraud.conditions) {
      if (c.rule === type) {
        this.highlightCondition = type;
        setTimeout(() => {
          this.highlightCondition = '';
          setTimeout(() => {
            this.highlightCondition = type;
            setTimeout(() => {
              this.highlightCondition = '';
            }, 100);
          }, 100);
        }, 100);
        return;
      }
    }

    let value: any;
    switch (type) {
      case 'frequencycap':
        value = {
          visits: 1,
          hours: 1
        };
        break;

      case 'list':
        value = '';
        break;
      case 'urlfilter':
      case 'country':
      case 'multi':
      case 'array':
        value = [];
    }
    this.campaign.noipfraud.conditions.push({
      rule: type,
      allow_only: false,
      value: value,
      block_next: {
        enabled: false,
        clicks: 0
      }
    });
  }

  addItem(cond: any, key: string) {
    if (!cond || !cond.value) {
      cond.value = [];
    }
    if (this.itemInput[key] === '') {
      return;
    }
    cond.value.push(this.itemInput[key].toLowerCase());
    this.itemInput[key] = '';
  }

  toggleMulti(id: string, cond: any) {
    if (!cond || !cond.value) {
      cond.value = [];
    }
    const p = cond.value.indexOf(id);
    if (p > -1) {
      cond.value.splice(p, 1);
    } else {
      cond.value.push(id);
    }
  }

  addUrlFilter(cond: any) {
    if (!cond || !cond.value) {
      cond.value = [];
    }
    if (this.urlfilterInput.param === '') {
      return;
    }
    cond.value.push({
      param: this.urlfilterInput.param,
      type: this.urlfilterOption.id,
      value: this.urlfilterOption.showValueField ? this.urlfilterInput.value.trim() : ''
    });
    this.urlfilterInput.value = '';
  }

  getUrlFilterTypeName(type: string): string {
    for (const v of this.urlfilterOptions) {
      if (v.id === type) {
        return v.name;
      }
    }
    return '';
  }

  save() {

    // run corrections

    // route must start with /
    if (this.campaign.route.charAt(0) !== '/') {
      this.campaign.route = '/' + this.campaign.route;
    }

    // trim strings
    this.campaign.name = this.campaign.name.trim();
    this.campaign.route = this.campaign.route.trim();

    for (let i = 0; i < this.campaign.landing_pages.length; i++) {
      this.campaign.landing_pages[i].name = this.campaign.landing_pages[i].name.trim();
      this.campaign.landing_pages[i].url = this.campaign.landing_pages[i].url.trim();
    }
    for (let i = 0; i < this.campaign.offers.length; i++) {
      this.campaign.offers[i].name = this.campaign.offers[i].name.trim();
      this.campaign.offers[i].url = this.campaign.offers[i].url.trim();
    }

    for (let i = 0; i < this.campaign.noipfraud.landing_pages.length; i++) {
      this.campaign.noipfraud.landing_pages[i].name = this.campaign.noipfraud.landing_pages[i].name.trim();
      this.campaign.noipfraud.landing_pages[i].url = this.campaign.noipfraud.landing_pages[i].url.trim();
    }
    for (let i = 0; i < this.campaign.noipfraud.offers.length; i++) {
      this.campaign.noipfraud.offers[i].name = this.campaign.noipfraud.offers[i].name.trim();
      this.campaign.noipfraud.offers[i].url = this.campaign.noipfraud.offers[i].url.trim();
    }

    //remove empty postbacks
    if (!this.campaign.postbacks ) {
      this.campaign.postbacks = []
    }
    this.campaign.postbacks = this.campaign.postbacks.filter(postback => postback.url !== '');
    this.saving = true;
    this.saved.emit(this.campaign);
  }

  campaignPagesValid(): boolean {
    if (this.campaignPagesState === 'invalid' || this.campaignOfferPagesState === 'invalid') {
      return false;
    }
    if (this.campaignPagesState === 'empty' && this.campaignOfferPagesState === 'invalid') {
      return false;
    }
    if (this.campaignOfferPagesState === 'empty' && this.campaignPagesState === 'invalid') {
      return false;
    }
    return true;
  }

  noipPagesValid(): boolean {
    if (this.noipPagesState === 'invalid' || this.noipOfferPagesState === 'invalid') {
      return false;
    }
    if (this.noipPagesState === 'empty' && this.noipOfferPagesState === 'invalid') {
      return false;
    }
    if (this.noipOfferPagesState === 'empty' && this.noipPagesState === 'invalid') {
      return false;
    }
    return true;
  }

  cpStateChange(s: string) {
    this.campaignPagesState = s;
  }

  coStateChange(s: string) {
    this.campaignOfferPagesState = s;
  }

  npStateChange(s: string) {
    this.noipPagesState = s;
  }

  noStateChange(s: string) {
    this.noipOfferPagesState = s;
  }

  ngOnInit() {
    if (this.campSavedEvent) {
      this.campSavedEvent.subscribe(() => {
        this.saving = false;
      });
    }
  }

}
