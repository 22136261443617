import { Component, Output, EventEmitter, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { OfferPage, NewOfferPage } from '../../../pages';

@Component({
  selector: 'app-offer-pages-v1',
  templateUrl: './offer-pages.component.html',
  styleUrls: ['./offer-pages.component.css']
})
export class V1OfferPagesComponent implements OnInit, OnDestroy {
  @ViewChild('pagesForm') pagesForm: NgForm;

  @Input() pages: OfferPage[];
  @Input() lpSplit: number;
  @Output() stateChange = new EventEmitter<string>(true);

  prevValidState: string;
  _required: boolean;

  constructor() { }

  pagesTrackFn(index: number, obj: OfferPage) {
    return index;
  }

  newWeightedPage() {
    this.pages.push(NewOfferPage());
  }

  @Input() set isRequired(value: boolean) {
    this._required = value;
    this.onInput();
 }

  splitValid(): boolean {
    if (!this.pages) {
      return false;
    }

    const pc = this.pagesSplit();

    if (this.lpSplit === 100) {
      return (Math.ceil(pc) === 100 || Math.ceil(pc) === 0);
    }

    // multiple pages must have a full 100% split
    const required = this._required || this.pages.length > 1;
    if (!required && this.pages[0].name.length === 0 && this.pages[0].url.length === 0) {
      return (Math.ceil(pc) === 100 || Math.ceil(pc) === 0);
    }
    return (Math.ceil(pc) === 100);
  }

  pagesSplit(): number {
    let pc = 0.0;
    for (const p of this.pages) {
      pc += p.weight;
    }
    return pc;
  }

  urlValid(pageIndex: number) {
    // multiple pages must be filled in completely
    const required = this._required || this.pages.length > 1;
    if (!required) {
      if (this.pages[pageIndex].name.length === 0 && this.pages[pageIndex].url.length === 0) {
        return true;
      }
    }
    return (this.pages[pageIndex].url.length > 0 && this.pages[pageIndex].url.match('^https?://.*'));
  }

  nameValid(pageIndex: number) {
     // multiple pages must be filled in completely
    const required = this._required || this.pages.length > 1;
    if (!required) {
      if (this.pages[pageIndex].url.length === 0 && this.pages[pageIndex].name.length === 0) {
        return true;
      }
    }
    return (this.pages[pageIndex].name.length > 0);
  }

  payoutValid(pageIndex: number) {
    return (this.pages[pageIndex].payout !== null && this.pages[pageIndex].payout >= 0);
  }

  pagesValid() {
    for (let i = 0; i < this.pages.length; i++) {
      if (!this.nameValid(i) || !this.payoutValid(i) || !this.urlValid(i)) {
        return false;
      }
    }
    return true;
  }

  pagesEmpty() {
    if (this.pages.length > 1) {
      return false;
    }
    const p = this.pages[0];
    return !p || (p.name.length === 0 && p.url.length === 0 && (p.weight === 0 || p.weight === null));
  }

  onInput() {
    // empty check
    if (this.pagesEmpty() && !this._required) {
      if ('empty' !== this.prevValidState) {
        this.prevValidState = 'empty';
        this.stateChange.emit('empty');
      }
      return;
    }

    // valid check
    const valid = (this.splitValid() && this.pagesValid()) ? 'valid' : 'invalid';
    if (valid !== this.prevValidState) {
      this.prevValidState = valid;
      this.stateChange.emit(this.prevValidState);
    }

  }

  equalizeSplit() {
    for (const p of this.pages) {
      p.weight = 100 / this.pages.length;
    }
  }

  ngOnInit() {
    // check form first
    this.onInput();
  }

  ngOnDestroy() {
  }

}
