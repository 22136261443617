import { Component, Output, EventEmitter, OnInit, Input, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CampaignFlow } from '../../../campaigns';
import { LandingPage, NewLandingPage } from '../../../pages';
import { PageStateChange } from '../campaign-add-edit.component';

enum AdvancedOption {
  Caching = 0,
  RWContent = 1,
  RWLinks = 2,
  Voluum = 3
}

@Component({
  selector: 'app-weighted-pages-v2',
  templateUrl: './landing-pages.component.html',
  styleUrls: ['./landing-pages.component.css']
})
export class V2LandingPagesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('pagesForm') pagesForm: NgForm;

  @Input() pages: LandingPage[];
  @Input() flow: CampaignFlow;
  @Input() nodeVersion: string;
  @Input() flowTouched: boolean;
  @Output() stateChange = new EventEmitter<PageStateChange>(true);

  AdvancedOption = AdvancedOption;
  _required: boolean;
  selectedPage: string;

  @Input() set isRequired(value: boolean) {
    this._required = value;
    this.onInput('modify');
  }

  pp_adv: boolean[] = [];
  pp_adv_opt: number[] = [];
  prevValidState: string;

  cacheDurations = [
    { display: '1 min', ms: 60000 },
    { display: `5 min`, ms: 300000 },
    { display: '30 min', ms: 1800000 },
    { display: '1 hr', ms: 3600000 },
    { display: '12 hrs', ms: 43200000 },
    { display: '1 day', ms: 86400000 },
    { display: '3 days', ms: 259200000 }
  ];

  getCacheDisplay(idx: number): string {
    if (!this.pages[idx].options.caching.enabled) {
      return 'Off';
    }
    for (let i = 0; i < this.cacheDurations.length; i++) {
      if (this.cacheDurations[i].ms === this.pages[idx].options.caching.value) {
        return this.cacheDurations[i].display;
      }
    }
    return this.pages[idx].options.caching.value + ' ms';
  }

  constructor(
    private modalService: NgbModal
  ) { }

  pagesTrackFn(index: number, obj: LandingPage) {
    return index;
  }

  newWeightedPage() {
    this.pages.push(NewLandingPage());
  }

  urlValid(pageIndex: number) {
    // multiple pages must be filled in completely
    const required = this._required || this.pages.length > 1;
    if (!required) {
      if (this.pages[pageIndex].name.length === 0 && this.pages[pageIndex].url.length === 0) {
        return true;
      }
    }
    return (this.pages[pageIndex].url.length > 0 && this.pages[pageIndex].url.match('^https?://.*'));
  }

  nameValid(pageIndex: number) {
    // multiple pages must be filled in completely
    const required = this._required || this.pages.length > 1;
    if (!required) {
      if (this.pages[pageIndex].url.length === 0 && this.pages[pageIndex].name.length === 0) {
        return true;
      }
    }
    return (this.pages[pageIndex].name.length > 0);
  }

  pagesValid() {
    for (let i = 0; i < this.pages.length; i++) {
      if (!this.nameValid(i) || !this.urlValid(i)) {
        return false;
      }
    }
    return true;
  }

  removePage(removeConfirm, page: LandingPage, index: number) {
    if (!this.flowTouched) {
      this.pages.splice(index, 1);
      this.onInput('modify');
      return;
    }

    let flowObjectID = '';
    // lookup flow object id by page id
    for (const object of this.flow.objects) {
      if (object.page_id === page.id) {
        flowObjectID = object.id;
        break;
      }
    }
    if (!flowObjectID) {
      this.pages.splice(index, 1);
      this.onInput('modify');
      return;
    }

    // check to see if page is in the flow
    for (const link of this.flow.links) {
      if (link.source === flowObjectID) {
        this.selectedPage = page.name;
        // page has children assigned to it in the flow
        this.modalService.open(removeConfirm).result.then(action => {
          this.pages.splice(index, 1);
          this.onInput(action);
        }, er => {});
        return;
      }
    }

    this.pages.splice(index, 1);
    this.onInput('action');
  }

  pagesEmpty() {
    if (this.pages.length > 1) {
      return false;
    }
    const p = this.pages[0];
    return (p.name.length === 0 && p.url.length === 0);
  }

  onInput(inputAction: string) {
    // empty check
    if (this.pagesEmpty() && !this._required) {
      if ('empty' !== this.prevValidState) {
        this.prevValidState = 'empty';
        this.stateChange.emit({state: this.prevValidState, action: inputAction});
      } else {
        this.stateChange.emit({action: inputAction});
      }
      return;
    }

    // valid check
    const valid = (this.pagesValid()) ? 'valid' : 'invalid';
    if (valid !== this.prevValidState) {
      this.prevValidState = valid;
      this.stateChange.emit({state: this.prevValidState, action: inputAction});
    } else {
      this.stateChange.emit({action: inputAction});
    }
  }

  ngOnInit() {
    // check form first
    this.onInput('modify');
  }

  ngOnChanges() {
    // check form if pages are changed  fromoutside of component
    this.onInput('modify');
  }

  ngOnDestroy() {
  }

}
