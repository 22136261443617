import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

export interface ColumnConfig {
  id: any;
  display: string;
  format?: string;
  align?: string;
  postfix?: string;
  transform?: boolean; //true if were setting a transform rule in parent component for this column
  rowclass?: {
    value: any;
    class: string;
  }[];
}

export interface ColTransform {
  col: string;
  val: string;
  replace: (value?: string) => string;
}

@Component({
  selector: 'app-treething',
  templateUrl: './treething.component.html',
  styleUrls: ['./treething.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush //Default caused transforms to trigger due to tooltip, so switching this to OnPush to limit unecessary redraws
})
export class TreethingComponent implements OnInit {
  @Input() sortedBy: string;
  @Input() sortedAsc: boolean;
  @Output() sortChanged = new EventEmitter<string>();

  pcols: ColumnConfig[];
  pdata: any;
  prows: any;
  orows: any;
  pgroups: string[];
  ptransform: ColTransform[];

  private _rawcols: ColumnConfig[];
  private _groupcols: ColumnConfig[];

  private buildRows(data: any, rows: any[], limit: number, level: number = 0, pos: number = 0, isLimit: boolean = false): number {
    let i = 0;
    let atLimit = false;
    const start = pos;
    let end = 0;
    for (const child of data.children) {
      const lim = isLimit || (limit > 0 && i >= limit);
      // capture position when first goes above limit
      if (!atLimit && lim) {
        end = pos;
      }
      atLimit = lim;

      if (!atLimit) {
        let row: any = {};
        row = {
          name: child.name,
          values: child.values
        };

        // internal stuff
        row._show = true;
        row._level = level;
        if (child.children && child.children.length > 0) {
          row._hasChild = true;
          row._expanded = true;
        }
        rows.push(row);
        i++;
      }
      pos++;

      // child rows
      if (child.children) {
        pos = this.buildRows(child, rows, limit, level + 1, pos, atLimit);
      }
    }
    if (data.children && limit > 0 && !isLimit && (pos - start) > limit) {
      const r = {
        _remain: data.children.length - limit,
        _pos: end,
        _end: pos,
        _level: level,
        _showMore: true,
        _show: true,
      };
      rows.push(r);
    }

    return pos;
  }


  @Input() set rawCols(cols: ColumnConfig[]) {
    this._rawcols = cols;
  }
  @Input() set groupCols(cols: ColumnConfig[]) {
    this._groupcols = cols;
  }
  @Input() set data(data: any) {
    if (!data || data.length === 0) {
      this.pdata = [];
      this.prows = [];
      this.orows = [];
      this.pcols = [];
      return;
    }

    if (data.children) {
      this.orows = [];
      this.prows = [];
      this.buildRows(data, this.orows, 0); // build full set
      this.buildRows(data, this.prows, 5); // build limited display set so UI is fast
      this.pcols = this._groupcols;
      this.pdata = [];
    } else {
      this.pdata = data;
      this.pcols = this._rawcols;
    }
  }
  @Input() set groups(groups: string[]) {
    this.pgroups = groups;
  }
  @Input() set transforms(tr: ColTransform[]) {
    this.ptransform = tr;
  }

  toggleSubLevel(idx: number) {
    const thisRow = this.prows[idx];
    thisRow._expanded = !thisRow._expanded;
    let j = 0;
    for (let i = idx + 1; i < this.prows.length && this.prows[i]._level > thisRow._level; i++) {
      if (j > thisRow._limit - 1) {
        continue;
      }
      this.prows[i]._expanded = thisRow._expanded;
      this.prows[i]._show = thisRow._expanded;
      j++;
    }
  }

  showMore(pos: number, row: any) {
    const j = {};
    let k = pos;
    j[row._level] = 0;
    for (let i = row._pos; i < row._end; i++) {
      const prevLevelCount = j[Math.max(0, this.orows[i]._level - 1)] || 0;
      const thisLevelCount = j[this.orows[i]._level];

      // add row if current level count < 5
      if (prevLevelCount <= 5 && thisLevelCount < 5) {
        j[this.orows[i]._level + 1] = 0; // reset next level
        this.prows.splice.apply( this.prows, [k, 0].concat(this.orows.slice(i, i + 1)) );
        k++;
      } else if (prevLevelCount <= 5 && this.orows[i]._level !== row._level && thisLevelCount === 5) {
        // count to end of level
        let n: number, l = 0;
        for (n = row._pos; this.orows[n]._level > row._level; n++) {
          l += this.orows[n]._level === this.orows[row._pos]._level ? 1 : 0;
        }

        // insert 'show more' row
        const r = {
          _remain: l,
          _pos: row._pos,
          _end: n,
          _level: this.orows[i]._level,
          _showMore: true,
          _show: true,
        };
        this.prows.splice(k, 0, r);
        k++;
      }

      // increment the current level. go one past the end to satisfy a count of 6
      if (thisLevelCount <= 5) {
        j[this.orows[i]._level]++;
      }

      // on current level: check if we want to exit
      if (this.orows[i]._level === row._level) {
        if (row._remain <= 0 || j[row._level] > 5) {
          break;
        }
        row._remain--;
      }

      row._pos++;
    }
  }

  setRowClass(data: any): string {
    for (const col of this.pcols) {
      //as styles are applied to rows before transforms on cells happen - make sure we transform the cell here
      //before checking if it matches the value specified
      if (!col.rowclass) {
        continue;
      }
      for (const rc of col.rowclass) {
        if (col.transform) {
          data[col.id]=this.transform(col.id,data)
        }
        if (data[col.id] === rc.value) {
            return rc.class;
        }
      }
    }
    return '';
  }

  transform(col: string, row: any): string {
    if (!this.ptransform || !this.ptransform.length) {
      return row[col];
    }
    for (const t of this.ptransform) {
      if (t.col === col && (t.val === '*' || t.val === row[col])) {
        return t.replace(row);
      }
    }
    return row[col];
  }

  sort(id: string) {
    this.sortChanged.emit(id);
  }

  constructor() { }

  ngOnInit() {
  }

}
