export const browsers = [
    { id: 'facebook app', name: 'Facebook App (Meta)' },
    { id: 'chrome', name: 'Chrome' },
    { id: 'edge', name: 'Microsoft Edge' },
    { id: 'samsung browser', name: 'Samsung Browser' },
    { id: 'android webview', name: 'Android Webview' },
    { id: 'instagram app', name: 'Instagram App (Meta)' },
    { id: 'google app', name: 'Google App' },
    { id: 'firefox', name: 'Firefox' },
    { id: 'safari', name: 'Safari' },
    { id: 'miui browser', name: 'MIUI Browser' },
    { id: 'opera', name: 'Opera' },
    { id: 'snapchat app', name: 'Snapchat App' },
    { id: 'whatsapp', name: 'WhatsApp (Meta)' },
    { id: 'vivo browser', name: 'Vivo Browser' },
    { id: 'klarna app', name: 'Klarna App' },
    { id: 'heytap browser', name: 'Heytap Browser' },
    { id: 'huawei browser', name: 'Huawei Browser' },
    { id: 'android browser', name: 'Android Browser' },
    { id: 'apple mail', name: 'Apple Mail' },
    { id: 'x (twitter) app', name: 'X (Twitter) App' },
    { id: 'tiktok app', name: 'TikTok App' },
    { id: 'pinterest app', name: 'Pinterest App' },
    { id: 'aol desktop', name: 'AOL Desktop' },
    { id: 'google news app', name: 'Google News App' },
    { id: 'duckduckgo', name: 'DuckDuckGo' },
    { id: 'wechat mobile app', name: 'WeChat Mobile App' },
    { id: 'daum app', name: 'Daum App' },
    { id: 'ecosia browser', name: 'Ecosia Browser' },
    { id: 'bing search app', name: 'Bing Search App' },
    { id: 'oculus browser', name: 'Oculus Browser' },
    { id: 'baidu search app', name: 'Baidu Search App' },
    { id: 'brave', name: 'Brave' },
    { id: 'chromium', name: 'Chromium' }
]
