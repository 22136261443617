import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title"><span class="oi oi-warning mr-2"></span>Are you sure?</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    <div *ngIf="message != ''" class="row">
      <div class="col">
        <p>{{message}}</p>
      </div>
   </div>
     <div class="row">
      <div class="col">
        <button class="btn btn-block btn-light" (click)="activeModal.dismiss()">Cancel</button>
      </div>
      <div class="col">
        <button class="btn btn-block btn-primary" (click)="activeModal.dismiss(true)">OK</button>
      </div>
     </div>
    </div>
  `
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  message: string;

  ngOnInit() {
  }

}
