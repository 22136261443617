export const environment = {
  production: false,

  // URL of dev API
  apiUrl: 'https://dash.thedevbiz.com/api',
  nodeInstallUrl: 'https://newdash.n2.app/n/install',
  nodePostbackUrl: 'https://newdash.n2.app/cv',
  chargebeeSiteName: 'n2-app-test',
  chargebeeProductName: 'n2-base',
  chargebeeDefaultAddon: 'fd_weekly',
  auth0: {
    clientID: 'VNHFUXaQYrK83Q3u2s3ULBCGe3ztbuFu',
    domain: 'n2-auth.auth0.com',
    responseType: 'token id_token',
    audience: 'https://n2-auth.auth0.com/api/v2/',
    redirectUri: 'https://dash.thedevbiz.com/authcallback',
    logoutUri: 'https://dash.thedevbiz.com',
    scope: 'openid profile'
  },
  showExport: false
};