import { UniqueID } from './util';

export interface WeightedPage {
    id: string;
    name: string;
    url: string;
    weight?: number;
}

export interface LandingPage extends WeightedPage {
    options: {
        caching: {
            enabled: boolean;
            value: number;
        };
        rewrite_content: {
            enabled: boolean;
            value: {
                selector: string;
                content: string;
            }[];
        };
        rewrite_links: {
            enabled: boolean;
            value: {
                link_search: string;
                link_replace: string;
            }[];
        };
        voluum: {
            enabled: boolean;
        }
    };
}

export function NewLandingPage(): LandingPage {
    return <LandingPage>{
        id: UniqueID(),
        name: '',
        url: '',
        weight: 0,
        options: {
            caching: {
                enabled: true,
                value: 300000
            },
            rewrite_content: {
                enabled: false,
                value: [{
                    selector: '',
                    content: ''
                }]
            },
            rewrite_links: {
                enabled: false,
                value: [{
                    link_search: '',
                    link_replace: ''
                }]
            },
            voluum: {
                enabled: false
            }
        }
    };
}


export interface OfferPage extends WeightedPage {
    payout: number;
}

export function NewOfferPage(): OfferPage {
    return <OfferPage>{
        id: UniqueID(),
        name: '',
        url: '',
        weight: 0,
        payout: 0.0
    };
}

