import { Component, OnInit } from '@angular/core';
import { MigrateDialogComponent } from '../migrate-dialog/migrate-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-import-account',
  templateUrl: './import-account.component.html',
  styleUrls: ['./import-account.component.css']
})
export class ImportAccountComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    setTimeout(() => this.showImport());
  }

  showImport() {
    const modalRef = this.modalService.open(MigrateDialogComponent, {keyboard: false, backdrop : 'static', container: 'body'})
    modalRef.componentInstance.action = 'import';
    modalRef.result.catch(res => {
    });
  }

}
