import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-import-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title"><span class="oi oi-warning mr-2"></span>Are you sure?</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <p>Importing campaign <code>{{ campaignName }}</code> will overwrite your current settings.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <small class="form-text text-muted mb-2">Route</small>
        </div>
        <div class="col-3">
          <small class="form-text text-muted mb-2">Landing Pages</small>
        </div>
        <div class="col-3">
         <small class="form-text text-muted mb-2">Offers</small>
        </div>
        <div class="col-3">
          <small class="form-text text-muted mb-2">Fraud Detection Settings</small>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-3">
         <ui-switch name="route" size="small" [(ngModel)]="options.route" color="#65BD6C"></ui-switch>
        </div>
        <div class="col-3">
         <ui-switch name="landing_pages" size="small" [(ngModel)]="options.landing_pages" color="#65BD6C"></ui-switch>
        </div>
        <div class="col-3">
         <ui-switch name="offers" size="small" [(ngModel)]="options.offers" color="#65BD6C"></ui-switch>
        </div>
        <div class="col-3">
         <ui-switch name="noipfraud" size="small" [(ngModel)]="options.noipfraud" color="#65BD6C"></ui-switch>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn btn-block btn-light" (click)="activeModal.dismiss()">Cancel</button>
        </div>
        <div class="col">
          <button class="btn btn-block btn-primary" (click)="activeModal.dismiss(options)">OK</button>
        </div>
      </div>
    </div>
  `
})
export class ImportDialogComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  campaignName: string;
  options: any;

  ngOnInit() {
    this.options = {
      route: true,
      landing_pages: true,
      offers: true,
      noipfraud: true
    }
  }

}
