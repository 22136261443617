import {
    OnInit,
    OnDestroy,
    ElementRef,
    Directive, Input, TemplateRef,
    EventEmitter,
    Renderer2,
    Injector,
    ComponentFactoryResolver,
    ViewContainerRef,
    NgZone
} from '@angular/core';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[stickyPopover]',
    exportAs: 'stickyPopover'
})
export class StickyPopoverDirective extends NgbPopover implements OnInit, OnDestroy {
    @Input() stickyPopover: TemplateRef<any>;
    @Input() disabled: boolean;

    popoverTitle: string;

    placement: 'auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left'
        | 'top-right' | 'bottom-left' | 'bottom-right' | 'left-top'
        | 'left-bottom' | 'right-top' | 'right-bottom' |
        ('auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left'
        | 'top-right' | 'bottom-left' | 'bottom-right' | 'left-top'
        | 'left-bottom' | 'right-top' | 'right-bottom')[];

    triggers: string;

    container: string;

    shown: EventEmitter<void>;

    hidden: EventEmitter<void>;

    ngpPopover: TemplateRef<any>;

    canClosePopover: boolean;

    toggle(): void {
        super.toggle();
    }

    isOpen(): boolean {
        return super.isOpen();
    }

    constructor(private _elRef: ElementRef, private _render: Renderer2, injector: Injector,
        componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, config: NgbPopoverConfig,
        ngZone: NgZone) {
        super(_elRef, _render, injector, componentFactoryResolver, viewContainerRef, config, ngZone, window.document, null, null);
        this.triggers = 'manual';
        this.popoverTitle = 'Permissions';
        this.container = 'body';
        this.autoClose = 'outside';
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.ngbPopover = this.stickyPopover;

        this._render.listen(this._elRef.nativeElement, 'click', () => {
            if (!this.disabled) {
                this.canClosePopover = true;
                this.open();
            }
        });

        /*this._render.listen(this._elRef.nativeElement, 'mouseleave', (event: Event) => {
            setTimeout(() => {
                if (this.canClosePopover) {
                    this.close();
                }
            }, 100);
        });

        this._render.listen(this._elRef.nativeElement, 'click', () => {
            // this.close();
        });
        */
    }

    ngOnDestroy(): void {
        console.log('ngOnDestroy');
        super.ngOnDestroy();
    }

    open() {
        console.log('open');
        super.open();
        const popover = window.document.querySelector('.popover');
        console.log('popover', popover);
        this._render.listen(popover, 'click', () => {
            this.canClosePopover = false;
        });

        this._render.listen(popover, 'mouseout', () => {
            this.canClosePopover = true;
            setTimeout(() => {
                if (this.canClosePopover) {
                    this.close();
                }
            }, 0);
        });
    }

    close() {
        super.close();
    }
}
