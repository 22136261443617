import { Injectable } from '@angular/core';

import { DateStruct } from './date-range.component';
import { Timezone, findTimezone } from './timezones';

import * as moment from 'moment-timezone';
import { NodeListResponse } from './api.service';

// quick & dirty cache
interface Cache {
  [key: string]: any;
}

class SessionCacheObject {
  private _id: string;
  private _d: {};

  constructor(id) {
    this._id = id;

    // try and load from session storage
    this._d = JSON.parse(sessionStorage.getItem(this._id)) || {};
  }

   // set specific property
   set(key: string, value: any) {
    this._d[key] = value;
    this._store();
  }

  // get specific property
  get(key): any {
    return this._d[key];
  }

  set data(item: any) {
    this._d = item;
    this._store();
  }

  // update session storage
  private _store() {
    sessionStorage.setItem(this._id, JSON.stringify(this._d));
  }
}

class PermamentCacheObject {
  private _id: string;
  private _d: {};

  constructor(id: string) {
    this._id = id;

    // try and load from cache
    this._d = JSON.parse(localStorage.getItem(this._id)) || {};
  }

  // set specific property
  set(key: string, value: any) {
    this._d[key] = value;
    this._store();
  }

  // get specific property
  get(key): any {
    return this._d[key];
  }

  // delete specific property
  del(key) {
    delete this._d[key];
    this._store();
  }

  isEmpty(): boolean {
    for (const key in this._d) {
      if (this._d.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  // set entire object
  set data(item: any) {
    this._d = item;
    this._store();
  }

  // get entire object
  get data() {
    return this._d;
  }

  // update permament storage
  private _store() {
    localStorage.setItem(this._id, JSON.stringify(this._d));
  }
}

class PermamentCacheArray {
  private _id: string;
  private _d: any[];

  constructor(id: string) {
    this._id = id;

    // try and load from cache
    this._d = JSON.parse(localStorage.getItem(this._id)) || [];
  }

  // add an item to the array
  push(item: any) {
    this._d.push(item);
    this._store();
  }

  // remove an item from the array
  splice(index: number) {
    this._d.splice(index, 1);
    this._store();
  }

  unshift(item: any) {
    this._d.unshift(item);
    this._store();
  }

  // set entire array
  set data(data: any[]) {
    this._d = data;
    this._store();
  }

  // get entire array
  get data(): any[] {
    return this._d;
  }

  // update permament storage
  private _store() {
    localStorage.setItem(this._id, JSON.stringify(this._d));
  }
}

@Injectable()
export class UserSettingsService {
  private dates: DateStruct;

  cache = <Cache>{};
  dateCache: PermamentCacheObject;

  timezone: any;

  constructor() {
    this.cache['nodeSelected'] = {};
    this.cache['campSelected'] = {};
    this.cache['overview'] = {};
    this.cache['campDetailStats'] = {};
    this.cache['campDetailSort'] = new PermamentCacheObject('detailSort');

    this.cache['grouping'] = new PermamentCacheObject('grouping');
    this.cache['collapsedStates'] = new PermamentCacheObject('collapsedStates');
    this.cache['searchQuery'] = new PermamentCacheArray('searchQuery');
    this.cache['searchTotals'] = {};

    this.cache['campaignIDs'] = [];
    this.cache['campaignNames'] = [];
    this.cache['nodeNames'] = [];
    this.cache['nodeIPs'] = [];
    this.cache['domains'] = [];
    this.cache['domainGeneration'] = new PermamentCacheObject('domainGeneration');
    this.cache['allRoutes'] = [];
    this.cache['nodeRoutes'] = {};

    this.dateCache = new PermamentCacheObject('selectedDate');

    this.cache['notificationState'] = new PermamentCacheObject('notificationState');

    // set date
    this.dates = (!this.dateCache.isEmpty()) ? this.dateCache.data  : <DateStruct>{
      from: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()},
      to: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()},
      display: 'Today',
      _range: 'today'
    };

    this.cache['tags'] = new PermamentCacheArray('tags');

    this.loadTimezone();
  }

  set selectedDates(dates: DateStruct) {
    this.dates = dates;
    this.dateCache.data = dates;
  }

  get selectedDates(): DateStruct {
    return this.dates;
  }

  get fromNano(): number {
    // initial date
    const fromDate = moment.tz({
      year: this.dates.from.year,
      month: this.dates.from.month - 1,
      day: this.dates.from.day
    }, this.timezone.name);

    fromDate.startOf('day');

    return Number(fromDate.format('x')) * 1000000;
  }

  get toNano(): number {
    // initial date
    const toDate = moment.tz({
      year: this.dates.to.year,
      month: this.dates.to.month - 1,
      day: this.dates.to.day
    }, this.timezone.name);

    // we add 1 day because api uses <to rather than <= to
    toDate.startOf('day').add(1, 'day');

    return Number(toDate.format('x')) * 1000000;
  }

  diffHours(): number {
    return (this.toNano - this.fromNano) / 3600000000000;
  }

  setTimezone(tz: Timezone) {
    localStorage.setItem('tz', JSON.stringify(tz));
    this.timezone = tz;
  }

  loadTimezone(): void {
    // attempt to load timezone from local storage
    let tz: Timezone;
    try {
      tz = JSON.parse(localStorage.getItem('tz'));
    } catch {}
    if (!tz) {
      tz = findTimezone(moment.tz.guess());
      if (!tz) {
        tz = findTimezone('Europe/London');
      }
    }
    this.setTimezone(tz);
  }
  
  // reconcileCache clears out any cached data that is out of date
  private reconcileCache(nodeIPS, tags) {
    // clear collapsedState cache
    let cs = this.cache['collapsedStates'].data;
    Object.keys(cs).forEach(key => {
      // internal state for ungrouped
      if (key === '') {return};

      if (nodeIPS.indexOf(key) === -1 && tags.indexOf(key) === -1) {
        this.cache['collapsedStates'].del(key);
      }
    })
  }

  async removeFromRouteCache(nodeID, route) {
    if (!this.cache['nodeRoutes'][nodeID]) { return; }
    this.cache['nodeRoutes'][nodeID] = this.cache['nodeRoutes'][nodeID].filter(function(v) {
      return (v !== route)
    });
  }

  async updateRouteCache(nodes: NodeListResponse[]) {
    const nodeRoutes = {};
    for (const node of nodes) {
      nodeRoutes[node.id] = [];
      // campaign stuff
      if (!node.campaigns) { continue; }
      for (const camp of node.campaigns) {
        // node routes
        nodeRoutes[node.id].push(camp.route);
      }
    }
    this.cache['nodeRoutes'] = nodeRoutes;
  }

  async updateSearchCache(nodes: NodeListResponse[]) {
    const tags: string[] = [];
    const campIDs: string[] = [];
    const campNames: string[] = [];
    const nodeNames: string[] = [];
    const nodeIPs: string[] = [];
    const domains: string[] = [];
    const allRoutes: string[] = [];


    for (const node of nodes) {

      // node names
      if (nodeNames.indexOf(node.name) === -1) {
        nodeNames.push(node.name);
      }

      // node IPs
      if (nodeIPs.indexOf(node.id) === -1) {
        nodeIPs.push(node.id);
      }

      // campaign stuff
      if (!node.campaigns) { continue; }
      for (const camp of node.campaigns) {

        // route
        if (allRoutes.indexOf(camp.route) === -1) {
          allRoutes.push(camp.route);
        }

        // campaign ID
        if (campIDs.indexOf(camp.id) === -1) {
          campIDs.push(camp.id);
        }

        // campaign name
        if (campNames.indexOf(camp.name) === -1) {
          campNames.push(camp.name);
        }

        // page domains
        for (const lp of camp.landing_pages) {
          const matches = lp.url.match(/^https?\:\/\/([^\/:?#]+)(?:[\/:?#]|$)/i);
          const domain = matches && matches[1];
          if (domain && domains.indexOf(domain) === -1) {
            domains.push(domain);
          }
        }

        // noip domains
        if (camp.noipfraud.hasOwnProperty('landing_pages')) {
          for (const lp of camp.noipfraud.landing_pages) {
            const matches = lp.url.match(/^https?\:\/\/([^\/:?#]+)(?:[\/:?#]|$)/i);
            const domain = matches && matches[1];
            if (domain && domains.indexOf(domain) === -1) {
              domains.push(domain);
            }
          }
        }

        // campaign tags
        if (!camp.user_tags) { continue; }
        for (const tag of camp.user_tags) {
          if (tags.indexOf(tag) === -1) {
            tags.push(tag);
          }
        }
      }
    }

    this.cache['tags'].data = tags;
    this.cache['campaignIDs'] = campIDs;
    this.cache['campaignNames'] = campNames;
    this.cache['nodeNames'] = nodeNames;
    this.cache['nodeIPs'] = nodeIPs;
    this.cache['domains'] = domains;
    this.cache['allRoutes'] = allRoutes;

    this.reconcileCache(nodeIPs, tags);

  }

}
