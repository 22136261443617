import { Component, OnInit } from '@angular/core';

import { Timezone, Timezones } from './timezones';
import { UserSettingsService } from './user-settings.service';
import { TimezoneService } from './timezone.service';

@Component({
  selector: 'app-timezone',
  template: `
    <div class="btn-group">
      <div class="btn-group" ngbDropdown role="group">
        <button class="btn btn-light" ngbDropdownToggle>
          <span class="oi oi-clock oi-sm mr-1"></span>
          {{ (timezone?.display.length > 25) ? (timezone?.display | slice:0:25) + '..' : (timezone.display) }}
          <span class="oi oi-caret-bottom oi-sm ml-1"></span>
        </button>
        <div class="dropdown-menu scrollable" ngbDropdownMenu>
          <button (click)="setTimezone(tz)" *ngFor="let tz of tzs" class="dropdown-item">{{ tz.display }}</button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .dropdown-toggle::after {
        display: none;
    }
    .scrollable {
      max-height:354px;
      overflow-y:scroll;
    }
  `]
})
export class TimezoneComponent implements OnInit {
  timezone: Timezone;
  tzs = Timezones;

  constructor(
    private userSettings: UserSettingsService,
    private timezoneService: TimezoneService,
    ) {
    this.timezone = userSettings.timezone;
  }

  setTimezone(tz: Timezone): void {
    this.userSettings.setTimezone(tz);
    this.timezone = tz;
    this.timezoneService.change.emit(tz);
  }

  ngOnInit() {
  }

}
