import { Component, Output, EventEmitter, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { LandingPage, NewLandingPage } from '../../../pages';

enum AdvancedOption {
  Caching = 0,
  RWContent = 1,
  RWLinks = 2,
}

@Component({
  selector: 'app-weighted-pages-v1',
  templateUrl: './landing-pages.component.html',
  styleUrls: ['./landing-pages.component.css']
})
export class V1LandingPagesComponent implements OnInit, OnDestroy {
  @ViewChild('pagesForm') pagesForm: NgForm;

  @Input() pages: LandingPage[];
  @Output() stateChange = new EventEmitter<string>(true);
  AdvancedOption = AdvancedOption;
  _required: boolean;

  @Input() set isRequired(value: boolean) {
    this._required = value;
    this.onInput();
 }

  pp_adv: boolean[] = [];
  pp_adv_opt: number[] = [];
  prevValidState: string;

  cacheDurations = [
    { display: '1 min', ms: 60000 },
    { display: '5 min', ms: 300000 },
    { display: '30 min', ms: 1800000 },
    { display: '1 hr', ms: 3600000 },
    { display: '12 hrs', ms: 43200000 },
    { display: '1 day', ms: 86400000 },
    { display: '3 days', ms: 259200000 }
  ];

  getCacheDisplay(idx: number): string {
    if (!this.pages[idx].options.caching.enabled) {
      return 'Off';
    }
    for (let i = 0; i < this.cacheDurations.length; i++) {
      if (this.cacheDurations[i].ms === this.pages[idx].options.caching.value) {
        return this.cacheDurations[i].display;
      }
    }
    return this.pages[idx].options.caching.value + ' ms';
  }

  constructor() { }

  pagesTrackFn(index: number, obj: LandingPage) {
    return index;
  }

  newWeightedPage() {
    this.pages.push(NewLandingPage());
  }

  pagesSplit(): number {
    let pc = 0.0;
    for (const p of this.pages) {
      pc += p.weight;
    }
    return pc;
  }

  splitValid(): boolean {
    const pc = this.pagesSplit();
    return (Math.ceil(pc) === 100 || !this._required && pc === 0);
  }

  urlValid(pageIndex: number) {
    // multiple pages must be filled in completely
    const required = this._required || this.pages.length > 1;
    if (!required) {
      if (this.pages[pageIndex].name.length === 0 && this.pages[pageIndex].url.length === 0) {
        return true;
      }
    }
    return (this.pages[pageIndex].url.length > 0 && this.pages[pageIndex].url.match('^https?://.*'));
  }

  nameValid(pageIndex: number) {
    // multiple pages must be filled in completely
    const required = this._required || this.pages.length > 1;
    if (!required) {
      if (this.pages[pageIndex].url.length === 0 && this.pages[pageIndex].name.length === 0) {
        return true;
      }
    }
    return (this.pages[pageIndex].name.length > 0);
  }

  pagesValid() {
    for (let i = 0; i < this.pages.length; i++) {
      if (!this.nameValid(i) || !this.urlValid(i)) {
        return false;
      }
    }
    return true;
  }

  pagesEmpty() {
    if (this.pages.length > 1) {
      return false;
    }
    const p = this.pages[0];
    return !p || (p.name.length === 0 && p.url.length === 0 && p.weight === 0);
  }

  onInput() {
    // empty check
    if (this.pagesEmpty() && !this._required) {
      if ('empty' !== this.prevValidState) {
        this.prevValidState = 'empty';
        this.stateChange.emit('empty');
      }
      return;
    }

    // valid check
    const valid = (this.splitValid() && this.pagesValid()) ? 'valid' : 'invalid';
    if (valid !== this.prevValidState) {
      this.prevValidState = valid;
      this.stateChange.emit(this.prevValidState);
    }
  }

  equalizeSplit() {
    for (const p of this.pages) {
      p.weight = 100 / this.pages.length;
    }
  }

  ngOnInit() {
    // check form first
    this.onInput();
  }

  ngOnDestroy() {
  }

}
