import { environment } from '../environments/environment';

declare var Chargebee: any;

export let chargebeeInstance: any;

export function initChargebee() {
  Chargebee.init({
    site: environment.chargebeeSiteName
  });
  chargebeeInstance = Chargebee.getInstance();
}

export function openCheckout(accountid: string, callbackFuncs: Function) {
  // set callback functions to be called once checkout actions are performed
  chargebeeInstance.setCheckoutCallbacks(callbackFuncs);

  const product = chargebeeInstance.initializeProduct(environment.chargebeeProductName);
  product.addAddon(environment.chargebeeDefaultAddon);
  const c = chargebeeInstance.getCart();
  c.setCustomer({cf_account_id: accountid});
  c.replaceProduct(product);
  c.proceedToCheckout();
}

export function openPortal() {
  chargebeeInstance.createChargebeePortal().open();
}

export function setPortalSession(cb: any) {
  chargebeeInstance.setPortalSession(cb);
}
