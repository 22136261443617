import { Component } from '@angular/core';
import { AlertService, Alert } from './alert.service';

@Component({
  selector: 'app-alerts',
  template: `
    <div *ngFor="let alert of alertService.alerts()">
      <ngb-alert [type]="alert.type" (close)="close(alert)" style="z-index: 9999;">{{alert.message}}</ngb-alert>
    </div>
  `,
})
export class AlertComponent {

    constructor(public alertService: AlertService) {}

    close(alert: Alert) {
      this.alertService.del(alert);
    }

}
