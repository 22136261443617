import { Injectable, EventEmitter } from '@angular/core';

import { Timezone } from './timezones';

/*
Explanation on how timezone works:

When searching for a shortcut range: “today”, “yesterday”, “last 7 days”, “last 30 days”, “this month” and “last month”,
the user’s selected timezone is taken into consideration when calculating the unix range to search.

The date that is used when calculating the unix range is ((UTC +/- SELECTEDTIMEZONEOFFSET) +/-  SELECTEDTIMEZONEOFFSET).
This means that the target day/month could change if the user is looking for clicks in a
timezone that is currently in the next or previous day.

Search examples:

Date and time: September 30th 11:01pm UTC. Timezone set to gmt+0. Range = "Today"
Look from the start to the end of the target tz day, with the user offset applied
09/30/2018 @ 12:00am (UTC) -> 10/01/2018 @ 12:00am (UTC)

Date and time: September 30th 11:01pm UTC. Timezone set to gmt+1. Range = "Today"
Look from the start to the end of the target tz day, with the user offset applied. The target TZ is in the following day
09/30/2018 @ 11:00pm (UTC) -> 10/01/2018 @ 11:00pm (UTC)

Date and time: September 30th 11:01pm UTC. Timezone set to gmt+0. Range = "This month"
Look from the start to the end of the target tz month, with the user offset applied
09/01/2018 @ 12:00am (UTC) -> 10/01/2018 @ 12:00am (UTC)

Date and time: September 30th 11:01pm UTC. Timezone set to gmt+1. Range = "This month"
Look from the start to the end of the target tz month, with the user offset applied. The target TZ is in the following month
09/30/2018 @ 11:00pm (UTC) -> 10/31/2018 @ 11:00pm (UTC)
*/

// the timezone service is used to react to changes to the selected timezone. Timezone.component emits
// selected timezones whilst date-range.component subscribes.
@Injectable()
export class TimezoneService {
  change: EventEmitter<Timezone> = new EventEmitter();
  timezoneChanged(tz: Timezone) {
    this.change.emit(tz);
  }
}
