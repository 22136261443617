import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgProgressModule } from '@ngx-progressbar/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OverviewComponent } from './overview/overview.component';
import { AuthInterceptor, ApiService } from './api.service';
import { TimezoneService } from './timezone.service';
import { SpinnerComponent } from './spinner.component';
import { TimezoneComponent } from './timezone.component';
import { LoginCallbackComponent } from './auth/login-callback.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guard.service';
import { ImportGuard } from './auth/import-guard.service';
import { UserSettingsService } from './user-settings.service';
import { V1CampaignAddEditComponent } from './campaign-add-edit/v1/campaign-add-edit.component';
import { V2CampaignAddEditComponent } from './campaign-add-edit/v2/campaign-add-edit.component';
import { CampaignDetailService } from './campaign-detail/campaign-detail.service';
import { CampaignDetailComponent } from './campaign-detail/campaign-detail.component';
import { CampaignStatsComponent } from './campaign-detail/campaign-stats/campaign-stats.component';
import { CampaignSetupComponent } from './campaign-detail/campaign-setup/campaign-setup.component';
import { CampaignNewComponent } from './campaign-new/campaign-new.component';
import { FlowComponent } from './campaign-add-edit/v2/flow/flow.component';
import { V1LandingPagesComponent } from './campaign-add-edit/v1/landing-pages/landing-pages.component';
import { V2LandingPagesComponent } from './campaign-add-edit/v2/landing-pages/landing-pages.component';
import { V1OfferPagesComponent } from './campaign-add-edit/v1/offer-pages/offer-pages.component';
import { V2OfferPagesComponent } from './campaign-add-edit/v2/offer-pages/offer-pages.component';
import { TreethingComponent } from './treething/treething.component';
import { AddNodeModalComponent } from './overview/add-node-modal.component';
import { ConversionSettingsModalComponent } from './conversion-settings-modal.component';
import { LinksModalComponent } from './links-modal.component';
import { CampaignOverviewComponent } from './campaign-detail/campaign-overview/campaign-overview.component';
import { DateRangeComponent } from './date-range.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ImportDialogComponent } from './campaign-new/campaign-import-dialog.component';
import { MigrateDialogComponent } from './migrate-dialog/migrate-dialog.component';
import { ProgressBarComponent } from './progress-bar.component';
import { AlertComponent } from './alert.component';

import { StickyPopoverDirective } from './sticky-popover.directive';
import { ErrorComponent } from './error/error.component';
import { IfVersionDirective } from './if-version.directive';
import { FlowItemContentComponent, FlowItemComponent } from './campaign-add-edit/v2/flow/flow-item.component';
import { NoipfraudOptionsComponent } from './campaign-add-edit/v2/noipfraud-options/noipfraud-options.component';
import { ProgressBarService } from './progress-bar.service';
import { AlertService } from './alert.service';
import { CampaignEditService } from './campaign-edit-service';
import { SearchPopoverComponent } from './search-popover/search-popover.component';
import { ImportAccountComponent } from './import-account/import-account.component';
import { SubscribeDialogComponent } from './subscribe-dialog/subscribe-dialog.component';
import { LabelFilterPipe } from './campaign-add-edit/v2/flow/label-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    TimezoneComponent,
    LoginCallbackComponent,
    OverviewComponent,
    V1CampaignAddEditComponent,
    V2CampaignAddEditComponent,
    CampaignDetailComponent,
    CampaignStatsComponent,
    CampaignSetupComponent,
    CampaignNewComponent,
    FlowComponent,
    FlowItemContentComponent,
    NoipfraudOptionsComponent,
    FlowItemComponent,
    V1LandingPagesComponent,
    V2LandingPagesComponent,
    V1OfferPagesComponent,
    V2OfferPagesComponent,
    TreethingComponent,
    AddNodeModalComponent,
    LinksModalComponent,
    ConversionSettingsModalComponent,
    CampaignOverviewComponent,
    DateRangeComponent,
    ConfirmDialogComponent,
    ImportDialogComponent,
    StickyPopoverDirective,
    ErrorComponent,
    IfVersionDirective,
    ProgressBarComponent,
    AlertComponent,
    SearchPopoverComponent,
    MigrateDialogComponent,
    ImportAccountComponent,
    SubscribeDialogComponent,
    LabelFilterPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    UiSwitchModule,
    ClipboardModule,
    NgxChartsModule,
    NgxGraphModule,
    NgProgressModule.forRoot()
  ],
  entryComponents : [
    FlowItemContentComponent,
    NoipfraudOptionsComponent,
    ConfirmDialogComponent,
    ImportDialogComponent,
    MigrateDialogComponent,
    SubscribeDialogComponent,
    AddNodeModalComponent,
    LinksModalComponent,
    ConversionSettingsModalComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ApiService,
    TimezoneService,
    AuthService,
    AuthGuard,
    ImportGuard,
    UserSettingsService,
    CampaignDetailService,
    ProgressBarService,
    AlertService,
    CampaignEditService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
