import { Component, OnInit, ViewChild } from '@angular/core';

import { NewPostback, Postback } from './campaigns';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserSettingsService } from './user-settings.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-conversion-settings-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title"><span class="oi oi-cog mr-2"></span>Conversion Settings</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label><strong>Additional postbacks</strong></label>
      <p class="form-text">
        Set up additional URLs to postback to when you receive a conversion event in n2. You can use tokens like <code ngNonBindable>{{subid}}</code>, URL parameters and
        <a href="https://docs.n2.app/docs/tokens" target="_blank">built-in tokens</a> in the URL.
      </p>
      <form #postbackForm="ngForm">
        <div class="input-group mb-2" *ngFor="let postback of postbacks; index as i; trackBy:trackByIndex">
          <div ngbDropdown class="mr-2">
            <button class="btn btn-outline-primary" ngbDropdownToggle>{{postback.method}}</button>
            <div ngbDropdownMenu>
              <button (click)="postback.method = 'POST'" class="dropdown-item">POST</button>
              <button (click)="postback.method = 'GET'" class="dropdown-item">GET</button>
            </div>
          </div>
          <input type="text"
            pattern="https?://.*" [(ngModel)]="postback.url" class="form-control" name="url_{{i}}" placeholder="URL" required>
          <button *ngIf="postbacks.length > 0" (click)="postbacks.splice(i, 1)" class="btn btn-outline-danger ml-2">
            <span class="oi oi-circle-x"></span>
          </button>
        </div>
        <div class="form-group mb-3">
          <button (click)="addPostback()" class="btn btn-primary"><span class="oi oi-plus"></span> Add Postback</button>
        </div>
      </form>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close(null)">Cancel</button>
        <button type="button" class="btn btn-primary"
        [disabled]="!postbackForm.valid"
        (click)="ok()">Save</button>
      </div>
    </div>
  `,
  styles: [`
    input.ng-invalid {
      border-bottom: 1px solid #dc3545 !important;
    }
  `]
})
export class ConversionSettingsModalComponent implements OnInit {
  camp: any;
  node: any;
  postbacks: Postback[];

  constructor(
    private userSettingsService: UserSettingsService,
    public activeModal: NgbActiveModal
  ) {}

  addPostback() {
    this.postbacks.push(NewPostback());
  }

  trackByIndex(index) {
    return index;
  }

  ok() {
    this.activeModal.close(this.postbacks);
  }

  ngOnInit() {
    this.camp = this.userSettingsService.cache.campSelected;
    this.node = this.userSettingsService.cache.nodeSelected;

    if (!this.camp.postbacks) {
      this.postbacks = [];
    } else {
      // copy postbacks
      this.postbacks = JSON.parse(JSON.stringify(this.camp.postbacks));
    }
  }

}
