import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelFilter'
})
export class LabelFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    // remove encoded strings the from flow display
    if (args.type && args.type === "token_split_item") {
      return decodeURIComponent(value);
    }
    return value;
  }

}