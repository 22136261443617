import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService
  ) { }
  code = '0';
  message = 'Unknown error';
  authService = this.auth;

  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get('code');
    switch (this.code) {
      case '0':
        this.message = 'No connection.';
        break;
      case '404':
        this.message = 'Resource not found.';
        break;
      case '403':
        this.message = 'You do not have permission to access this resource.';
        break;
    }
  }

}
