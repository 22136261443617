import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Subject } from 'rxjs';

import { Campaign } from '../../../campaigns';
import { UniqueID } from '../../../util';

import * as shape from 'd3-shape';


@Component({
  selector: 'app-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.css']
})
export class FlowComponent implements OnInit, OnChanges, OnDestroy {

  @Input() campaign: Campaign;
  @Input() nodeVersion: string;
  @Input() flowGenerate: Subject<void>;
  @Input() flowUpdate: Subject<void>;
  @Input() campImported: boolean;

  @Output() noipfraudOptionsClicked: EventEmitter<any> = new EventEmitter();
  @Output() flowUpdated: EventEmitter<any> = new EventEmitter(true);

  update$: Subject<any> = new Subject();
  addFlowItem$: Subject<any> = new Subject();
  editFlowItem$: Subject<any> = new Subject();

  view: any[];

  showLegend: true;
  touched = false;

  colorScheme: {
    name: 'picnic',
    selectable: false,
    group: 'Ordinal',
    domain: [
      '#FAC51D', '#66BD6D', '#FAA026', '#29BB9C', '#E96B56', '#55ACD2', '#B7332F', '#2C83C9', '#9166B8', '#92E7E8'
    ]
  };

  nodeColors = {
    '': '#B0DE2E', // start
    'percent_split_item': '#55ACD2',
    'token_split_item': '#55ACD2',
    'percent_split': '#55ACD2',
    'token_split': '#55ACD2',
    'noipfraud_allowed_item': '#66BD6D',
    'noipfraud_blocked_item': '#FAA026',
    'noipfraud': '#E96B56',
    'page': '#29BB9C',
    'offer': '#FAC51D'
  };

  orientation = 'LR';

  curve = shape.curveBasis;

  linkMode = {
    active: false,
    source: '',
    target: ''
  };

  linkSource: string;

  hierarchialGraph = {nodes: [<any>{
    id: 'start',
    type: '',
    label: 'Visit'
  }], links: []};

  /*

  Example of graph data structure:

  hierarchialGraph = {
    nodes: [
      <any>{
        id: 'start',
        type: '',
        label: 'Visit'
      }, {
        id: '1',
        type: 'noipfraud',
        label: 'Noipfraud',
      }, {
        id: '2',
        type: 'noipfraud_item',
        label: 'Blocked',
      }, {
        id: '3',
        type: 'noipfraud_item',
        label: 'Allowed'
      }, {
        id: '4',
        type: 'percent_split',
        percentages: [60, 40],
        label: 'Percent split'
      }, {
        id: '5',
        type: 'percent_split_item',
        label: '60%'
      }, {
        id: '6',
        type: 'percent_split_item',
        label: '40%'
      }, {
        id: '7',
        type: 'page',
        label: 'Page 1'
      }, {
        id: '8',
        type: 'page',
        label: 'Page 2'
      }, {
        id: '9',
        type: 'offer',
        label: 'Offer 1'
      }, {
        id: '10',
        type: 'offer',
        label: 'Offer 2'
      }, {
        id: '11',
        type: 'page',
        label: 'Safe Page 1'
      }, {
        id: '12',
        type: 'offer',
        label: 'Safe Offer'
      }
    ],
    links: [
      {
        source: 'start',
        target: '1'
      }, {
        source: '1',
        target: '2'
      }, {
        source: '1',
        target: '3'
      }, {
        source: '3',
        target: '4'
      }, {
        source: '4',
        target: '5'
      }, {
        source: '4',
        target: '6'
      }, {
        source: '5',
        target: '7'
      },
      {
        source: '6',
        target: '8'
      },
      {
        source: '7',
        target: '9'
      },
      {
        source: '8',
        target: '9'
      },
      {
        source: '7',
        target: '10'
      },
      {
        source: '8',
        target: '10'
      },
      {
        source: '2',
        target: '11'
      },
      {
        source: '11',
        target: '12'
      }
    ]
  };
  */

  constructor() {}

  private generatePageSplitPercentages(pages): number[] {
    const percentages = [];
    let percentTot = 0.0;
    const t = pages.length;
    for (let i = 0; i < t - 1; i++) {
      const p = Math.round(100 / t);
      percentTot += p;
      percentages.push(p);
    }
    // final percentage remaining
    percentages.push(100 - percentTot);
    return percentages;
  }

  private generateOfferFlow(offerParentNodes, offers) {
    // for each parent node to each offer, generate an offer node or a split node and offer nodes for the split
    for (let i = 0; i < offerParentNodes.length; i++) {
      if (offers.length === 1 && offers[0].name !== '') {
        const oitemid = UniqueID();
        // append single offer to each parent node
          this.hierarchialGraph.nodes.push({
            id: oitemid,
            page_id: offers[0].id, // USE PAGE ID
            type: 'offer',
            label: offers[0].name,
          });
          this.addLink(offerParentNodes[i], oitemid);
          continue;
      }

      // more than 1 offer, create a percentage split
      const percentages = this.generatePageSplitPercentages(offers);
      const percentSplitNodes = [];

      const splitID = UniqueID();
      // create split percentage node
      this.hierarchialGraph.nodes.push({
        id: splitID,
        type: 'percent_split',
        percentages: percentages,
        label: 'Percent split'
      });
      this.addLink(offerParentNodes[i], splitID);

      // append split items to the percentage node
      for (const pct of percentages) {
        const pitemid = UniqueID();
        this.hierarchialGraph.nodes.push({
          id: pitemid,
          type: 'percent_split_item',
          label: pct + '%'
        });
        percentSplitNodes.push(pitemid);
        this.addLink(splitID, pitemid);
      }

      for (let j = 0; j < percentSplitNodes.length; j++) {
        const oitemid = UniqueID();
        this.hierarchialGraph.nodes.push({
          id: oitemid,
          page_id: offers[j].id, // USE OFFER ID
          type: 'offer',
          label: offers[j].name,
        });
        this.addLink(percentSplitNodes[j], oitemid);
      }
    }
  }

  // setflow sets the flow to the specified objects and link arrays
  private setFlow(touched, nodes, links) {
    this.hierarchialGraph.nodes = nodes;
    this.hierarchialGraph.links = links;
    this.touched = touched;
    // refresh graph, do not emit changes
    this.refreshGraph();
  }

  // update flow updates node labels and removes any landingpages/offers + children if they do not exist anymore
  private updateFlow() {
    // build a map of node labels to update
    const toUpdate = new Map();
    for (const p of this.campaign.landing_pages) {
      toUpdate.set(p.id, p.name);
    }
    for (const o of this.campaign.offers) {
      toUpdate.set(o.id, o.name);
    }

    const toRemove = [];
    for (let i = 0; i < this.hierarchialGraph.nodes.length; i++) {
      const isPageOrOffer = (
        this.hierarchialGraph.nodes[i].type === 'page' ||
        this.hierarchialGraph.nodes[i].type === 'offer'
      );

      if (!isPageOrOffer) {
        continue;
      }

      const newLabel = toUpdate.get(this.hierarchialGraph.nodes[i].page_id);
      if (!newLabel) {
        // page or offer no longer exists, add to removal list
        toRemove.push(this.hierarchialGraph.nodes[i].id);
        continue;
      }

      // overwrite label
      this.hierarchialGraph.nodes[i].label = newLabel;
    }

    // remove any nodes that have been removed from landing pages or offers
    for (const id of toRemove) {
      this.removeNode(id);
    }

    this.refreshGraph();
  }

  private generateFlow() {
    const noipfraudID = UniqueID();
    const noipfraudAllowedID = UniqueID();
    const noipfraudBlockedID = UniqueID();
    const nodes = [{
      id: 'start',
      type: '',
      label: 'Visit'
    }, {
      id: noipfraudID,
      type: 'noipfraud',
      label: 'Fraud Detection',
    }, {
      id: noipfraudBlockedID,
      type: 'noipfraud_blocked_item',
      label: this.campaign.noipfraud.enabled ? 'Blocked' : 'Off',
    }, {
      id: noipfraudAllowedID,
      type: 'noipfraud_allowed_item',
      label: 'Allowed'
    }];

    const links = [{
      source: 'start',
      target: noipfraudID,
    }, {
      source: noipfraudID,
      target: noipfraudBlockedID,
    }, {
      source: noipfraudID,
      target: noipfraudAllowedID,
    }];

    this.hierarchialGraph.links = links;
    this.hierarchialGraph.nodes = nodes;

    const offerParentNodes = [];
    const areLandingPages = (this.campaign.landing_pages.length >= 1 && this.campaign.landing_pages[0].name !== '');
    const areOfferPages = (this.campaign.offers.length >= 1 && this.campaign.offers[0].name !== '');

    // no landing pages or offer pages to generate. halt.
    if (!areLandingPages && !areOfferPages) {
      this.refreshGraph();
      return;
    }

    // no landing pages to add to the flow, go straight to adding offers
    if (!areLandingPages) {
      offerParentNodes.push(noipfraudBlockedID);
      this.generateOfferFlow(offerParentNodes, this.campaign.offers);
      this.refreshGraph();
      return;
    }

    // make a split for each landing page, if more than 1
    const toAppendPages = [];
    if (this.campaign.landing_pages.length > 1) {
      const percentages = this.generatePageSplitPercentages(this.campaign.landing_pages);
      const splitID = UniqueID();
      // create split percentage
      this.hierarchialGraph.nodes.push({
        id: splitID,
        type: 'percent_split',
        percentages: percentages,
        label: 'Percent split'
      });
      this.addLink(noipfraudBlockedID, splitID);

      for (const pct of percentages) {
        const pitemid = UniqueID();
        this.hierarchialGraph.nodes.push({
          id: pitemid,
          type: 'percent_split_item',
          label: pct + '%'
        });
        toAppendPages.push(pitemid);
        this.addLink(splitID, pitemid);
      }
    } else {
      toAppendPages.push(noipfraudBlockedID);
    }

    // append pages
    for (let i = 0; i < toAppendPages.length; i++) {
      const pageID = UniqueID();
      this.hierarchialGraph.nodes.push({
        id: pageID,
        page_id: this.campaign.landing_pages[i].id, // USE PAGE ID
        type: 'page',
        label: this.campaign.landing_pages[i].name,
      });
      this.addLink(toAppendPages[i], pageID);
      offerParentNodes.push(pageID);
    }

    if (areOfferPages) {
      this.generateOfferFlow(offerParentNodes, this.campaign.offers);
    }

    this.refreshGraph();
  }


  private validateNodes(): boolean {
    let nodesValid = true;
    const sourceIDS = new Map();
    for (const link of this.hierarchialGraph.links) {
      sourceIDS.set(link.source, true);
    }

    for (let i = 0; i < this.hierarchialGraph.nodes.length; i++) {
      const node = this.hierarchialGraph.nodes[i];

      // allowed can be empty if noip disabled
      if (!this.campaign.noipfraud.enabled && node.type === 'noipfraud_allowed_item') {
        this.hierarchialGraph.nodes[i].invalid = false;
        continue;
      }

      switch (node.type) {
        case 'noipfraud_blocked_item':
          node.label = this.campaign.noipfraud.enabled ? 'Blocked' : 'Off';

          // if id is not in a source, there is no child for this node
          const blockedHasChild = sourceIDS.get(this.hierarchialGraph.nodes[i].id);
          if (!blockedHasChild) {
            this.hierarchialGraph.nodes[i].invalidReason = 'Must end with a page or offer';
            this.hierarchialGraph.nodes[i].invalid = true;
            nodesValid = false;
          } else if (this.campaign.noipfraud.enabled && this.campaign.noipfraud.exp_enabled) {

            // if exp enabled, we have to load a blocked page first and cannot successfuly handle a split
            // that requires information from the noip api as the noip api call occurs after the initial blocked page load
            const hasInvalidTokenSplit = (nodeID: string): boolean => {
              const children = this.findChildNodeIDs(nodeID);
              if (children.length === 0) {
                return false;
              }
              for (const c of children) {
                const n = this.findNode(c);
                if (n.type === 'token_split' && n.token && n.token.name !== 'URL Param') {
                  return true;
                }
                const res = hasInvalidTokenSplit(n.id);
                if (!res) {
                  return false;
                }
              }
              return true;
            };

            // if exp enabled, every branch from "blocked" must have a page for the expjs to work
            // keep traversing through each branch until we reach the end
            // if we reach the end of a branch without finding a node of 'page'
            // then there is no page on that branch
            // return false early
            const pageOnEveryBranch = (nodeID: string): boolean => {
              const children = this.findChildNodeIDs(nodeID);
              if (children.length === 0) {
                return false;
              }
              for (const c of children) {
                const n = this.findNode(c);
                if (n.type === 'page') {
                  return true;
                }
                const res = pageOnEveryBranch(n.id);
                if (!res) {
                  return false;
                }
              }
              return true;
            };

            if (hasInvalidTokenSplit(this.hierarchialGraph.nodes[i].id)) {
              this.hierarchialGraph.nodes[i].invalidReason = 'EXP mode: Token split not permitted here';
              this.hierarchialGraph.nodes[i].invalid = true;
              nodesValid = false;
            }
            if (nodesValid && !pageOnEveryBranch(this.hierarchialGraph.nodes[i].id)) {
              this.hierarchialGraph.nodes[i].invalidReason = `EXP mode: each 'Blocked' branch must have a page`;
              this.hierarchialGraph.nodes[i].invalid = true;
              nodesValid = false;
            }
          }
          if (nodesValid) {
            this.hierarchialGraph.nodes[i].invalid = false;
          }
        break;
        case 'noipfraud_allowed_item':
        case 'percent_split_item':
        case 'token_split_item':
          // if id is not in a source, there is no child for this node
          const nodeHasChild = sourceIDS.get(this.hierarchialGraph.nodes[i].id);
          if (!nodeHasChild) {
            this.hierarchialGraph.nodes[i].invalidReason = 'Must end with a page or offer';
            this.hierarchialGraph.nodes[i].invalid = true;
            nodesValid = false;
          } else {
            this.hierarchialGraph.nodes[i].invalid = false;
          }
          break;
      }
    }
    return nodesValid;
  }

  private refreshGraph() {
    this.hierarchialGraph.links = [...this.hierarchialGraph.links];
    this.hierarchialGraph.nodes = [...this.hierarchialGraph.nodes];
    this.update$.next(true);
    this.flowUpdated.emit({touched: this.touched, graph: this.hierarchialGraph, valid: this.validateNodes()});
  }

  private addLink(from: string, to: string) {
    this.hierarchialGraph.links.push({
      source: from,
      target: to
    });
  }

  private addPercentSplitItem(sourceID: any, pct: number) {
    const pitemid = UniqueID();
    this.hierarchialGraph.nodes.push({
      id: pitemid,
      type: 'percent_split_item',
      label: pct + '%'
    });
    this.addLink(sourceID, pitemid);
  }

  private addTokenSplitItem(sourceID: any, value: string, type: string) {
    const pitemid = UniqueID();
    this.hierarchialGraph.nodes.push({
      id: pitemid,
      type: 'token_split_item',
      label: value,
    });
    this.addLink(sourceID, pitemid);
  }

  private findNode(nodeID: string): any {
    for (const n of this.hierarchialGraph.nodes) {
      if (n.id === nodeID) {
        return n;
      }
    }
    return null;
  }

  private findChildNodeIDs(sourceNodeID: string): string[] {
    const ret = [];
    for (const l of this.hierarchialGraph.links) {
      if (l.source === sourceNodeID) { ret.push(l.target); }
    }
    return ret;
  }

  isNodeLastOnBranch(node): boolean {
    // if link for this ID has no source then it is the last link
    for (const link of this.hierarchialGraph.links) {
      if (link.source === node.id) {
        return false;
      }
    }

    // node id did not have a link source
    return true;
  }

  canAddToNode(node): boolean {
    // check if node is last on branch and not an offer
    return node.type !== 'offer' && this.isNodeLastOnBranch(node);
  }

  canDeleteNode(node): boolean {
    if (node.id === 'start') { return false; }
    switch (node.type) {
      case 'percent_split_item':
      case 'token_split_item':
      case 'noipfraud':
      case 'noipfraud_allowed_item':
      case 'noipfraud_blocked_item':
        return false;
    }
    return true;
  }

  hasOptions(node): boolean {
    switch (node.type) {
      case 'percent_split':
      case 'token_split':
      case 'noipfraud':
        return true;
    }
    return false;
  }

  openAddNode(node) {
    this.addFlowItem$.next(node);
  }

  addNode(item) {
    const id = UniqueID();
    switch (item.type) {
      case 'page':
        this.hierarchialGraph.nodes.push({
          id: id,
          page_id: item.options.selected.id,
          type: 'page',
          label: item.options.selected.name,
        });
        this.addLink(item.original.id, id);
        break;
      case 'offer':
        this.hierarchialGraph.nodes.push({
          id: id,
          page_id: item.options.selected.id,
          type: 'offer',
          label: item.options.selected.name,
        });
        this.addLink(item.original.id, id);
        break;
      case 'percent_split':
        this.hierarchialGraph.nodes.push({
          id: id,
          type: 'percent_split',
          percentages: item.options.percentages,
          label: 'Percent split'
        });
        this.addLink(item.original.id, id);

        // add each item
        for (const pct of item.options.percentages) {
          this.addPercentSplitItem(id, pct);
        }
        break;
      case 'token_split':
        this.hierarchialGraph.nodes.push({
          id: id,
          type: 'token_split',
          token: item.options.selected,
          values: item.options.value,
          label: item.options.selected.name + ' split'
        });
        this.addLink(item.original.id, id);

        // add mandatory ELSE item
        if (item.options.selected.type === 'fixed') {
          for (const opt of item.options.selected.options) {
            this.addTokenSplitItem(id, opt, item.options.selected.type);
          }
        } else {
          this.addTokenSplitItem(id, 'ELSE', item.options.selected.type);
        }

        // add each item
        for (const val of item.options.value) {
          this.addTokenSplitItem(id, val, item.options.selected.type);
        }
        break;
    }
    this.touched = true;
    this.refreshGraph();
  }

  openEditNode(node) {
    if (node.type === 'noipfraud') {
      this.noipfraudOptionsClicked.emit(true);
      return;
    }

    // show edit dialog
    this.editFlowItem$.next(node);
  }

  editNode(item) {
    switch (item.type) {
      case 'page':
        break;
      case 'offer':
        break;
      case 'percent_split':
        // this aims to retain as many links as possible

        // add or remove remaining
        const diff = item.options.percentages.length - item.original.percentages.length;
        if (diff > 0) {
          for (let i = item.original.percentages.length; i < item.original.percentages.length + diff; i++) {
            this.addPercentSplitItem(item.original.id, item.options.percentages[i]);
          }
        } else if (diff < 0) {
          const ch = this.findChildNodeIDs(item.original.id);
          for (let i = item.options.percentages.length - 1; i < item.original.percentages.length - 1; i++) {
            this.removeNode(ch[i]);
          }
        }

        // get child items
        const childIPercentItemIDs = this.findChildNodeIDs(item.original.id);

        // find original node (we only have by-value at this point)
        // and set percentages on node
        const psNode = this.findNode(item.original.id);
        psNode.percentages = item.options.percentages;

        for (let i = 0; i < psNode.percentages.length; i++) {
          const cnode = this.findNode(childIPercentItemIDs[i]);
          cnode.label = psNode.percentages[i] + '%';
        }
        break;
      case 'token_split':
        const tsNode = this.findNode(item.original.id);

        const childTokenItemIDs = this.findChildNodeIDs(item.original.id);
        for (let i = 0; i < Math.max(tsNode.values.length, item.options.value.length); i++) {
          if (i > tsNode.values.length - 1) {
            this.addTokenSplitItem(item.original.id, item.options.value[i], item.options.selected.type);
          } else if (i > item.options.value.length - 1) {
            this.removeNode(childTokenItemIDs[i + 1]);
          } else {
            const cnode = this.findNode(childTokenItemIDs[i + 1]);
            cnode.label = (item.options.selected.type === 'split') ? item.options.value[i].replace('=', ' = ') : item.options.value[i];
          }
        }

        tsNode.values = item.options.value;
        this.touched = true;
        break;
    }
    this.refreshGraph();
  }

  removeNode(targetNodeID: string) {
    // wrap remove node logic in a recursive function so all nodes have been removed
    // *before* continuing with redrawing and revalidating the graph
    const rn = (nodeID: string): void => {
      // remove from nodes
      for (let j = 0; j < this.hierarchialGraph.nodes.length; j++) {
        if (this.hierarchialGraph.nodes[j].id === nodeID) {
          this.hierarchialGraph.nodes.splice(j, 1);
        }
      }

      // search through links and remove downstream connections
      for (let i = this.hierarchialGraph.links.length - 1; i >= 0; i--) {
        const link = this.hierarchialGraph.links[i];
        if (link.source === nodeID || link.target === nodeID) {
          // remove from links
          this.hierarchialGraph.links.splice(i, 1);

          // remove downstream target
          if (link.target && link.target.length) {
            rn(link.target);
          }
        }
      }
    };
    rn(targetNodeID);

    this.touched = true;
    this.refreshGraph();
  }

  ngOnInit() {
    if (this.campaign.id !== '') {
      this.touched = true;
      this.setFlow(this.touched, this.campaign.flow.objects, this.campaign.flow.links);
    }

    this.flowGenerate
    .subscribe(() => {
      // generate flow from scratch, set as untouched as the flow will be reset
      this.touched = false;
      this.generateFlow();
    });

    this.flowUpdate
    .subscribe(() => {
      this.updateFlow();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.campImported && changes.campImported.currentValue) {
      this.touched = true;
      this.setFlow(this.touched, this.campaign.flow.objects, this.campaign.flow.links);
      this.updateFlow();
    }
  }

  ngOnDestroy() {
  }

}
