export const countries = [
    { val: 'AW', search: 'Aruba' },
    { val: 'AF', search: 'Afghanistan' },
    { val: 'AO', search: 'Angola' },
    { val: 'AI', search: 'Anguilla' },
    { val: 'AX', search: 'Aland Islands' },
    { val: 'AL', search: 'Albania' },
    { val: 'AD', search: 'Andorra' },
    { val: 'AN', search: 'Netherlands Antilles' },
    { val: 'AE', search: 'United Arab Emirates' },
    { val: 'AR', search: 'Argentina' },
    { val: 'AM', search: 'Armenia' },
    { val: 'AP', search: 'Asia (Unknown Country)' },
    { val: 'AS', search: 'American Samoa' },
    { val: 'AQ', search: 'Antarctica' },
    { val: 'TF', search: 'French Southern Territories' },
    { val: 'AG', search: 'Antigua And Barbuda' },
    { val: 'AU', search: 'Australia' },
    { val: 'AT', search: 'Austria' },
    { val: 'AZ', search: 'Azerbaijan' },
    { val: 'BI', search: 'Burundi' },
    { val: 'BE', search: 'Belgium' },
    { val: 'BJ', search: 'Benin' },
    { val: 'BQ', search: 'Bonaire/Sint Eustatius/Saba' },
    { val: 'BF', search: 'Burkina Faso' },
    { val: 'BD', search: 'Bangladesh' },
    { val: 'BG', search: 'Bulgaria' },
    { val: 'BH', search: 'Bahrain' },
    { val: 'BS', search: 'Bahamas' },
    { val: 'BA', search: 'Bosnia And Herzegowina' },
    { val: 'BL', search: 'Saint Barthelemy' },
    { val: 'BY', search: 'Belarus' },
    { val: 'BZ', search: 'Belize' },
    { val: 'BM', search: 'Bermuda' },
    { val: 'BO', search: 'Bolivia' },
    { val: 'BR', search: 'Brazil' },
    { val: 'BB', search: 'Barbados' },
    { val: 'BN', search: 'Brunei Darussalam' },
    { val: 'BT', search: 'Bhutan' },
    { val: 'BV', search: 'Bouvet Island' },
    { val: 'BW', search: 'Botswana' },
    { val: 'CF', search: 'Central African Republic' },
    { val: 'CA', search: 'Canada' },
    { val: 'CC', search: 'Cocos (Keeling) Islands' },
    { val: 'CH', search: 'Switzerland' },
    { val: 'CL', search: 'Chile' },
    { val: 'CN', search: 'China' },
    { val: 'CI', search: 'Cote D Ivoire' },
    { val: 'CM', search: 'Cameroon' },
    { val: 'CD', search: 'Democratic Republic Of The Congo' },
    { val: 'CG', search: 'Congo' },
    { val: 'CK', search: 'Cook Islands' },
    { val: 'CO', search: 'Colombia' },
    { val: 'KM', search: 'Comoros' },
    { val: 'CV', search: 'Cape Verde' },
    { val: 'CR', search: 'Costa Rica' },
    { val: 'CU', search: 'Cuba' },
    { val: 'CW', search: 'Curacao' },
    { val: 'CX', search: 'Christmas Island' },
    { val: 'KY', search: 'Cayman Islands' },
    { val: 'CY', search: 'Cyprus' },
    { val: 'CZ', search: 'Czech Republic' },
    { val: 'DE', search: 'Germany' },
    { val: 'DJ', search: 'Djibouti' },
    { val: 'DM', search: 'Dominica' },
    { val: 'DK', search: 'Denmark' },
    { val: 'DO', search: 'Dominican Republic' },
    { val: 'DZ', search: 'Algeria' },
    { val: 'EC', search: 'Ecuador' },
    { val: 'EG', search: 'Egypt' },
    { val: 'ER', search: 'Eritrea' },
    { val: 'EH', search: 'Western Sahara' },
    { val: 'ES', search: 'Spain' },
    { val: 'EE', search: 'Estonia' },
    { val: 'ET', search: 'Ethiopia' },
    { val: 'EU', search: 'Europe (Unknown Country)' },
    { val: 'FI', search: 'Finland' },
    { val: 'FJ', search: 'Fiji' },
    { val: 'FK', search: 'Falkland Islands (Malvinas)' },
    { val: 'FR', search: 'France' },
    { val: 'FO', search: 'Faroe Islands' },
    { val: 'FM', search: 'Federated States Of Micronesia' },
    { val: 'GA', search: 'Gabon' },
    { val: 'UK', search: 'United Kingdom' },
    { val: 'GE', search: 'Georgia' },
    { val: 'GG', search: 'Guernsey' },
    { val: 'GH', search: 'Ghana' },
    { val: 'GI', search: 'Gibraltar' },
    { val: 'GN', search: 'Guinea' },
    { val: 'GP', search: 'Guadeloupe' },
    { val: 'GM', search: 'Gambia' },
    { val: 'GW', search: 'Guinea-Bissau' },
    { val: 'GQ', search: 'Equatorial Guinea' },
    { val: 'GR', search: 'Greece' },
    { val: 'GD', search: 'Grenada' },
    { val: 'GL', search: 'Greenland' },
    { val: 'GT', search: 'Guatemala' },
    { val: 'GF', search: 'French Guiana' },
    { val: 'GU', search: 'Guam' },
    { val: 'GY', search: 'Guyana' },
    { val: 'HK', search: 'Hong Kong' },
    { val: 'HM', search: 'Heard And Mc Donald Islands' },
    { val: 'HN', search: 'Honduras' },
    { val: 'HR', search: 'Croatia' },
    { val: 'HT', search: 'Haiti' },
    { val: 'HU', search: 'Hungary' },
    { val: 'ID', search: 'Indonesia' },
    { val: 'IM', search: 'Isle Of Man' },
    { val: 'IN', search: 'India' },
    { val: 'IO', search: 'British Indian Ocean Territory' },
    { val: 'IE', search: 'Ireland' },
    { val: 'IR', search: 'Iran (Islamic Republic Of)' },
    { val: 'IQ', search: 'Iraq' },
    { val: 'IS', search: 'Iceland' },
    { val: 'IL', search: 'Israel' },
    { val: 'IT', search: 'Italy' },
    { val: 'JM', search: 'Jamaica' },
    { val: 'JE', search: 'Jersey' },
    { val: 'JO', search: 'Jordan' },
    { val: 'JP', search: 'Japan' },
    { val: 'KZ', search: 'Kazakhstan' },
    { val: 'KE', search: 'Kenya' },
    { val: 'KG', search: 'Kyrgyzstan' },
    { val: 'KH', search: 'Cambodia' },
    { val: 'KI', search: 'Kiribati' },
    { val: 'KN', search: 'Saint Kitts And Nevis' },
    { val: 'KR', search: 'South Korea' },
    { val: 'KW', search: 'Kuwait' },
    { val: 'LA', search: 'Lao Peoples Democratic Republic' },
    { val: 'LB', search: 'Lebanon' },
    { val: 'LR', search: 'Liberia' },
    { val: 'LY', search: 'Libyan Arab Jamahiriya' },
    { val: 'LC', search: 'Saint Lucia' },
    { val: 'LI', search: 'Liechtenstein' },
    { val: 'LK', search: 'Sri Lanka' },
    { val: 'LS', search: 'Lesotho' },
    { val: 'LT', search: 'Lithuania' },
    { val: 'LU', search: 'Luxembourg' },
    { val: 'LV', search: 'Latvia' },
    { val: 'MO', search: 'Macau' },
    { val: 'MF', search: 'Saint Martin' },
    { val: 'MA', search: 'Morocco' },
    { val: 'MC', search: 'Monaco' },
    { val: 'MD', search: 'Moldova' },
    { val: 'MG', search: 'Madagascar' },
    { val: 'MV', search: 'Maldives' },
    { val: 'MX', search: 'Mexico' },
    { val: 'MH', search: 'Marshall Islands' },
    { val: 'MK', search: 'Macedonia' },
    { val: 'ML', search: 'Mali' },
    { val: 'MT', search: 'Malta' },
    { val: 'MM', search: 'Myanmar' },
    { val: 'ME', search: 'Montenegro' },
    { val: 'MN', search: 'Mongolia' },
    { val: 'MP', search: 'Northern Mariana Islands' },
    { val: 'MZ', search: 'Mozambique' },
    { val: 'MR', search: 'Mauritania' },
    { val: 'MS', search: 'Montserrat' },
    { val: 'MQ', search: 'Martinique' },
    { val: 'MU', search: 'Mauritius' },
    { val: 'MW', search: 'Malawi' },
    { val: 'MY', search: 'Malaysia' },
    { val: 'YT', search: 'Mayotte' },
    { val: 'NA', search: 'Namibia' },
    { val: 'NC', search: 'New Caledonia' },
    { val: 'NE', search: 'Niger' },
    { val: 'NF', search: 'Norfolk Island' },
    { val: 'NG', search: 'Nigeria' },
    { val: 'NI', search: 'Nicaragua' },
    { val: 'NU', search: 'Niue' },
    { val: 'NL', search: 'Netherlands' },
    { val: 'NO', search: 'Norway' },
    { val: 'NP', search: 'Nepal' },
    { val: 'NR', search: 'Nauru' },
    { val: 'NZ', search: 'New Zealand' },
    { val: 'OM', search: 'Oman' },
    { val: 'PK', search: 'Pakistan' },
    { val: 'PA', search: 'Panama' },
    { val: 'PN', search: 'Pitcairn' },
    { val: 'PE', search: 'Peru' },
    { val: 'PH', search: 'Philippines' },
    { val: 'PW', search: 'Palau' },
    { val: 'PG', search: 'Papua New Guinea' },
    { val: 'PL', search: 'Poland' },
    { val: 'PR', search: 'Puerto Rico' },
    { val: 'KP', search: 'North Korea' },
    { val: 'PT', search: 'Portugal' },
    { val: 'PY', search: 'Paraguay' },
    { val: 'PS', search: 'Palestinian Territories' },
    { val: 'PF', search: 'French Polynesia' },
    { val: 'QA', search: 'Qatar' },
    { val: 'RE', search: 'Reunion' },
    { val: 'RO', search: 'Romania' },
    { val: 'RU', search: 'Russian Federation' },
    { val: 'RW', search: 'Rwanda' },
    { val: 'SA', search: 'Saudi Arabia' },
    { val: 'SD', search: 'Sudan' },
    { val: 'SN', search: 'Senegal' },
    { val: 'SG', search: 'Singapore' },
    { val: 'GS', search: 'South Georgia / South Sandwich Isl' },
    { val: 'SH', search: 'St. Helena' },
    { val: 'SJ', search: 'Svalbard And Jan Mayen Islands' },
    { val: 'SB', search: 'Solomon Islands' },
    { val: 'SL', search: 'Sierra Leone' },
    { val: 'SV', search: 'El Salvador' },
    { val: 'SM', search: 'San Marino' },
    { val: 'SO', search: 'Somalia' },
    { val: 'PM', search: 'St. Pierre And Miquelon' },
    { val: 'RS', search: 'Serbia' },
    { val: 'SS', search: 'South Sudan' },
    { val: 'ST', search: 'Sao Tome And Principe' },
    { val: 'SR', search: 'Suriname' },
    { val: 'SK', search: 'Slovakia (Slovak Republic)' },
    { val: 'SI', search: 'Slovenia' },
    { val: 'SE', search: 'Sweden' },
    { val: 'SZ', search: 'Swaziland' },
    { val: 'SX', search: 'Sint Maarten' },
    { val: 'SC', search: 'Seychelles' },
    { val: 'SY', search: 'Syrian Arab Republic' },
    { val: 'TC', search: 'Turks And Caicos Islands' },
    { val: 'TD', search: 'Chad' },
    { val: 'TG', search: 'Togo' },
    { val: 'TH', search: 'Thailand' },
    { val: 'TJ', search: 'Tajikistan' },
    { val: 'TK', search: 'Tokelau' },
    { val: 'TM', search: 'Turkmenistan' },
    { val: 'TL', search: 'Timor-Leste' },
    { val: 'TO', search: 'Tonga' },
    { val: 'TT', search: 'Trinidad And Tobago' },
    { val: 'TN', search: 'Tunisia' },
    { val: 'TR', search: 'Turkey' },
    { val: 'TV', search: 'Tuvalu' },
    { val: 'TW', search: 'Taiwan Province Of China' },
    { val: 'TZ', search: 'Tanzania' },
    { val: 'UG', search: 'Uganda' },
    { val: 'UA', search: 'Ukraine' },
    { val: 'UM', search: 'Us Minor Outlying Islands' },
    { val: 'UY', search: 'Uruguay' },
    { val: 'US', search: 'United States' },
    { val: 'UZ', search: 'Uzbekistan' },
    { val: 'VA', search: 'Holy See (Vatican City State)' },
    { val: 'VC', search: 'Saint Vincent And The Grenadines' },
    { val: 'VE', search: 'Venezuela' },
    { val: 'VG', search: 'British Virgin Islands' },
    { val: 'VI', search: 'Us Virgin Islands' },
    { val: 'VN', search: 'Viet Nam' },
    { val: 'VU', search: 'Vanuatu' },
    { val: 'WF', search: 'Wallis And Futuna Islands' },
    { val: 'WS', search: 'Samoa' },
    { val: 'YE', search: 'Yemen' },
    { val: 'ZA', search: 'South Africa' },
    { val: 'ZM', search: 'Zambia' },
    { val: 'ZW', search: 'Zimbabwe' }
 ];
