import { Injectable } from '@angular/core';
import { HttpClient,
         HttpInterceptor,
         HttpHandler,
         HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, timeout, retry } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { Campaign } from './campaigns';
import { CountStats } from './stats';
import { AuthService } from './auth/auth.service';

const API_URL = environment.apiUrl;

export interface ClickStatsResponse {
  [campaignID: string]: CountStats;
}

export interface NodesClickStatsReponse {
  [nodeID: string]: ClickStatsResponse;
}

export interface NodeListResponse {
  id: string;
  name: string;
  status: string;
  team_id: string;
  campaigns: Campaign[];
  version: string;
}

export interface APIResponse {
  data: any;
  errors: string[];
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = localStorage.getItem('access_token');

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) { }

  getUser(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/user`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Getting user', <APIResponse>{}))
      );
  }

  checkAccountExists(accountID: string): Observable<any> {
    return this.http.get<any>(`${API_URL}/accountexists/${accountID}`)
    .pipe(
      timeout(10000),
      catchError(this.handleError('checking if account exists', <any>{}))
    );
  }

  exportAccount(): Observable<Blob> {
    return this.http.get<Blob>(`${API_URL}/account/export`, {responseType: 'blob' as 'json'})
    .pipe(
      timeout(10000),
      catchError(this.handleError('exporting account', <any>{}))
    );
  }

  importAccount(accountFormData: FormData): Observable<any> {
    return this.http.post<APIResponse>(`${API_URL}/account/import`, accountFormData)
    .pipe(
      timeout(10000),
      catchError(this.handleError('importing account', <APIResponse>{}))
    );
  }

  changePassword(): Observable<any> {
    return this.http.post<APIResponse>(`${API_URL}/changepassword`, {})
      .pipe(
        timeout(10000),
        catchError(this.handleError('Changing password', <APIResponse>{}))
      );
  }

  getNode(teamID: string, nodeID: string): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Getting node', <APIResponse>{}))
      );
  }

  updateNode(teamID: string, nodeID: string): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}/update`, {})
      .pipe(
        timeout(10000),
        catchError(this.handleError('Updating node', <APIResponse>{}))
      );
  }

  updateNodes(teamID: string): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${API_URL}/nodes/update`, {})
      .pipe(
        timeout(10000),
        catchError(this.handleError('Updating nodes', <APIResponse>{}))
      );
  }

  getCampaign(teamID: string, nodeID: string, campID: string): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}/campaigns/${campID}`)
      .pipe(
        timeout(7000),
        retry(2),
        catchError(this.handleError('Getting campaign', <APIResponse>{}))
      );
  }

  getCampaigns(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/overview`)
      .pipe(
        timeout(30000),
        catchError(this.handleError('Getting overview', <APIResponse>{}))
      );
  }

  newCampaign(teamID: string, camp: Campaign): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${camp.nodeID}/campaigns`, camp)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Saving new campaign', <APIResponse>{}))
      );
  }

  updateCampaign(teamID: string, id: string, camp: Campaign): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${camp.nodeID}/campaigns/${id}`, camp)
      .pipe(
        timeout(7000),
        retry(2),
        catchError(this.handleError('Updating campaign', <APIResponse>{}))
      );
  }

  getNodeStatExports(teamID: string, nodeID: string): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}/exports`)
      .pipe(
        timeout(10000),
        retry(2),
        catchError(this.handleError('Getting node stat exports', <APIResponse>{}))
      );
  }

  exportCampaignStats(teamID: string, camp: Campaign, from: number, to: number): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${camp.nodeID}/exports`, {
      campaign_id: camp.id,
      from: from.toString(), 
      to: to.toString(),
    })
      .pipe(
        timeout(10000),
        catchError(this.handleError('Exporting stats', <APIResponse>{}))
      );
  }

  deleteExport(teamID: string, nodeID: string, exportName: string): Observable<APIResponse> {
    return this.http.delete<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}/exports/${exportName}`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Deleting export', <APIResponse>{}))
      );
  }

  updateCampaignPostbacks(teamID: string, id: string, nodeID: string, camp: Campaign): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}/campaigns/${id}/postbacks`, camp)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Updating campaign postbacks', <APIResponse>{}))
      );
  }

  deleteCampaign(teamID: string, nodeID: string, campID): Observable<APIResponse> {
    return this.http.delete<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}/campaigns/${campID}`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Deleting campaign', <APIResponse>{}))
      );
  }

  deleteNode(teamID: string, nodeID: string): Observable<APIResponse> {
    return this.http.delete<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Deleting node', <APIResponse>{}))
      );
  }

  getNodeList(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/nodes`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Getting node list', <APIResponse>{}))
      );
  }

  getOverview(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/overview`)
      .pipe(
        timeout(30000),
        catchError(this.handleError('Getting campaign list', <APIResponse>{}))
      );
  }

  getCounts(from: number, to: number, interval?: string): Observable<APIResponse> {
    let endpoint = `/campaigns/counts`;
    endpoint += `?from=${from}&to=${to}`;
    if (interval) {
      endpoint += `&interval=${interval}`;
    }
    return this.http.get<APIResponse>(`${API_URL}${endpoint}`)
      .pipe(
        timeout(30000),
        catchError(this.handleError('Getting count', <APIResponse>{}))
      );
  }

  getCampaignCounts(teamID: string, nodeID: string, campID: string,
   from: number, to: number, interval?: string): Observable<APIResponse> {
    let endpoint = `/teams/${teamID}/nodes/${nodeID}/campaigns/${campID}/counts`;
    endpoint += `?from=${from}&to=${to}`;
    if (interval) {
      endpoint += `&interval=${interval}`;
    }
    return this.http.get<APIResponse>(`${API_URL}${endpoint}`)
      .pipe(
        timeout(30000),
        catchError(this.handleError('Getting campaign count', <APIResponse>{}))
      );
  }

  getStatsFields(teamID: string, nodeID: string, campID: string): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}/campaigns/${campID}/fields`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Getting fields', <APIResponse>{}))
      );
  }

  getStats(teamID: string, nodeID: string, campID: string, group: string[], sortBy: string, from: number, to: number, page?: number, filter?: object)
  : Observable<APIResponse> {
    const groupcsv = group.toString();

    // convert filter object to url params
    let keys = Object.keys(filter);
    let filterParams = '';
    for (let i = 0; i < keys.length; i++) {
      filterParams += keys[i] + ':' + filter[keys[i]];
      if (i != keys.length - 1) {
        filterParams += ',';
      }
    }

    let u = `${API_URL}/teams/${teamID}/nodes/${nodeID}/campaigns/${campID}/stats?groupby=${groupcsv}`;
    u += `&from=${from}&to=${to}`;
    if (page) {
      u += `&page=${page}`;
    }
    u += `&sort=${sortBy}`;

    if (filterParams) {
      u += `&filter=${filterParams}`;
    }

    return this.http.get<APIResponse>(u)
      .pipe(
        timeout(50000),
        catchError(this.handleError('Getting stats', <APIResponse>{}))
      );
  }

  getLandingPageStats(teamID: string, nodeID: string, campID: string, from: number, to: number) {
    let u = `${API_URL}/teams/${teamID}/nodes/${nodeID}/campaigns/${campID}/stats/landingpages`;
    u += `?from=${from}&to=${to}`;
    return this.http.get<APIResponse>(u)
      .pipe(
        timeout(20000),
        catchError(this.handleError('Getting landing page stats', <APIResponse>{}))
      );
  }

  getOfferStats(teamID: string, nodeID: string, campID: string, from: number, to: number) {
    let u = `${API_URL}/teams/${teamID}/nodes/${nodeID}/campaigns/${campID}/stats/offers`;
    u += `?from=${from}&to=${to}`;
    return this.http.get<APIResponse>(u)
      .pipe(
        timeout(20000),
        catchError(this.handleError('Getting offer stats', <APIResponse>{}))
      );
  }

  getNewNodeCommand(teamID: string): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${API_URL}/teams/${teamID}/command`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Getting install command', <APIResponse>{}))
      );
  }

  updateNodeName(teamID: string, nodeID: string, name: string) {
    return this.http.post<APIResponse>(`${API_URL}/teams/${teamID}/nodes/${nodeID}/name`, {name: name})
      .pipe(
        timeout(10000),
        catchError(this.handleError('Updating node name', <APIResponse>{}))
      );
  }

  getIPaddress() {
    return this.http.get<APIResponse>(`${API_URL}/userip`)
      .pipe(
        timeout(10000),
        catchError(this.handleError('Getting IP address', <APIResponse>{}))
      );
  }

  /**
   * Handle Http operation that failed.
   * Return the error to the subscriber.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'Operation', result?: T) {
    return (error: any): Observable<T> => {
      switch (error.status) {
      case 0:
        error.message = 'No internet connection';
        break; // ignore
      case 401:
        this.authService.logout();
        return;
      case 403:
        this.router.navigate(['/error/403']);
        return;
      }

      error.message = `${operation} failed: ${error.message}`;

      // pass error on to caller
      return throwError(error);
    };
  }

}

