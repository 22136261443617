import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-callback',
  template: `
    <app-spinner [show]="true" options="mt-5 la-dark la-4x"></app-spinner>
  `,
  styles: [`
  `]
})
export class LoginCallbackComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }

}
