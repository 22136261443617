import { Directive, ViewContainerRef, TemplateRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[ifVersion]'
})
export class IfVersionDirective implements OnChanges {

  constructor(
    private container: ViewContainerRef,
    private template: TemplateRef<any>
  ) { }


  GREATERTHAN = '>';
  GREATERTHANOREQUALS = '>=';
  LESSTHAN = '<';
  LESSTHANOREQUALS = '<=';
  EQUALS = '=';

  @Input() ifVersion: string;
  @Input() ifVersionCompare: string;

  versionEquals(len, a, b) {
    for (let i = 0; i < len; i++) {
      if (a[i] !== b[i]) { return false; }
    }
    return true;
  }

  lessThan(len, a, b) {
    for (let i = 0; i < len; i++) {
      if (a[i] < b[i]) { return true; }
      if (b[i] < a[i]) { return false; }
    }
  }

  greaterThan(len, a, b) {
    for (let i = 0; i < len; i++) {
      if (a[i] > b[i]) { return true; }
      if (b[i] > a[i]) { return false; }
    }
  }

  compare(operation, matchVersion, compareVersion): boolean {
    const match = matchVersion.split('.');
    const compare = compareVersion.split('.');
    // invalid input
    if (match.length === 0 || compare.length === 0) {
      return false;
    }

    // make versions the same length
    const largestLength = Math.max(match.length, compare.length);
    for (let i = match.length; i < largestLength; i++) {
      match.push('0');
    }
    for (let i = compare.length; i < largestLength; i++) {
      compare.push('0');
    }

    switch (operation) {
      case this.GREATERTHANOREQUALS:
        return (this.versionEquals(largestLength, compare, match) || this.greaterThan(largestLength, compare, match));

      case this.GREATERTHAN:
        return (!this.versionEquals(largestLength, compare, match) && this.greaterThan(largestLength, compare, match));

      case this.LESSTHANOREQUALS:
        return (this.versionEquals(largestLength, compare, match) || this.lessThan(largestLength, compare, match));

      case this.LESSTHAN:
        return (!this.versionEquals(largestLength, compare, match) && this.lessThan(largestLength, compare, match));

      case this.EQUALS:
        return this.versionEquals(largestLength, compare, match);

      }
  }

  ngOnChanges() {
    // clear the container so we do not keep appending views
    this.container.clear();


    // do not display if input is invalid
    if ((!this.ifVersion) || (!this.ifVersionCompare)) {
      return;
    }

    const versionInput = this.ifVersion.trim();
    const versionToCompare = this.ifVersionCompare.trim();

    // get operator
    let operator = versionInput.substring(0, 2);
    let versionToMatch = '';
    if (operator.charAt(1) === '=') {
      versionToMatch = versionInput.substring(2);
    } else {
      operator = operator.charAt(0);
      versionToMatch = versionInput.substring(1);
    }

    if (this.compare(operator, versionToMatch, versionToCompare)) {
      this.container.createEmbeddedView(this.template);
    }
  }

}
