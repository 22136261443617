import { Component, OnInit, OnDestroy } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Subject} from 'rxjs/Subject';
import { takeWhile } from 'rxjs/operators';

import { Campaign } from '../../campaigns';
import { CampaignDetailService } from '../campaign-detail.service';
import { ApiService, NodeListResponse } from '../../api.service';
import { NewLandingPage, NewOfferPage, LandingPage, OfferPage } from '../../pages';
import { ProgressBarService } from '../../progress-bar.service';
import { NodeDetailService } from '../node-detail.service';


@Component({
  selector: 'app-campaign-setup',
  templateUrl: './campaign-setup.component.html',
  styleUrls: ['./campaign-setup.component.css']
})
export class CampaignSetupComponent implements OnInit, OnDestroy {
  campaign: Campaign;
  node: NodeListResponse;
  savedEvent$ = new Subject<void>();
  loading = true;
  alive = true;
  existingRoutes: string[] = [];

  constructor(
    private nodeDetailService: NodeDetailService,
    private campaignDetailService: CampaignDetailService,
    private apiService: ApiService,
    private progressBarService: ProgressBarService,
  ) { }

  // for campaigns on node version < 2.0.0
  campaignSaved(camp: Campaign) {
    this.campaignDetailService.clearCache();
    this.progressBarService.start();
    this.apiService.updateCampaign(this.campaignDetailService.teamID, camp.id, camp)
    .finally(() => {
      this.savedEvent$.next();
      this.progressBarService.done();
    })
    .subscribe();
  }

  ngOnInit() {

    forkJoin([
      this.campaignDetailService.getCampaign(),
      this.campaignDetailService.getNode(),
      this.nodeDetailService.getNodeRoutes(),
    ])
    .pipe( takeWhile(() => this.alive) )
    .subscribe(([campaignResult, nodeResult, nodeRoutes]) => {
      // central strips empty pages. re-insert if neeeded
      if (campaignResult.landing_pages.length === 0) { campaignResult.landing_pages.push(NewLandingPage()); }
      if (campaignResult.offers.length === 0) { campaignResult.offers.push(NewOfferPage()); }

      const majorVersionIndex = nodeResult.version.indexOf('.');
      let nodeMajorVersion = 1; // set to 1 as default. a node should always have a version
      if (majorVersionIndex > -1) {
        nodeMajorVersion = parseInt(nodeResult.version.substring(0, majorVersionIndex), 10);
      }

      // only append noipfraud pages and offers to a campaigns belonging to a node of version < 2
      if (nodeMajorVersion < 2) {
        if (!campaignResult.noipfraud.landing_pages) {
          campaignResult.noipfraud.landing_pages = new Array<LandingPage>();
        }
        if (!campaignResult.noipfraud.offers) {
          campaignResult.noipfraud.offers = new Array<OfferPage>();
        }
        if (campaignResult.noipfraud.landing_pages.length === 0) { campaignResult.noipfraud.landing_pages.push(NewLandingPage()); }
        if (campaignResult.noipfraud.offers.length === 0) { campaignResult.noipfraud.offers.push(NewOfferPage()); }
      }

      this.campaign = campaignResult;
      this.node = nodeResult;
      this.existingRoutes = nodeRoutes.filter(function(v) {
        // remove current campaign route from list
        return(v !== campaignResult.route);
      })
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
