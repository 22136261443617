import { LandingPage, OfferPage } from './pages';

export interface Noipfraud {
    enabled: boolean;
    b64_encoding_enabled: boolean;
    block_all: boolean;
    ad_block_all: boolean;
    ad_block_all_clicks: number;
    traffic_source_id: string;
    landing_pages?: LandingPage[];
    offers?: OfferPage[];
    exp_enabled: boolean;
    conditions: {
        rule: string;
        allow_only: boolean;
        value: any;
        block_next: {
            enabled: boolean,
            clicks: number
        }
    }[];
    ip_whitelist: string[];
}

interface RuleType {
    id: string;
    name: string;
    type: string;
    values?: {
        id: string;
        name: string;
    }[];
}

export const RuleTypes: RuleType[] = [
    {
        id: 'urlfilter',
        name: 'URL Parameter',
        type: 'urlfilter'
    },
    {
        id: 'httpheaders',
        name: 'HTTP Headers',
        type: 'fieldvalue'
    },
    {
        id: 'browserlanguage', // special rule that pre-fills the http header rule
        name: 'Browser Language',
        type: 'fieldvalue'
    },
    {
        id: 'frequencycap',
        name: 'Frequency Cap',
        type: 'frequencycap'
    },
    {
        id: 'mobile',
        name: 'Mobile',
        type: 'boolean'
    },
    {
        id: 'host',
        name: 'Host',
        type: 'array'
    },
    {
        id: 'org',
        name: 'Org',
        type: 'array'
    },
    {
        id: 'isp',
        name: 'ISP',
        type: 'array'
    },
    {
        id: 'carrier',
        name: 'Carrier',
        type: 'array'
    },
    {
        id: 'country',
        name: 'Country',
        type: 'country'
    },
    {
        id: 'city',
        name: 'City',
        type: 'array'
    },
    {
        id: 'state',
        name: 'State',
        type: 'array'
    },
    {
        id: 'referer',
        name: 'Referer',
        type: 'array'
    },
    {
        id: 'ua',
        name: 'User Agent',
        type: 'array'
    },
    {
        id: 'os',
        name: 'OS',
        type: 'multi',
        values: [
            { id: 'macos', name: 'macOS' },
            { id: 'ios', name: 'iOS' },
            { id: 'windows', name: 'Windows' },
            { id: 'android', name: 'Android' },
            { id: 'linux', name: 'Linux' }
        ]
    },
    {
        id: 'browser',
        name: 'Browser',
        type: 'browser',
    },
    {
        id: 'ip',
        name: 'IP Address',
        type: 'list'
    },
];

export const TrafficSources = [
    { id: '54218f34454c61f813000001', name: 'Facebook' },
    { id: '54218e50454c619c63000068', name: 'Google' },
    { id: '627b92b32df4c4f99317eb56', name: 'TikTok', label: 'NEW' },
    { id: '650059245a0f3be176f7a543', name: 'X (Twitter)', label: 'NEW' },
    { id: '54218f65454c61ec4b000115', name: 'Bing adCenter' },
    { id: '55bf7597646174007300004b', name: 'Yahoo Gemini' },
    { id: '5b44ee0e2a516b1040ea573c', name: 'Snapchat' },
    { id: '59301122c88dcc5d2dea18c0', name: 'Pinterest' },
    { id: '558d88646461740094000045', name: 'RevContent' },
    { id: '558d852564617400a8000046', name: 'Taboola' },
    { id: '558d7ded64617400a8000044', name: 'Content.Ad' },
    { id: '5642430206bf40e469000000', name: 'Nativeads.com' },
    { id: '575870d7d8d124001fa53a6d', name: 'MGID' },
    { id: '575a8902b8f7cf0c05eb003f', name: 'MyLikes' },
    { id: '55b110d764617400a80000cd', name: 'Outbrain' },
    { id: '54ab225d1166a776078b4568', name: 'Decisive' },
    { id: '56e2da696bfa29ad078b4567', name: 'ExoClick' },
    { id: '54219046454c61056500000d', name: 'OddBytes' },
    { id: '55e5863fe59615aa1a000001', name: 'AdBlade' },
    { id: '54ab25ed1166a776078b4569', name: 'Leadbolt' },
    //{ id: '54219063454c61f813000034', name: 'Lead Impact' },
    //{ id: '54a6f0871166a78a088b4567', name: 'Airpush' },
    //{ id: '56e2b7f1105082cb0f5aff83', name: '50onRed' },
];

export function GetTrafficSourceName(id: string): string {
    for (const ts of TrafficSources) {
        if (ts.id === id) {
            return ts.name;
        }
    }
    return '';
}

export function GetRuleName(type: string): string {
    for (const r of RuleTypes) {
        if (r.id === type) {
            return r.name;
        }
    }
    return type;
}

export function GetRuleType(type: string): string {
    for (const r of RuleTypes) {
        if (r.id === type) {
            return r.type;
        }
    }
    return type;
}

export function GetRuleValues(type: string): any {
    for (const r of RuleTypes) {
        if (r.id === type) {
            return r.values;
        }
    }
    return type;
}
