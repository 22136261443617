import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginCallbackComponent } from './auth/login-callback.component';
import { ErrorComponent } from './error/error.component';
import { OverviewComponent } from './overview/overview.component';
import { CampaignDetailComponent } from './campaign-detail/campaign-detail.component';
import { CampaignOverviewComponent } from './campaign-detail/campaign-overview/campaign-overview.component';
import { CampaignStatsComponent } from './campaign-detail/campaign-stats/campaign-stats.component';
import { CampaignSetupComponent } from './campaign-detail/campaign-setup/campaign-setup.component';
import { CampaignNewComponent } from './campaign-new/campaign-new.component';
import { ImportAccountComponent} from './import-account/import-account.component';

import { AuthGuard } from './auth/auth-guard.service';
import { ImportGuard } from './auth/import-guard.service';

const routes: Routes = [
    { path: '', redirectTo: '/overview', pathMatch: 'full' },
    { path: 'login', redirectTo: '/overview', pathMatch: 'full' },
    { path: 'authcallback', component: LoginCallbackComponent },
    { path: 'importaccount', component: ImportAccountComponent, canActivate: [ImportGuard], pathMatch: 'full' },
    { path: 'overview', component: OverviewComponent, canActivate: [AuthGuard] },
    { path: 'team/:teamid/node/:nodeid/campaign/new', component: CampaignNewComponent, canActivate: [AuthGuard] },
    {
        path: 'team/:teamid/node/:nodeid/campaign/:campid',
        canActivate: [AuthGuard],
        component: CampaignDetailComponent,
        children: [
            {path: '', redirectTo: 'overview', pathMatch: 'full'},
            {path: 'overview', component: CampaignOverviewComponent},
            {path: 'stats', component: CampaignStatsComponent},
            {path: 'setup', component: CampaignSetupComponent}
        ]
    },
    { path: 'error/:code', component: ErrorComponent },
    { path: '**', redirectTo: '/error/404' }
];


@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
