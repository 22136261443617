import { Component, OnInit, ViewChild } from '@angular/core';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ProgressBarService } from './progress-bar.service';

@Component({
  selector: 'app-progress-bar',
  template: `
    <ng-progress [spinner]="false"></ng-progress>
  `,
})
export class ProgressBarComponent implements OnInit {

  infoColor = '#2780E3';
  successColor = '#329414';

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  constructor(
    private progressBarService: ProgressBarService
    ) { }

  resetBar() {
    this.progressBar.speed = 300;
    this.progressBar.color = this.infoColor;
  }

  setBarSuccess() {
    this.progressBar.color = this.successColor;
    this.progressBar.speed = 500; // extend transition period of bar when showing success
  }

  ngOnInit() {
    this.resetBar();

    this.progressBarService.start$.subscribe(() => {
      this.progressBar.start();
    });
    this.progressBarService.done$.subscribe(() => {
      this.setBarSuccess();
      this.progressBar.complete();
    });

    this.progressBar.completed.subscribe(() => {
      this.resetBar();
    });

  }

}
