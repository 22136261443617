import { Component, OnInit, AfterViewInit,
          ViewChild, ElementRef, HostListener,
          Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';

import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Timezone } from './timezones';
import * as moment from 'moment-timezone';
import { UserSettingsService } from './user-settings.service';
import { TimezoneService } from './timezone.service';
import { Subject } from 'rxjs';

export interface DateStruct {
  from: NgbDateStruct;
  to: NgbDateStruct;
  display: string;
  _range: string;
}

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-date-range',
  template: `
    <button #btn (click)="$event.stopPropagation()" type="button" class="btn btn-light"
      (click)="toggleCal()">
      <span class="oi oi-calendar oi-sm mr-1"></span>
      {{ displayDate }} <span class="oi oi-caret-bottom oi-sm ml-1"></span>
    </button>

    <div #cal (click)="$event.stopPropagation()" class="cal" [style.top.px]="calY" [style.left.px]="calX">
      <div class="date-select-buttons">
        <button (click)="selectRange('today')" class="btn btn-outline-primary btn-sm">Today</button>
        <button (click)="selectRange('yesterday')" class="btn btn-outline-primary btn-sm">Yesterday</button>
        <button (click)="selectRange('7 days')" class="btn btn-outline-primary btn-sm">7 days</button>
        <button (click)="selectRange('30 days')" class="btn btn-outline-primary btn-sm">30 days</button>
        <button (click)="selectRange('this month')" class="btn btn-outline-primary btn-sm">This month</button>
        <button (click)="selectRange('last month')" class="btn btn-outline-primary btn-sm">Last month</button>
      </div>
      <ngb-datepicker #dp ngModel (select)="onDateChange($event)" [displayMonths]="2" [dayTemplate]="t">
      </ngb-datepicker>
    </div>

    <ng-template #t let-date="date" let-focused="focused">
      <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>
  `,
  styles: [`
    .date-select-buttons {
      padding-bottom: .5rem;
    }
    .cal {
      float: left;
      z-index: 999;
      background: #fff;
      position: absolute;
      border: 1px solid rgba(0,0,0,.15);
      padding: .5rem;
    }
    .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
    }
    .custom-day.focused {
        background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
    }
    .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
    }
  `],
  providers: [DatePipe]
})
export class DateRangeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() set startDate(dt: DateStruct) {
    // set once at init
    if (!dt || dt == null) {
      setTimeout(() => { this.selectRange('today'); });
    } else {
      this.fromDate = dt.from;
      this.toDate = dt.to;
      this.displayDate = dt.display;
    }
  }
  @Input() refreshTimeRange: Subject<void>;
  @Output() dateChanged: EventEmitter<DateStruct> = new EventEmitter();

  @ViewChild('btn') btn: ElementRef;
  @ViewChild('cal') cal: ElementRef;

  showCal = false;
  calX = -1000;
  calY = -1000;
  tz: Timezone;

  hoveredDate: NgbDateStruct;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  displayDate: string;

  @HostListener('document:click', ['$event']) clickedOutside($event) {
    this.hideCal();
  }

  constructor(
    private datePipe: DatePipe,
    private calendar: NgbCalendar,
    private userService: UserSettingsService,
    private timezoneService: TimezoneService
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.tz = userService.timezone;

    // listen to timezone changes.
    // When a timezone selection occurs, re-calculate the timerange for
    // the selected timezone if a dynamic range is selected
    this.timezoneService.change.subscribe(tz => {
      this.tz = tz;
      if (this.selectedRangeDynamic(userService.selectedDates._range)) {
        this.selectRange(userService.selectedDates._range);
      } else {
        this.dateChanged.emit({ from: this.userService.selectedDates.from,
          to: this.userService.selectedDates.to,
          display: this.userService.selectedDates.display,
          _range: this.userService.selectedDates._range
        });
      }
    });
  }

  private selectedRangeDynamic(range: string): boolean {
    switch (range) {
      case 'today':
      case 'yesterday':
      case '7 days':
      case '30 days':
      case 'this month':
      case 'last month':
        return true;
      default:
        return false;
    }
  }

  private setCalPos() {
    if (this.showCal) {
      this.calX = this.btn.nativeElement.offsetLeft - (this.cal.nativeElement.offsetWidth - this.btn.nativeElement.offsetWidth);
      this.calY = this.btn.nativeElement.offsetTop + this.btn.nativeElement.offsetHeight + 2;
    } else {
      this.calX = -1000;
      this.calY = -1000;
    }
  }

  toggleCal() {
    this.showCal = !this.showCal;
    this.setCalPos();
  }

  hideCal() {
    this.showCal = false;
    this.setCalPos();
  }

  selectRange(when: string) {
    const n = moment().utc();
    // use time relative to selected timezone
    const now = moment.tz({
      year: n.year(),
      month: n.month(),
      day: n.date(),
      hour: n.hour(),
      minute: n.minute(),
    }, this.tz.name);
    now.add(now.utcOffset(), 'm');

    const past = now.clone();
    switch (when) {
      case 'today':
        this.fromDate = {year: now.year(), month: now.month() + 1, day: now.date()};
        this.toDate = {year: now.year(), month: now.month() + 1, day: now.date()};
        break;
      case 'yesterday':
        past.subtract(1, 'day');
        this.fromDate = {year: past.year(), month: past.month() + 1, day: past.date()};
        this.toDate = {year: past.year(), month: past.month() + 1, day: past.date()};
        break;
      case '7 days':
         past.subtract(6, 'day');
        this.fromDate = {year: past.year(), month: past.month() + 1, day: past.date()};
        this.toDate = {year: now.year(), month: now.month() + 1, day: now.date()};
        break;
      case '30 days':
        past.subtract(29, 'day');
        this.fromDate = {year: past.year(), month: past.month() + 1, day: past.date()};
        this.toDate = {year: now.year(), month: now.month() + 1, day: now.date()};
        break;
      case 'this month':
        this.fromDate = {year: now.year(), month: now.month() + 1, day: 1};
        this.toDate = {year: now.year(), month: now.month() + 1, day: now.daysInMonth()};
        break;
      case 'last month':
        past.subtract(1, 'month');
        this.fromDate = {year: past.year(), month: past.month() + 1, day: 1};
        this.toDate = {year: past.year(), month: past.month() + 1, day: past.daysInMonth()};
        break;
      default:
        return;
    }

    this.displayDate = when.charAt(0).toUpperCase() + when.slice(1);
    this.dateChanged.emit({ from: this.fromDate, to: this.toDate, display: this.displayDate, _range: when });
    this.hideCal();
  }

  onDateChange(date: NgbDateStruct) {
    let done = false;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && ( equals(date, this.fromDate) || after(date, this.fromDate)) ) {
      this.toDate = date;
      done = true;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (done) {
      this.displayDate = this.datePipe.transform(
        `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
        'MMM d'
      );
      this.displayDate += ' - ' + this.datePipe.transform(
        `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`,
        ((this.fromDate.month !== this.toDate.month) ? 'MMM d' : 'd')
      );
      this.dateChanged.emit({ from: this.fromDate, to: this.toDate, display: this.displayDate, _range: this.displayDate });
      this.hideCal();
    }
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  ngOnInit() {
    // listen to requests to refresh the time (for when the app is open for long periods)
    this.refreshTimeRange.subscribe(() => {
      if (this.selectedRangeDynamic(this.userService.selectedDates._range)) {
        // if the user has a dynamic range selected, calculate query date.
        this.selectRange(this.userService.selectedDates._range);
      } else {
        // user has defined range selected, emit regular date event
        this.dateChanged.emit({ from: this.userService.selectedDates.from,
          to: this.userService.selectedDates.to,
          display: this.userService.selectedDates.display,
          _range: this.userService.selectedDates._range
        });
      }
    });
  }

  ngAfterViewInit() {
    this.setCalPos();
  }

  ngOnDestroy() {
    this.refreshTimeRange.unsubscribe();
  }

}
