import { Noipfraud } from './noipfraud';
import { OfferPage, LandingPage, NewOfferPage, NewLandingPage } from './pages';

export interface Campaign {
    id: string;
    name: string;
    route: string;
    cpc: number;
    noipfraud: Noipfraud;
    nodeID: string;
    landing_pages: LandingPage[];
    offers: OfferPage[];
    flow?: CampaignFlow;
    user_tags: string[];
    postbacks?: Postback[];
}

export interface CampaignFlow {
    objects: any[];
    links: CampaignFlowLinkItem[];
}

export interface CampaignFlowLinkItem {
    source: string;
    target: string;
}

export interface Postback {
  url: string;
  method: string;
}

export function NewPostback(): Postback {
  return <Postback>{
    url: '',
    method: 'POST'
  };
}

export function NewNoipfraud(): Noipfraud {
  return {
    enabled: false,
    b64_encoding_enabled: false,
    block_all: true,
    ad_block_all: false,
    ad_block_all_clicks: 75,
    traffic_source_id: '54218f34454c61f813000001',
    landing_pages: [NewLandingPage()],
    offers: [NewOfferPage()],
    exp_enabled: false,
    conditions: [],
    ip_whitelist: []
  };
}

export function NewV1Campaign(): Campaign {
    return <Campaign>{
        id: '',
        name: '',
        route: '',
        cpc: 0.0,
        nodeID: '',
        noipfraud: NewNoipfraud(),
        user_tags: [],
        landing_pages: [NewLandingPage()],
        offers: [NewOfferPage()],
      };
}

export function NewV2Campaign(): Campaign {
    return <Campaign>{
        id: '',
        name: '',
        route: '',
        cpc: 0.0,
        nodeID: '',
        noipfraud: NewNoipfraud(),
        user_tags: [],
        landing_pages: [NewLandingPage()],
        offers: [NewOfferPage()],
        flow: <CampaignFlow>{},
        postbacks: []
      };
}
