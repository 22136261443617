import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Noipfraud, GetTrafficSourceName, TrafficSources, GetRuleName, GetRuleType, GetRuleValues, RuleTypes } from '../../../noipfraud';

import { ApiService } from '../../../api.service';

import { countries } from '../countries';
import { browsers } from '../browsers';

import { Observable } from 'rxjs';

import { NgbTypeaheadSelectItemEvent, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-noipfraud-options',
  templateUrl: './noipfraud-options.component.html',
  styleUrls: ['./noipfraud-options.component.css']
})
export class NoipfraudOptionsComponent {
  settings: Noipfraud;

  getTrafficSourceName = GetTrafficSourceName;
  trafficSources = TrafficSources;
  getRuleName = GetRuleName;
  getRuleType = GetRuleType;
  getRuleValues = GetRuleValues;
  ruleTypes = RuleTypes;
  countries = countries;
  countryInput: any;
  browsers = browsers;
  browserInput: any;

  @Input() nodeVersion: string;

  // used for toggling advanced rule settings
  r_adv: boolean[] = [];
  r_adv_prev_state: any[] = [];

  itemInput = {};

  ipwl = '';

  stringMatchOptions = [
    { id: 'isempty', name: 'is empty', showValueField: false },
    { id: 'isequal', name: 'is equal to', showValueField: true },
    { id: 'isnotequal', name: 'is not equal to', showValueField: true },
    { id: 'contains', name: 'contains', showValueField: true}
  ];
  
  urlfilterInput = {
    param: '',
    value: ''
  };

  urlfilterOption = this.stringMatchOptions[0];

  stringMatchFilterOptions = {};
  stringMatchInput = {};

  highlightCondition = '';

  constructor(
    private apiService: ApiService,
    public activeModal: NgbActiveModal
  ) {}

  getIP() {
    this.apiService.getIPaddress().subscribe(
      ip => this.ipwl = ip.data,
    );
  }

  addIPWL() {
    const ip = this.ipwl.trim();
    this.ipwl = '';
    if (ip === '' || this.settings.ip_whitelist.indexOf(ip) > -1) {
      return;
    }
    this.settings.ip_whitelist.push(ip);
  }

  addCondition(id: string, type: string) {
    let fieldValueParams = {param: '', value: ''}
    
    // if http headers exists and user is trying to add browser language, update the input for the http header filter 
    if (id === 'browserlanguage') {
      id = 'httpheaders';
      fieldValueParams = {param: 'Accept-Language', value: ''}
      for (const c of this.settings.conditions) {
        if (c.rule === 'httpheaders') {
          this.stringMatchInput['httpheaders'] = fieldValueParams;
        }
      }
    }
    for (const c of this.settings.conditions) {
      if (c.rule === id ) {
        this.highlightCondition = id;
        setTimeout(() => {
          this.highlightCondition = '';
          setTimeout(() => {
            this.highlightCondition = id;
            setTimeout(() => {
              this.highlightCondition = '';
            }, 100);
          }, 100);
        }, 100);
        return;
      }
    }

    let value: any;
    switch (type) {
      case 'frequencycap':
        value = {
          visits: 1,
          hours: 1
        };
        break;

      case 'list':
        value = '';
        break;
      case 'urlfilter':
      case 'fieldvalue':
        this.stringMatchFilterOptions[id] = this.stringMatchOptions[0];
        this.stringMatchInput[id] = fieldValueParams;
      case 'country':
      case 'multi':
      case 'array':
        value = [];
    }
    this.settings.conditions.push({
      rule: id,
      allow_only: false,
      value: value,
      block_next: {
        enabled: false,
        clicks: 0
      }
    });
  }

  addItem(cond: any, key: string) {
    if (!cond || !cond.value) {
      cond.value = [];
    }
    if (this.itemInput[key] === '') {
      return;
    }
    cond.value.push(this.itemInput[key].toLowerCase());
    this.itemInput[key] = '';
  }

  toggleAdvancedSettings(enable: boolean, ruleUIIndex: number) {
    if (!enable) {
      // store prev state
      this.r_adv_prev_state[ruleUIIndex] = {
        toggled: !!this.r_adv[ruleUIIndex],
        block_next_enabled: this.settings.conditions[ruleUIIndex].block_next.enabled,
      };

      this.settings.conditions[ruleUIIndex].block_next.enabled = false;
      this.r_adv[ruleUIIndex] = false;
    } else {
      if (!this.r_adv_prev_state[ruleUIIndex]) {
        return;
      }
      // restore prev state, if exists
      this.r_adv[ruleUIIndex] = this.r_adv_prev_state[ruleUIIndex].toggled;
      this.settings.conditions[ruleUIIndex].block_next.enabled = this.r_adv_prev_state[ruleUIIndex].block_next_enabled;
    }
  }

  toggleMulti(id: string, cond: any) {
    if (!cond || !cond.value) {
      cond.value = [];
    }
    const p = cond.value.indexOf(id);
    if (p > -1) {
      cond.value.splice(p, 1);
    } else {
      cond.value.push(id);
    }
  }

  addUrlFilter(cond: any) {
    if (!cond || !cond.value) {
      cond.value = [];
    }
    if (this.urlfilterInput.param === '') {
      return;
    }
    cond.value.push({
      param: this.urlfilterInput.param,
      type: this.urlfilterOption.id,
      value: this.urlfilterOption.showValueField ? this.urlfilterInput.value.trim() : ''
    });
    this.urlfilterInput.value = '';
  }

  getStringMatchInput(rule: string) {
    if (!this.stringMatchInput[rule]) {
      this.stringMatchInput[rule] = {param: '', value: ''};
    }
    return this.stringMatchInput[rule];
  }

  getStringMatchFilterOptions(rule: string) {
    if (!this.stringMatchFilterOptions[rule]) {
      this.stringMatchFilterOptions[rule] = this.stringMatchOptions[0];
    }
    return this.stringMatchFilterOptions[rule];
  }

  addFieldValueFilter(cond: any) {
    if (!cond || !cond.value) {
      cond.value = [];
    }
    if (this.stringMatchInput[cond.rule].param === '') {
      return;
    }
    cond.value.push({
      param: this.stringMatchInput[cond.rule].param,
      type: this.stringMatchFilterOptions[cond.rule].id,
      value: this.stringMatchFilterOptions[cond.rule].showValueField ? this.stringMatchInput[cond.rule].value.trim() : ''
    });
    this.stringMatchInput[cond.rule].value = '';
  }

  getStringMatchTypeName(type: string): string {
    for (const v of this.stringMatchOptions) {
      if (v.id === type) {
        return v.name;
      }
    }
    return '';
  }

  // Country search fuctions
  countrySearch = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .map(function(term) {
        if (term === '') { return [] };
        // filter by all countries or country codes containing letters in the term
        let c = countries.filter(v =>
          v.search.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.val.toLowerCase().indexOf(term.toLowerCase()) > -1 )

        // if term matches a country code, place first
        for (let i = 0; i < c.length; i++) {
          if (c[i].val === term.toUpperCase()) {
            c.unshift(c.splice(i, 1)[0]);
          }
        }
        return c.slice(0, 10);
      }
  )

  countryFormatter = (x: { search: string; val: string; }) => `(${x.val}) ${x.search}`;

  countryRuleSelected(rule: NgbTypeaheadSelectItemEvent, cond: any) {
    rule.preventDefault();
    this.countryInput = null;

    const val = rule.item.val;

    // init if not array (first)
    if (!cond || !cond.value) {
      cond.value = [];
    }

    // check duplicate
    if (cond.value.indexOf(val) > -1) {
      return;
    }

    cond.value.push(val);
  }

  // Browser search fuctions - similar to country
  browserSearch = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .map(function(term) {
        if (term === '') { return [] };
        // filter by all browsers or browser names containing letters in the term
        let c = browsers.filter(v =>
          v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 )

        // if term matches a browser name, place first
        for (let i = 0; i < c.length; i++) {
          if (c[i].name === term.toUpperCase()) {
            c.unshift(c.splice(i, 1)[0]);
          }
        }
        return c.slice(0, 10);
      }
  )

  browserFormatter = (x: { name: string; id: string; }) => `${x.name}`;

  browserRuleSelected(rule: NgbTypeaheadSelectItemEvent, cond: any) {
    rule.preventDefault();
    this.browserInput = null;

    const id = rule.item.id;

    // init if not array (first)
    if (!cond || !cond.value) {
      cond.value = [];
    }

    // check duplicate
    if (cond.value.indexOf(id) > -1) {
      return;
    }

    cond.value.push(id);
  }
}
