import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { Campaign, NewV1Campaign, NewV2Campaign, NewNoipfraud } from '../campaigns';
import { CampaignDetailService } from '../campaign-detail/campaign-detail.service';

import { ApiService, NodeListResponse } from '../api.service';
import { Subject } from 'rxjs/Subject';
import { ProgressBarService } from '../progress-bar.service';
import { UserSettingsService } from '../user-settings.service';
import { CampaignEditService } from '../campaign-edit-service';
import { ConversionSettingsModalComponent } from '../conversion-settings-modal.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchPopoverListItem } from '../search-popover/search-popover.component';
import { of, Observable } from 'rxjs';
import { ImportDialogComponent } from './campaign-import-dialog.component';
import { NewOfferPage, NewLandingPage } from '../pages';
import { NodeDetailService } from '../campaign-detail/node-detail.service';


@Component({
  selector: 'app-campaign-new',
  templateUrl: './campaign-new.component.html',
  styleUrls: ['./campaign-new.component.css']
})
export class CampaignNewComponent implements OnInit {

  savedEvent$ = new Subject<void>();
  campImported: boolean;
  teamID: string;
  loading = true;
  importReady = false;
  campaign: Campaign;
  node: NodeListResponse;
  showShadow: boolean;
  campValid: boolean;
  searchItems: SearchPopoverListItem[] = [];
  nodesAndCampaigns: NodeListResponse[] = [];
  campModified: boolean;
  existingRoutes: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private campaignDetailService: CampaignDetailService,
    private campaignEditService: CampaignEditService,
    private apiService: ApiService,
    private progressBarService: ProgressBarService,
    private userSettingsService: UserSettingsService,
    private modalService: NgbModal,
    private nodeService: NodeDetailService,

    @Inject(DOCUMENT) private document: Document,
  ) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollAmount = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (scrollAmount > 10) {
      this.showShadow = true;
    } else {
      this.showShadow = false;
    }
  }

  importSelected(selection: SearchPopoverListItem) {
    const modalRef = this.modalService.open(ImportDialogComponent);
    modalRef.componentInstance.campaignName = selection.display;
    modalRef.result.catch((options: any)  => {
      // no options sent, do not import
      if (!options) {
        return;
      }
      const s = selection.value.split('-');
      if (s.length < 2) {
        // something has gone wrong
        return;
      }
      const nodeID = s[0];
      const campID = s[1];

      for (const r of this.nodesAndCampaigns) {
        if (!r.campaigns) {
          continue;
        }
        if (r.id === nodeID) {
          for (const c of r.campaigns) {
            if (c.id === campID) {
              this.campImported = true;
              // make a copy of the source campaign
              const ic = JSON.parse(JSON.stringify(c));

              // apply changes to copy
              ic.name = ic.name + ' (copy)';
              ic.nodeID = this.node.id;
              
              // remove any unwanted campaign settings
              if (!options.route) {
                ic.route = '';
              }
              if (!options.landing_pages) {
                ic.landing_pages = [NewLandingPage()];
              }
              if (!options.offers) {
                ic.offers = [NewOfferPage()];
              }
              if (!options.noipfraud) {
                ic.noipfraud = NewNoipfraud();
              }

              // check if landing pages are empty
              if (ic.landing_pages.length === 0) {
                ic.landing_pages.push(NewLandingPage());
              }

              // check if offer pages are empty
              if (ic.offers.length === 0) {
                ic.offers.push(NewOfferPage());
              }

              this.campaign = ic;
              return;
            }
          }
        }
      }
    });
  }

  // for campaigns on node version < 2.0.0
  campaignSaved(camp: Campaign) {
    this.progressBarService.start();
    this.apiService.newCampaign(this.teamID, camp)
    .finally(() => {
      this.savedEvent$.next();
      this.progressBarService.done();
      this.router.navigate(['/overview']);
    })
    .subscribe();
  }

  openConversionSettingsModal() {
    this.userSettingsService.cache.campSelected = this.campaign;
    this.userSettingsService.cache.nodeSelected = this.node;
    const modalRef = this.modalService.open(ConversionSettingsModalComponent);
    modalRef.result.then(res => {
      if (res !== null) {
        this.campaign.postbacks = res;
      }
    }, err => {});
  }

  // for >= 2.0.0 campaigns
  save() {
    // run corrections
    // route must start with /
    if (this.campaign.route.charAt(0) !== '/') {
      this.campaign.route = '/' + this.campaign.route;
    }

    // trim strings
    this.campaign.name = this.campaign.name.trim();
    this.campaign.route = this.campaign.route.trim();
    for (let i = 0; i < this.campaign.landing_pages.length; i++) {
      this.campaign.landing_pages[i].name = this.campaign.landing_pages[i].name.trim();
      this.campaign.landing_pages[i].url = this.campaign.landing_pages[i].url.trim();
    }

    for (let i = 0; i < this.campaign.offers.length; i++) {
      this.campaign.offers[i].name = this.campaign.offers[i].name.trim();
      this.campaign.offers[i].url = this.campaign.offers[i].url.trim();
    }

    //remove postbacks with empty URLs
    if ( !this.campaign.postbacks ) {
      this.campaign.postbacks = []
    }
    this.campaign.postbacks = this.campaign.postbacks.filter(postback => postback.url !== '');

    this.campaignDetailService.clearCache();
    this.progressBarService.start();

    this.apiService.newCampaign(this.teamID, this.campaign)
    .finally(() => {
      this.progressBarService.done();
    }).subscribe(resp => {
      // if the node returns the node campaign id, then navigate to the settings view
      if (resp.data) {
        this.router.navigate(['/team', this.teamID, 'node', this.node.id, 'campaign', resp.data, 'setup']);
      } else {
        this.router.navigate(['/overview']);
      }
    });
  }

  getNodesAndCampaigns(): Observable<NodeListResponse[]> {
    // do we have all campaigns?
    const nodes = this.userSettingsService.cache['overview'].nodes;
    const nodes$ = new Subject<NodeListResponse[]>();
    if (nodes) {
      return of(nodes);
    }
    if (!nodes) {
      this.apiService.getOverview().subscribe(resp => {
        nodes$.next(resp.data);
      });
    }
    return nodes$.asObservable();
  }

  ngOnInit() {
    this.teamID = this.route.snapshot.paramMap.get('teamid');
    const nodeID = this.route.snapshot.paramMap.get('nodeid');
    this.node = this.userSettingsService.cache['nodeSelected'];
    if (!this.node.id) {
      this.apiService.getNode(this.teamID, nodeID).subscribe(
        n => {
          this.node = <NodeListResponse>n.data;
          this.campaign = this.node.version.substring(0, 1) >= '2' ? NewV2Campaign() : NewV1Campaign();
          this.campaign.nodeID = nodeID;
          this.loading = false;
        }
      );
    } else {
      this.campaign = this.node.version.substring(0, 1) >= '2' ? NewV2Campaign() : NewV1Campaign();
      this.campaign.nodeID = nodeID;
      this.loading = false;
    }

    // listen to campaign edit events
    // for campaigns on version >= 2.0.0
    this.campaignEditService.campaignSetupValid.subscribe(valid => {
      this.campModified = true;
      this.campImported = false;
      this.campValid = valid;
    });

    this.nodeService.getNodeRoutes().subscribe(nodeRoutes => {
      this.existingRoutes = nodeRoutes;
    })

    this.getNodesAndCampaigns().subscribe(result => {
      this.nodesAndCampaigns = result;

      // build searchItems for import list
      for (const r of result) {
        if (!r.campaigns) {
          continue;
        }

        // only show campaigns from versions >= 2.0.0
        if (r.version[0] < '2') {
          continue;
        }

        this.searchItems.push({
          header: true,
          display: 'Node: ' + r.name,
          value: r.id
        });

        for (const c of r.campaigns) {
          this.searchItems.push({
            header: false,
            display: c.name,
            value: r.id + '-' + c.id,
          });
        }
      }
      this.importReady = true;
    });

  }

}
