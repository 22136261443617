import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs/Subject';

import { ApiService } from '../api.service';
import { Campaign } from '../campaigns';
import { NodeListResponse } from '../api.service';
import { UserSettingsService } from '../user-settings.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class CampaignDetailService {
  teamID: string;
  nodeID: string;
  campID: string;

  private campaignCache: Campaign;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private userSettingsService: UserSettingsService
  ) {}

  private setIDS() {
    this.teamID = this.route.children[0].snapshot.paramMap.get('teamid');
    this.nodeID = this.route.children[0].snapshot.paramMap.get('nodeid');
    this.campID = this.route.children[0].snapshot.paramMap.get('campid');
  }

  setCampaign(camp: Campaign) {
    this.campaignCache = camp;
  }

  getNode(): Observable<NodeListResponse> {
    this.setIDS();

    const node = this.userSettingsService.cache['nodeSelected'];
    const node$ = new Subject<NodeListResponse>();
    if (node.id) {
      return of(node);
    } else {
      this.apiService.getNode(this.teamID, this.nodeID).subscribe(
        n => {
          this.userSettingsService.cache['nodeSelected'] = n.data;
          node$.next(<NodeListResponse>n.data);
          node$.complete();
        },
        error => node$.error(error));
    }

    return node$.asObservable();
  }

  getCampaign(): Observable<Campaign> {
    this.setIDS();

    if (this.campaignCache && this.campID === this.campaignCache.id) {
      return of(this.campaignCache);
    }

    const campaign$ = new Subject<Campaign>();
    this.apiService.getCampaign(this.teamID, this.nodeID, this.campID).subscribe(
        camp => {
          this.campaignCache = camp.data;
          campaign$.next(<Campaign>camp.data);
          campaign$.complete();
        },
        error => campaign$.error(error)
    );

    return campaign$.asObservable();
  }

  clearCache() {
    this.campaignCache = null;
  }
}
