import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { UserSettingsService } from '../user-settings.service';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NodeDetailService {

  nodeID: string;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private userSettingsService: UserSettingsService
  ) {}

  getNodeRoutes(): Observable<string[]> {
    this.nodeID = this.route.children[0].snapshot.paramMap.get('nodeid');
    const nodeRoutes = this.userSettingsService.cache['nodeRoutes'][this.nodeID];
    const routes$ = new Subject<string[]>();
    if (nodeRoutes) {
      return of(nodeRoutes);
    } else { 
      this.apiService.getCampaigns().subscribe(
        n => {
          for(const node of n.data) {
            if (node.id === this.nodeID) {
              // get all campaign routes
              const r = node.campaigns.map(function(v) {
                return v.route;
              });
              this.userSettingsService.cache['nodeRoutes'][this.nodeID] = r;
              routes$.next(r);
              routes$.complete();
            }
          }
        },
        error => routes$.error(error));
    }
    return routes$.asObservable();
  }
}
