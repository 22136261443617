import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ProgressBarService {
  start$ = new Subject<void>();
  done$ = new Subject<void>();

  start() {
      this.start$.next();
  }
  done() {
      this.done$.next();
  }
}
