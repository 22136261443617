import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Campaign } from '../../campaigns';
import { UserSettingsService } from '../../user-settings.service';
import { CampaignDetailService } from '../campaign-detail.service';
import { ApiService, NodeListResponse } from '../../api.service';
import { ColumnConfig, ColTransform } from '../../treething/treething.component';

import { DateStruct } from '../../date-range.component';

import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { of, forkJoin } from 'rxjs';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { SearchPopoverListItem } from '../../search-popover/search-popover.component';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
import { takeWhile } from 'rxjs/operators';

import * as moment from 'moment-timezone';

@Component({
  selector: 'app-campaign-stats',
  templateUrl: './campaign-stats.component.html',
  styleUrls: ['./campaign-stats.component.css'],
  providers: [DatePipe]
})
export class CampaignStatsComponent implements OnInit, OnDestroy {

  private delayRefreshStats: Subscription;

  sort = {
    asc: true,
    col: ''
  };
  alive: boolean = true;
  t_groups: string[] = [];
  groups: string[] = [];
  selectedGroups: string[] = [];
  displayGroups: string[] = [];
  campaign: Campaign;
  loading: boolean;
  stats: any = {};
  transforms: ColTransform[] = [];
  page = 0;
  appending = false;
  resultLength = 0;
  reloading: boolean;
  filter: string = 'all';
  node: NodeListResponse;
  exports: string[] = [];
  exportInProgress: boolean;

  refreshTimeRange$ = new Subject<void>();

  readonly rawcols: ColumnConfig[] = [
    { id: 'gp_eventtype', display: 'type', align: 'left', transform: true,
      rowclass: [
        { value: "👉", class: 'table-primary' },
        { value: "🤑", class: 'table-success' },
      ] },
    { id: 'time', display: 'time', align: 'left', transform: true },
    { id: 'fd', display: 'fd on', align: 'left' },
    { id: 'blocked', display: 'block', align: 'left', transform: true,
      rowclass: [
        { value: 1, class: 'table-danger' }
      ]
    },
    { id: 'gp_blockreason', display: 'reason', align: 'left', transform: true },
    { id: 'conv', display: '' },// not displayed
    { id: 'lp clicks', display: '' }, // not displayed
    { id: 'ip', display: 'ip', align: 'left' },
    { id: 'host', display: 'host', align: 'left' },
    { id: 'connection', display: 'connection', align: 'left' },
    { id: 'gp_isp', display: 'isp', align: 'left' },
    { id: 'organization', display: 'organization', align: 'left' },
    { id: 'gp_country', display: 'country', align: 'left' },
    { id: 'city', display: 'city', align: 'left' },
    { id: 'state', display: 'state', align: 'left' },
    { id: 'gp_browser', display: 'browser', align: 'left' },
    { id: 'gp_mobile', display: 'mobile', align: 'left' },
    { id: 'carrier', display: 'carrier', align: 'left' },
    { id: 'gp_os', display: 'os', align: 'left' },
    { id: 'gp_landingpage', display: 'landingpage', align: 'left' },
    { id: 'referrer', display: 'referer', align: 'left' },
    { id: 'useragent', display: 'useragent', align: 'left' }
  ];

  readonly groupcols: ColumnConfig[] = [
    { id: 3, display: 'cost' },
    { id: 4, display: 'revenue' },
    { id: 7, display: 'P/L' },
    { id: 11, display: 'ROI', postfix: '%' },
    { id: 0, display: 'total' },
    { id: 6, display: 'allowed' },
    { id: 5, display: 'blocked' },
    { id: 10, display: 'blocked %', postfix: '%' },
    { id: 1, display: 'lp clicks' },
    { id: 8, display: 'CTR', postfix: '%' },
    { id: 2, display: 'conv.' },
    { id: 9, display: 'CVR', postfix: '%' }
  ];

  groupItems: SearchPopoverListItem[] = [
    { header: true, value: 'internal', display: 'Built-In' },
    { header: false, value: 'gp_browser', display: 'browser' },
    // header: false,  { value: 'gp_carrier', display: 'carrier' },
    { header: false, value: 'gp_isp', display: 'isp' },
    { header: false, value: 'gp_country', display: 'country' },
    { header: false, value: 'gp_landingpage', display: 'landing page' },
    { header: false, value: 'gp_offer', display: 'offer' },
    { header: false, value: 'gp_mobile', display: 'mobile' },
    { header: false, value: 'gp_os', display: 'os' },
    { header: false, value: 'gp_blockreason', display: 'block reason' },
    // { value: 'gp_type', display: 'type' }
  ];


  constructor(
    private apiService: ApiService,
    public userSettingsService: UserSettingsService,
    private campaignDetailService: CampaignDetailService,
    private datePipe: DatePipe
  ) { }

  private refreshStatsDelay(delay: number, append: boolean = false) {
    if (this.delayRefreshStats) {
      this.delayRefreshStats.unsubscribe();
    }

    // reset sort col
    // grouped -> non grouped
    if (this.t_groups.length > 0 && this.groups.length === 0) {
      this.sort.col = '';
      this.sort.asc = true;
      this.page = 0;
    }
    // non grouped -> grouped
    if (this.t_groups.length === 0 && this.groups.length > 0) {
      this.sort.col = '7';
      this.sort.asc = false;
      this.page = 0;
    }

    // set filter param to 1 for conversions and lp clicks (leads)
    let filter = {};
    if (this.filter != 'all') {
      filter[this.filter] = 1;
    }

    // reset page if not appending
    if (!append) {
      this.page = 0;
    }

    this.reloading = true;
    // request with delay
    this.delayRefreshStats = of({})
    .delay(delay)
    .subscribe(
      () => {
        this.apiService.getStats(
          this.campaignDetailService.teamID,
          this.campaign.nodeID,
          this.campaign.id,
          this.groups,
          this.sort.asc ? this.sort.col : `-${this.sort.col}`,
          this.userSettingsService.fromNano, this.userSettingsService.toNano,
          this.page, 
          filter
        )
        .finally(() => { this.appending = false; this.reloading = false; } )
        .subscribe(
          result => {
            // perform a copy of the object (so no immediate update because of reference)
            this.t_groups = [ ...this.groups ];

            // store result length for check in template
            this.resultLength = result.data.length || 0;

            // set stats
            if (append) {
              this.stats = this.stats.concat(result.data);
            } else {
              this.stats = result.data;
            }
          }
        );
      }
    );
  }

  updateFilter(filter: string) {
    this.filter = filter;
    this.clearGroupTags();
    this.refreshStatsDelay(500);
  }

  refreshStats() {
    this.refreshTimeRange$.next();
  }

  dtChanged(dt: DateStruct) {
    this.userSettingsService.selectedDates = dt;
    this.refreshStatsDelay(1);
  }

  showMore() {
    this.page++;
    this.appending = true;
    this.refreshStatsDelay(1, true);
  }

  groupDropdownOpenChange(open: boolean) {
    // cancel stats update on open
    if (open) {
      if (this.delayRefreshStats) {
        this.delayRefreshStats.unsubscribe();
      }
    } else {
      this.refreshStatsDelay(1000);
    }
  }

  getActiveFilter(): string {
    switch (this.filter) {
      case 'all':
        return 'All';
      case 'leads': 
        return 'LP Clicks';
      case 'conv': 
        return 'Conversions';
      default:
        return 'All';
    }
  }

  addGroupTag(ev: SearchPopoverListItem) {
    let swapped = false;
    if (ev.value !== 'gp_offer') {
      const i = this.groups.indexOf('gp_offer');
      if (i >= 0) {
        this.displayGroups[i] = ev.display;
        this.groups[i] = ev.value;
        this.selectedGroups[i] = ev.value;

        // needed for angular to recognise an array change
        this.selectedGroups = this.selectedGroups.slice();
        swapped = true;
      }
    }

    if (!swapped) {
      this.displayGroups.push(ev.display);
      this.groups.push(ev.value);
      this.selectedGroups.push(ev.value);

      // needed for angular to recognise an array change
      this.selectedGroups = this.selectedGroups.slice();
    }
    this.refreshStatsDelay(1000);
  }

  remGroupTag(idx: number) {
    this.displayGroups.splice(idx, 1);
    this.groups.splice(idx, 1);
    this.selectedGroups.splice(idx, 1);

    // needed for angular to recognise an array change
    this.selectedGroups = this.selectedGroups.slice();
    this.refreshStatsDelay(1000);
  }

  clearGroupTags() {
    this.displayGroups = [];
    this.groups = [];
    this.selectedGroups = [];

    // needed for angular to recognise an array change
    this.selectedGroups = this.selectedGroups.slice();
  }

  setSortOrder(id: string) {
    if (id === this.sort.col) {
      this.sort.asc = !this.sort.asc;
    }
    this.sort.col = id;
    this.refreshStatsDelay(1);
  }

  deleteExport(index: number) {
    let removed = this.exports.splice(index, 1);
    this.apiService.deleteExport(this.campaignDetailService.teamID, this.campaign.nodeID, removed[0]).subscribe(() => {});
  }

  exportIsPending(e: string) {
    return e.endsWith(".pending");
  }

  exportsPending() {
    for (let i = 0; i < this.exports.length; i++) {
      if (this.exportIsPending(this.exports[i])) {
        return true;
      }
    }
    return false;
  }

  pollExports() {
    TimerObservable.create(5000, 5000).pipe(
      takeWhile(() => {
        return this.alive && this.exportsPending()
      })
    )
    .subscribe(() => {
      this.getExports();
    });
  }

  export() {
    this.exportInProgress = true;
    this.apiService.exportCampaignStats(this.campaignDetailService.teamID, this.campaign, this.userSettingsService.fromNano, this.userSettingsService.toNano)
    .finally(() => {
      this.exportInProgress = false;
    })
    .subscribe(res => {
      this.exports.push(res.data);
      this.pollExports();
    });
  }

  getExports() {
    this.apiService.getNodeStatExports(this.campaignDetailService.teamID, this.campaign.nodeID).subscribe(res => {
      this.exports = res.data;
    });
  }

  createExportURL() {
    // generate export address
    if (this.node.id.includes(":")) {
      return`http://[${this.node.id}]`;
    }
    return `http://${this.node.id}`;
  }

  //function that sets the event type
  getEventType(row: any, nodeVersion:string):string {
    // For new nodes return the gp_eventtype set by node
    if (nodeVersion >= '3.2.0' && row.gp_eventtype && row.gp_eventtype !== '') {
      return row.gp_eventtype
    }

    //Determine event type based on click, leads, and conv fields
    //Note that click = visit and lead = clickthrough from lp or offer
    if (row.clicks==1) {
      if (row.leads==0) {
        return "view_lp";
      } else {
        return "view_of";
      }
    } else {
      if (row.leads==1) {
        return "click";
      } else if (row.conv==1) {
        return "conv";
      }
    }
    console.log("Unable to set event type:", nodeVersion, row)
    return ""
  }

  // get an emoji based on event type
  getEventEmoji(eventType: string): string {
    switch (eventType) {
      case 'view_lp': 
        return "📄";
      case "view_of":
        return "🎯";
      case "click":
        return "👉";
      case "conv":
        return "🤑"
      default:
        // this prevents converted eventType from being converted as it always runs twice
        return eventType;
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    this.loading = true;

    forkJoin(
      this.campaignDetailService.getCampaign(),
      this.campaignDetailService.getNode(),
    )
    .subscribe(
      result => {
        let camp = result[0];
        this.campaign = camp;
        this.node = result[1];

        // change event type to emoji using new gp_eventype field
        this.transforms.push({
          col: 'gp_eventtype',
          val: '*',
          replace: (row: any) => {
            var eventType = this.getEventType(row, this.node.version);
            return this.getEventEmoji(eventType);
          }
        });

        // treething transfer timestamp to human readable & adds event emoji
        this.transforms.push({
          col: 'time',
          val: '*',
          replace: (row: any) => {
            return moment.tz(row.time, this.userSettingsService.timezone.name).format('DD MMM YY, HH:mm:ss');
          }
        });

        //remove block reason for conv / click through - fix for legacy data on old nodes < 3.2.0
        //visit to lp & visit to offer - show block reason
        //clickthrough & conversion - dont show
        if ( this.node.version < '3.2.0' ) {
          this.transforms.push({
            col: 'gp_blockreason',
            val: '*',
            replace: (row: any) => {
              if ( row.gp_blockreason!=='' && row.clicks==0 ) {
                return '(visit was blocked)'
              }
              return row.gp_blockreason
            }
          })
  
          this.transforms.push({
            col: 'blocked',
            val: '*',
            replace: (row: any) => {
              return row.blocked && row.clicks==0 ? 0 : row.blocked
            }
          })  
        }

        // perform a copy of the object (so no immediate update because of reference)
        this.t_groups = [...this.groups];

        // get tags & raw click log
        forkJoin(
          this.apiService.getStatsFields(this.campaignDetailService.teamID, camp.nodeID, camp.id),
          this.apiService.getStats(
            this.campaignDetailService.teamID,
            this.campaign.nodeID,
            this.campaign.id,
            this.groups,
            '',
            this.userSettingsService.fromNano,
            this.userSettingsService.toNano, 
            0, 
            {} // empty filter
          )
        )
        .finally( () => {
          this.loading = false; 
          // load exports after initial load
          this.getExports();
          if (this.exportsPending) {
            this.pollExports();
          }
        })
        .subscribe(
          result => {
            let dbfields: string[];
            [ dbfields, this.stats ] = [ <string[]>result[0].data, result[1].data ];

            // store result length for check in template
            this.resultLength = this.stats.length || 0;

            let addedCustom = false;
            // parse dbfields
            for (let i = 0; i < dbfields.length; i++) {
              if (dbfields[i].startsWith('cu_')) {
                if (!addedCustom) {
                  this.groupItems.push({ header: true, value: 'custom', display: 'Tracked Parameters' });
                  addedCustom = true;
                }
                const p = dbfields[i].substr(3);
                this.groupItems.push({
                  header: false,
                  value: dbfields[i],
                  display: 'param: ' + (p.length > 40 ? p.substr(0, 40) + '...' : p)
                });
              }
            }
          },
          error => {}
        );
      },
      error => {}
    );
  }

}
