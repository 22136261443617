import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';

import { environment } from '../../environments/environment';

const NODE_INSTALL_URL = environment.nodeInstallUrl;

@Component({
  selector: 'app-add-node-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title"><span class="oi oi-terminal mr-2"></span>New node</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>Minimum recommended requirements</label>
      <p class="form-text">
        2vCPU | 60GB SSD | 2GB RAM | systemd *
      </p>
      <p class="form-text text-muted">
        Provides 500,000 clicks/day @ 28mil clicks total storage. Increase SSD size for more storage and CPU/RAM for more volume.
      </p>
      <label>Choose a name</label>
      <input #name (input)="updateCommand($event.target.value)"
        type="text" class="form-control"
        placeholder="My awesome node"
        [disabled]="loading || error">
      <small class="form-text text-muted">Choose a descriptive name for your node. For example,
        a domain name that you will use with this node.</small>
      <hr>
      <p>Spin up a new server and Copy and Paste the following command as the <code>root</code> user:</p>
      <div class="input-group">
        <input #command type="text" class="form-control" [value]="cmd" (focus)="$event.target.select()" readonly>
        <div class="input-group-append">
          <button [ngxClipboard]="command" (cbOnSuccess)="sc1=true"
           (mouseleave)="sc1=false"
           class="btn btn-outline-secondary"
           type="button"
           ngbTooltip="Copy to clipboard" container="body">
            <span class="oi oi-clipboard"></span>
          </button>
        </div>
      </div>
      <div *ngIf="sc1" class="float-left">
        <small class="form-text text-success">Copied!</small>
      </div>
      <div class="mb-4"></div>
      <hr>
      <small class="form-text text-muted">
        * Note for AWS: Amazon Linux 1 does not support systemd. Most modern distro's do, including Amazon Linux 2
      </small>
    </div>
  `
})
export class AddNodeModalComponent implements OnInit {
  @ViewChild('name') private elName: ElementRef;
  private _cmd: string;

  sc1: boolean;
  loading: boolean;
  error: boolean;
  cmd = 'loading...';

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public activeModal: NgbActiveModal
  ) { }

  updateCommand(name: string) {
    if (name.length === 0) {
      this.cmd = 'Enter node name...';
      return;
    }
    name = name.replace(/["'`]/g, '');
    this.cmd = this._cmd.replace('{{name}}', '"' + name + '"');
  }

  ngOnInit() {
    this.loading = true;
    this.apiService.getNewNodeCommand(this.authService.teams[0].id)
    .finally(() => {
      this.loading = false;
      setTimeout(() => this.elName.nativeElement.focus(), 200);
    })
    .subscribe(
      result => {
        this._cmd = <string>result.data;

        // set installation URL from environment config
        this._cmd = this._cmd.replace('{{installation_url}}', NODE_INSTALL_URL);
        this.updateCommand('');
      },
      error => {
        this.error = true;
        this.cmd = 'Error';
      }
    );
  }

}
