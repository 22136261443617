import { Component, OnInit, OnDestroy, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { Campaign } from '../campaigns';
import { CampaignDetailService } from './campaign-detail.service';

import 'rxjs/add/operator/finally';
import { ApiService, NodeListResponse } from '../api.service';
import { ProgressBarService } from '../progress-bar.service';
import { UserSettingsService } from '../user-settings.service';
import { CampaignEditService } from '../campaign-edit-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LinksModalComponent } from '../links-modal.component';
import { forkJoin } from 'rxjs';
import { ConversionSettingsModalComponent } from '../conversion-settings-modal.component';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-campaign-detail',
  templateUrl: './campaign-detail.component.html',
  styleUrls: ['./campaign-detail.component.css']
})
export class CampaignDetailComponent implements OnInit, OnDestroy {
  loading: boolean;
  campaign: Campaign;
  showShadow: boolean;
  campValid: boolean;
  node: NodeListResponse;
  saving: boolean;
  activeRoute: any;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private campaignDetailService: CampaignDetailService,
    private campaignEditService: CampaignEditService,
    private apiService: ApiService,
    private progressBarService: ProgressBarService,
    private userSettingsService: UserSettingsService,
    private modalService: NgbModal,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollAmount = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (scrollAmount > 10) {
      this.showShadow = true;
    } else {
      this.showShadow = false;
    }
  }

  openLinksModal() {
    this.userSettingsService.cache.campSelected = this.campaign;
    this.userSettingsService.cache.nodeSelected = this.node;
    const modalRef = this.modalService.open(LinksModalComponent);
    modalRef.result.catch(() => {
    });
  }

  openConversionSettingsModal() {
    this.userSettingsService.cache.campSelected = this.campaign;
    this.userSettingsService.cache.nodeSelected = this.node;
    const modalRef = this.modalService.open(ConversionSettingsModalComponent);
    modalRef.result.then(res => {
      if (res !== null) {
        this.campaign.postbacks = res;
        this.apiService.updateCampaignPostbacks(this.campaignDetailService.teamID,
          this.campaign.id,
          this.campaign.nodeID,
          res).subscribe();
      }
    }, err => {});
  }

  save() {

    // this does not apply to nodes on version < 2.0.0

    // run corrections
    // route must start with /
    if (this.campaign.route.charAt(0) !== '/') {
      this.campaign.route = '/' + this.campaign.route;
    }

    // trim strings
    this.campaign.name = this.campaign.name.trim();
    this.campaign.route = this.campaign.route.trim();
    for (let i = 0; i < this.campaign.landing_pages.length; i++) {
      this.campaign.landing_pages[i].name = this.campaign.landing_pages[i].name.trim();
      this.campaign.landing_pages[i].url = this.campaign.landing_pages[i].url.trim();
    }

    for (let i = 0; i < this.campaign.offers.length; i++) {
      this.campaign.offers[i].name = this.campaign.offers[i].name.trim();
      this.campaign.offers[i].url = this.campaign.offers[i].url.trim();
    }

    // remove empty postbacks
    if ( !this.campaign.postbacks ) {
      this.campaign.postbacks = []
    }
    this.campaign.postbacks = this.campaign.postbacks.filter(postback => postback.url !== '');

    this.saving = true;
    this.progressBarService.start();
    this.apiService.updateCampaign(this.campaignDetailService.teamID, this.campaign.id, this.campaign)
    .finally(() => {
      this.campaignDetailService.setCampaign(this.campaign);
      this.progressBarService.done();
      this.saving = false;
    })
    .subscribe(r => {},
      err => {
        this.alertService.add(err.message, 'danger');
      });
  }

  ngOnInit() {
    this.activeRoute = this.route.snapshot.firstChild.routeConfig;
    this.loading = true;

    // forkjoin is required to co-ordinate when view will be rendered.
    //  - both node and campaign data is required
    forkJoin(
      this.campaignDetailService.getNode(),
      this.campaignDetailService.getCampaign()
    ).subscribe(res => {
      this.node = res[0];
      this.campaign = res[1];
      this.loading = false;
    },
    error => {
      this.router.navigate(['/']);
    });

    this.campaignEditService.campaignSetupValid.subscribe(valid => {
      this.campValid = valid;
    });
  }

  ngOnDestroy() {
    this.campaignDetailService.clearCache();
  }

}
