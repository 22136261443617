import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';

import { initChargebee } from './chargebee';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(authService: AuthService) {
    authService.handleAuthentication();
    authService.scheduleRenewal();
    initChargebee();
  }
}
