import { Component, Output, EventEmitter, OnInit, Input, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';

import { OfferPage, NewOfferPage } from '../../../pages';
import { PageStateChange } from '../campaign-add-edit.component';

@Component({
  selector: 'app-offer-pages-v2',
  templateUrl: './offer-pages.component.html',
  styleUrls: ['./offer-pages.component.css']
})
export class V2OfferPagesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('pagesForm') pagesForm: NgForm;

  @Input() pages: OfferPage[];
  @Input() lpSplit: number;
  @Output() stateChange = new EventEmitter<PageStateChange>(true);

  prevValidState: string;
  _required: boolean;

  constructor() { }

  pagesTrackFn(index: number, obj: OfferPage) {
    return index;
  }

  newWeightedPage() {
    this.pages.push(NewOfferPage());
  }

  @Input() set isRequired(value: boolean) {
    this._required = value;
    this.onInput();
 }

  urlValid(pageIndex: number) {
    // multiple pages must be filled in completely
    const required = this._required || this.pages.length > 1;
    if (!required) {
      if (this.pages[pageIndex].name.length === 0 && this.pages[pageIndex].url.length === 0) {
        return true;
      }
    }
    return (this.pages[pageIndex].url.length > 0 && this.pages[pageIndex].url.match('^https?://.*'));
  }

  nameValid(pageIndex: number) {
     // multiple pages must be filled in completely
    const required = this._required || this.pages.length > 1;
    if (!required) {
      if (this.pages[pageIndex].url.length === 0 && this.pages[pageIndex].name.length === 0) {
        return true;
      }
    }
    return (this.pages[pageIndex].name.length > 0);
  }

  payoutValid(pageIndex: number) {
    return (this.pages[pageIndex].payout !== null && this.pages[pageIndex].payout >= 0);
  }

  pagesValid() {
    for (let i = 0; i < this.pages.length; i++) {
      if (!this.nameValid(i) || !this.payoutValid(i) || !this.urlValid(i)) {
        return false;
      }
    }
    return true;
  }

  pagesEmpty() {
    if (this.pages.length > 1) {
      return false;
    }
    const p = this.pages[0];
    return (p.name.length === 0 && p.url.length === 0);
  }

  onInput() {

    // empty check
    if (this.pagesEmpty() && !this._required) {
      if ('empty' !== this.prevValidState) {
        this.prevValidState = 'empty';
        this.stateChange.emit({state: this.prevValidState, action: 'modify'});
      } else {
        this.stateChange.emit({action: 'modify'});
      }
      return;
    }

    // valid check
    const valid = (this.pagesValid()) ? 'valid' : 'invalid';
    if (valid !== this.prevValidState) {
      this.prevValidState = valid;
      this.stateChange.emit({state: this.prevValidState, action: 'modify'});
    } else {
      this.stateChange.emit({action: 'modify'});
    }
  }

  ngOnInit() {
    // check form first
    this.onInput();
  }

  ngOnChanges() {
    // check form if pages are changed from outside of component
    this.onInput();
  }

  ngOnDestroy() {
  }

}
