import { Injectable } from '@angular/core';

export interface Alert {
  message: string;
  type: string;
}

@Injectable()
export class AlertService {

  private _alerts: Alert[] = <Alert[]>[];

  constructor() {
  }

  add(message, type: string) {
    this._alerts.push(<Alert>{
      message: message,
      type: type
    });
  }

  del(alert: Alert) {
    this._alerts.splice(this._alerts.indexOf(alert), 1);
  }

  alerts(): Alert[] {
    return this._alerts;
  }

}
