import { Component, ViewChild, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, AfterViewChecked } from '@angular/core';

import { TimerObservable } from 'rxjs/observable/TimerObservable';

import { Observable, Subject, forkJoin } from 'rxjs';
import { takeWhile, first } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';

import { ApiService, NodesClickStatsReponse, NodeListResponse } from '../api.service';
import { UserSettingsService } from '../user-settings.service';
import { AddNodeModalComponent } from './add-node-modal.component';
import { LinksModalComponent } from '../links-modal.component';
import { ConfirmDialogComponent } from '../confirm-dialog.component';
import { Campaign, CampaignFlow } from '../campaigns';
import { Noipfraud } from '../noipfraud';
import { NewLandingPage } from '../pages';
import { MigrateDialogComponent } from '../migrate-dialog/migrate-dialog.component';
import { SubscribeDialogComponent } from '../subscribe-dialog/subscribe-dialog.component';

import { AuthService } from '../auth/auth.service';

import { DateStruct } from '../date-range.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { CountStats } from '../stats';

import { openPortal } from '../chargebee';

import { environment } from '../../environments/environment';

interface GroupData {
  node: NodeListResponse;
  campaign: Campaign;
  counts: CountStats;
}

interface GroupedItem {
  type: string;
  id: string;
  metadata: {};
  data: GroupData[];
  totals: {};
}

interface SearchItem {
  type: string;
  subject: string;
  name?: string;
}

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('searchInstance') searchInstance: NgbTypeahead;
  @ViewChildren('nodeNameInput') nodeNameInputs: QueryList<ElementRef>;
  private focusedIndex: number | null = null;

  alive: boolean;
  devstack: boolean;
  allNodes: NodeListResponse[];
  nodes: NodeListResponse[];
  campCounts: NodesClickStatsReponse = {};
  groupedItems: {} = {
    node: [],
    tag: [],
    none: [],
  };
  loading: boolean;
  addNode = false;
  reloading: boolean;
  nodesUpdating: boolean;
  enn: boolean[] = [];
  profile: any;
  user: any;
  account: any;
  teams: any;
  searchEntry: any;
  searchQuery = this.userSettingsService.cache['searchQuery'];
  searchTotals: any = {};
  groupBy = '';
  sortBy = {
    stat: '',
    asc: false,
  };
  collapsedStates = this.userSettingsService.cache['collapsedStates'];
  notificationState = this.userSettingsService.cache['notificationState'];
  currentNotice = new Date('2024-07-11').getTime();

  accountLoaded$ = new Subject<void>();
  searchClick$ = new Subject<string>();
  refreshTimeRange$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    public userSettingsService: UserSettingsService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.devstack = environment.apiUrl.includes('thedevbiz.com');
    this.alive = true;
  }

  private internalTags: SearchItem[] = [
    { type: 'smart_tag', subject: 'fraud-detection-on' },
    { type: 'smart_tag', subject: 'fraud-detection-off' },
    { type: 'smart_tag', subject: 'fraud-detection-blocking' },
    { type: 'smart_tag', subject: 'fraud-detection-exp-on' },
    { type: 'smart_tag', subject: 'profitable-this-period' }
  ];

  private sortGroupedItems(groupItems: GroupedItem[], groupType: string, sortBy: string, sortAsc: boolean) {
    // sort inner groups first
    for (const item of groupItems) {
      item.data.sort(function(a, b) {

        // sort by campaign name
        if (sortBy === 'name') {
          if (a.campaign.name > b.campaign.name) {
            return sortAsc ? 1 : -1;
          }
          if (a.campaign.name < b.campaign.name) {
            return sortAsc ? -1 : 1;
          }
          // equal
          return 0;
        }

        // sort by selected click stat
        if (a.counts[sortBy] > b.counts[sortBy]) {
          return sortAsc ? -1 : 1;
        }
        if (a.counts[sortBy] < b.counts[sortBy]) {
          return sortAsc ? 1 : -1;
        }
        // equal
        return 0;
      });
    }

    // sort groups by totals
    groupItems.sort(function(a, b) {
      // sort by group title
      if (sortBy === 'name') {

        // sort by node name
        if (groupType === 'node') {
          if (a.metadata['name'] > b.metadata['name']) {
            return sortAsc ? 1 : -1;
          }
          if (a.metadata['name'] < b.metadata['name']) {
            return sortAsc ? -1 : 1;
          }

        // sort by group id (grouping by tag / none)
        } else {
          if (a.id > b.id) {
            return sortAsc ? 1 : -1;
          }
          if (a.id < b.id) {
            return sortAsc ? -1 : 1;
          }
        }
        // equal
        return 0;
      }

      // sort by selected click stat
      if (a.totals[sortBy] > b.totals[sortBy]) {
        return sortAsc ? -1 : 1;
      }
      if (a.totals[sortBy] < b.totals[sortBy]) {
        return sortAsc ? 1 : -1;
      }
      // equals
      return 0;
    });


    // if we're sorting by tags, always put empty last
    if (groupType === 'tag') {
      groupItems.forEach(function(v, index) {
        if (v.id === '') {
          // remove the ungrouped item from the array and push on the end
          groupItems.push(...groupItems.splice(index, 1));
          return;
        }
      });
    }
  }

  private groupByNode(nodes: NodeListResponse[]): GroupedItem[] {
    const groupedItems: GroupedItem[] = [];

    for (const n of nodes) {
      const g: GroupedItem = {
        type: 'node',
        id: n.id,
        metadata: n,
        data: [],
        totals: {},
      };

      if (n.campaigns) {
        g.data = n.campaigns.map(function(c) {
          return {
            node: n,
            campaign: c,
            counts: {
              name: '',
              allowed: 0,
              blocked: 0,
              blocked_percent: 0,
              conversions: 0,
              cost: 0,
              ctr: 0,
              cvr: 0,
              leads: 0,
              pl: 0,
              revenue: 0,
              roi: 0,
              total: 0
            }
          };
        });
      }
      groupedItems.push(g);
    }
    return groupedItems;
  }

  private groupByTag(nodes: NodeListResponse[]): GroupedItem[] {
    // when grouped by tag, campaigns are grouped by tag
    const groupedItems: GroupedItem[] = [];
    const groupedCampaigns = new Map();

    for (const n of nodes) {
      if (!n.campaigns) { continue; }
      for (const c of n.campaigns) {
        // no tags for campaign

        if (!c.user_tags || c.user_tags.length === 0) {
          if (!groupedCampaigns.has('')) {
            groupedCampaigns.set('', []);
          }
          groupedCampaigns.get('').push({
            n: n,
            c: c
          });
          continue;
        }

        for (const t of c.user_tags) {
          if (!groupedCampaigns.has(t)) {
            groupedCampaigns.set(t, []);
          }
          groupedCampaigns.get(t).push({
            n: n,
            c: c
          });
        }
      }
    }

    groupedCampaigns.forEach(function(taggedCampaigns, tag) {
      const taggedItem: GroupedItem = {
        type: 'tag',
        id: tag,
        metadata: {},
        data: [],
        totals: {},
      };

      for (const c of taggedCampaigns) {
        taggedItem.data.push({
          node: c.n,
          campaign: c.c,
          counts: {
            name: '',
            allowed: 0,
            blocked: 0,
            blocked_percent: 0,
            conversions: 0,
            cost: 0,
            ctr: 0,
            cvr: 0,
            leads: 0,
            pl: 0,
            revenue: 0,
            roi: 0,
            total: 0
          }
         });
      }
      groupedItems.push(taggedItem);
    });
    return groupedItems;
  }

  private groupByNone(nodes: NodeListResponse[]): GroupedItem[] {
    const groupedItems: GroupedItem[] = [];

    const g: GroupedItem = {
      type: 'none',
      id: '',
      metadata: '',
      data: [],
      totals: {},
    };

    // append all campaigns to a single groupedItem
    for (const n of nodes) {
      if (!n.campaigns) { continue; }
        g.data.push(...n.campaigns.map(function(c) {
          return {
            node: n,
            campaign: c,
            counts: {
              name: '',
              allowed: 0,
              blocked: 0,
              blocked_percent: 0,
              conversions: 0,
              cost: 0,
              ctr: 0,
              cvr: 0,
              leads: 0,
              pl: 0,
              revenue: 0,
              roi: 0,
              total: 0
            }
          };
        }));
    }
    // only append the group if campaigns existed
    if (g.data.length !== 0) {
      groupedItems.push(g);
    }
    return groupedItems;
  }

  private sumGroupItemTotals(groupItems: GroupedItem[]): GroupedItem[] {
    for (const group of groupItems) {
      if (!group.data) { return; }
      for (const d of group.data) {
        if (!this.campCounts[d.node.id] || !this.campCounts[d.node.id][d.campaign.id]) { continue; }

        // apply counts to groups
        d.counts['total'] = this.campCounts[d.node.id][d.campaign.id]['total'];
        d.counts['blocked'] = this.campCounts[d.node.id][d.campaign.id]['blocked'];
        d.counts['allowed'] = this.campCounts[d.node.id][d.campaign.id]['allowed'];
        d.counts['leads'] = this.campCounts[d.node.id][d.campaign.id]['leads'];
        d.counts['ctr'] = this.campCounts[d.node.id][d.campaign.id]['ctr'];
        d.counts['conversions'] = this.campCounts[d.node.id][d.campaign.id]['conversions'];
        d.counts['cost'] = this.campCounts[d.node.id][d.campaign.id]['cost'];
        d.counts['revenue'] = this.campCounts[d.node.id][d.campaign.id]['revenue'];
        d.counts['pl'] = this.campCounts[d.node.id][d.campaign.id]['pl'];
        d.counts['blocked_percent'] = d.counts.total > 0 ? (d.counts.blocked / d.counts.total) * 100 : 0;
        d.counts['roi'] = this.campCounts[d.node.id][d.campaign.id]['roi'];

        // sum to total for group
        group.totals['total'] = d.counts['total'] + (group.totals['total'] || 0);
        group.totals['blocked'] = d.counts['blocked'] + (group.totals['blocked'] || 0);
        group.totals['allowed'] = d.counts['allowed'] + (group.totals['allowed'] || 0);
        group.totals['leads'] = d.counts['leads'] + (group.totals['leads'] || 0);
        group.totals['ctr'] = group.totals['allowed'] > 0 ? (group.totals['leads'] / group.totals['allowed']) * 100 : (group.totals['ctr'] || 0);
        group.totals['conversions'] = d.counts['conversions'] + (group.totals['conversions'] || 0);
        group.totals['cost'] = d.counts['cost'] + (group.totals['cost'] || 0);
        group.totals['revenue'] = d.counts['revenue'] + (group.totals['revenue'] || 0);
        group.totals['pl'] = d.counts['pl'] + (group.totals['pl'] || 0);

        group.totals['blocked_percent'] = group.totals['total'] > 0 ? (group.totals['blocked'] / group.totals['total']) * 100 : 0;
        group.totals['roi'] = group.totals['total'] > 0 ? (group.totals['blocked'] / group.totals['total']) * 100 : 0;

        if (group.totals['revenue'] === group.totals['cost']) {
          group.totals['roi'] = 0;
        } else if (group.totals['cost'] > 0) {
          group.totals['roi'] = ((group.totals['revenue'] - group.totals['cost']) / group.totals['cost']) * 100;
        } else {
          group.totals['roi'] = 100;
        }
      }
    }
    return groupItems;
  }

  private stripCampaign(c) {
    c.flow = <CampaignFlow>{};
    c.landing_pages = [NewLandingPage()];
  }

  private nodesIntoGroups(nodes: NodeListResponse[]): any {
    // strip detailed campaign data, not necessary to keep in memory as we do not display
    for(let i = 0; i < nodes.length; i++) {
      const n = nodes[i];
      if (!n.campaigns) { continue };
      for (let j = 0; j < n.campaigns.length; j++) {
        this.stripCampaign(nodes[i].campaigns[j]);
      }
    }

    const groups = {};
    groups['node'] = this.groupByNode(nodes);
    groups['tag'] = this.groupByTag(nodes);
    groups['none'] = this.groupByNone(nodes);
    return this.sumGroups(groups);
  }

  private sumGroups(groups: any): any {
    groups['node'] = this.sumGroupItemTotals(groups['node']);
    groups['tag'] = this.sumGroupItemTotals(groups['tag']);
    groups['none'] = this.sumGroupItemTotals(groups['none']);
    return groups;
  }

  // checks to see if the selected group still has items in it. It not, display node overview
  private ensureGroupValid() {
    if (!this.groupedItems[this.groupBy].length) {
      this.groupBy = 'node';
    }
  }

  isDowngradeAvailable(node: any): boolean {
    return node.status === "update_available" && /beta|staging/.test(node.version)
  }

  isUpdateAvailable(node: any): boolean {
    return node.status === "update_available" && !/beta|staging/.test(node.version)
  }

  nodeUpdateAvailable() {
    if (!this.nodes) {
      return false;
    }
    for(let i = 0; i < this.nodes.length; i++) {
      if (this.isUpdateAvailable(this.nodes[i])) {
        return true;
      }
    }
    return false;
  }

  toggleExpand(id: string) {
    this.collapsedStates.set(id, !this.collapsedStates.get(id));
  }

  collapseAll() {
    let group = this.groupedItems['node'];
    for (const g of group) {
      this.collapsedStates.set(g.id, true);
    }

    group = this.groupedItems['tag'];
    for (const g of group) {
      this.collapsedStates.set(g.id, true);
    }

    group = this.groupedItems['none'];
    for (const g of group) {
      this.collapsedStates.set(g.id, true);
    }
  }

  expandAll() {
    this.collapsedStates.data = {};
  }

  toggleGroupBy(group: string) {
    this.groupBy = group;

    this.sortGroupedItems(this.groupedItems[group], group, this.sortBy.stat, this.sortBy.asc);

    // store chosen setting in cache
    this.userSettingsService.cache['grouping'].set('groupBy', group);
  }

  search = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .merge(this.searchClick$.filter(() => !this.searchInstance.isPopupOpen()))
      .map(term => {
        let result = [];
        const smartTags = this.internalTags;

        // if searching for a term, also search smart tags.
        // if no search term entered, show smart tags
        if (term.length > 0) {

          // tags
          const tags = this.userSettingsService.cache['tags'].data.map(function(v) {
            return { type: 'equal', subject: 'tag', name: v };
          });
          const tagSearch = tags.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 4);

          // campaign routes
          const routes = this.userSettingsService.cache['allRoutes'].map(function(v) {
            return { type: 'equal', subject: 'route', name: v };
          });
          const routeSearch = routes.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 4);

          // campaign IDs
          const campIDs = this.userSettingsService.cache['campaignIDs'].map(function(v) {
            return { type: 'equal', subject: 'campaign ID', name: v };
          });
          const campIDsSearch = campIDs.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 4);

          // campaign names
          const campNames = this.userSettingsService.cache['campaignNames'].map(function(v) {
            return { type: 'equal', subject: 'campaign name', name: v };
          });
          const campNameSearch = campNames.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 4);

          // node names
          const nodeNames = this.userSettingsService.cache['nodeNames'].map(function(v) {
            return { type: 'equal', subject: 'node name', name: v };
          });
          const nodeNameSearch = nodeNames.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 4);

          // node IPs
          const nodeIPs = this.userSettingsService.cache['nodeIPs'].map(function(v) {
            return { type: 'equal', subject: 'node IP', name: v };
          });
          const nodeIPSearch = nodeIPs.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 4);

          // page domains (not included in result, as we cannot match the domain against url exactly)
          const pageDomains = this.userSettingsService.cache['domains'].map(function(v) {
            return { type: 'equal', subject: 'page domain', name: v };
          });
          const domainSearch = pageDomains.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 4);

          // 'contains' filters
          // campaign name
          const campNamecontains = [], routeContains = [], domainContains = [];
          if (campNameSearch.length > 1) {
            campNamecontains.push({ type: 'contains', subject: 'campaign name', name: term });
          }
          if (routeSearch.length > 1) {
            routeContains.push({ type: 'contains', subject: 'route', name: term });
          }
          if (domainSearch.length > 0) { // can't do an equals on page domain, so show even if only 1 match
            domainContains.push({ type: 'contains', subject: 'page domain', name: term });
          }

          // smart tags
          const smartTagSearch = smartTags.filter(v => v.subject.toLowerCase().indexOf(term.toLocaleLowerCase()) > -1);

          result = result.concat(
            nodeNameSearch,
            nodeIPSearch,
            campIDsSearch,
            campNameSearch,
            campNamecontains,
            routeSearch,
            routeContains,
            domainContains,
            tagSearch,
            smartTagSearch
          );

          if (result.length === 0) {
            result.push('No matches found');
          }

        } else {

          result = result.concat(smartTags);

        }

        return result;
      })

  sort(stat: string) {
    if (stat === this.sortBy.stat) {
      this.sortBy.asc = !this.sortBy.asc;
    }
    this.sortBy.stat = stat;

    // only sort currently viewed group
    this.sortGroupedItems(this.groupedItems[this.groupBy], this.groupBy, this.sortBy.stat, this.sortBy.asc);
    this.userSettingsService.cache['grouping'].set('sortBy', this.sortBy);
  }

  routerLinkNewCampaign(node: NodeListResponse) {
    this.userSettingsService.cache['nodeSelected'] = node;
    this.router.navigate(['/team', node.team_id, 'node', node.id, 'campaign', 'new']);
  }

  routerLinkCampaignSetup(node: NodeListResponse, campaign: Campaign) {
    this.userSettingsService.cache['nodeSelected'] = node;
    this.router.navigate(['/team', node.team_id, 'node', node.id, 'campaign', campaign.id, 'setup']);
  }

  routerLinkCampaignOverview(node: NodeListResponse, campaign: Campaign) {
    this.userSettingsService.cache['nodeSelected'] = node;
    this.router.navigate(['/team', node.team_id, 'node', node.id, 'campaign', campaign.id]);
  }

  addSearchItem(val: SearchItem) {
    // no duplicates
    if (this.searchQuery.data.filter(v => JSON.stringify(v) === JSON.stringify(val)).length > 0) {
      return;
    }

    // add to array and apply search
    this.searchQuery.push(val);
    this.nodes = this.applySearch(this.allNodes);

    this.groupedItems = this.nodesIntoGroups(this.nodes);
    // only sort currently viewed group
    this.sortGroupedItems(this.groupedItems[this.groupBy], this.groupBy, this.sortBy.stat, this.sortBy.asc);

    // update cache
    this.userSettingsService.cache['overview'].nodeData = this.nodes;
    this.userSettingsService.cache['overview'].groupedItems = this.groupedItems;
  }

  addSearchSelection(ev: NgbTypeaheadSelectItemEvent) {
    ev.preventDefault();
    this.searchEntry = null;

    const val = ev.item;

    if (!val.type) { return; } // 'no matches found'

    this.addSearchItem(val);
  }

  remSearchItem(idx: number) {
    this.searchQuery.splice(idx);
    this.nodes = this.applySearch(this.allNodes);

    this.groupedItems = this.nodesIntoGroups(this.nodes);
    // only sort currently viewed group
    this.sortGroupedItems(this.groupedItems[this.groupBy], this.groupBy, this.sortBy.stat, this.sortBy.asc);

    // update cache
    this.userSettingsService.cache['overview'].nodeData = this.nodes;
    this.userSettingsService.cache['overview'].groupedItems = this.groupedItems;
  }

  applySearch(nr: NodeListResponse[]) {
    // copy input so we do not modify the original
    let nrcopy = JSON.parse(JSON.stringify(nr));

    // apply the search
    for (const q of this.searchQuery.data) {
      switch (q.type) {
        case 'equal':
          switch (q.subject) {
            case 'route':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.route.toLowerCase() === q.name.toLowerCase());
                return node.campaigns.length > 0;
              });
              break;
            case 'campaign ID':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.id.toLowerCase() === q.name.toLowerCase());
                return node.campaigns.length > 0;
              });
              break;
            case 'campaign name':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.name.toLowerCase() === q.name.toLowerCase());
                return node.campaigns.length > 0;
              });
              break;
            case 'tag':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.user_tags && camp.user_tags.indexOf(q.name.toLowerCase()) > -1);
                return node.campaigns.length > 0;
              });
              break;
            case 'node name':
              nrcopy = nrcopy.filter(node => node.name === q.name);
              break;
            case 'node IP':
              nrcopy = nrcopy.filter(node => node.id === q.name);
              break;
          }
          break;
        case 'contains':
          switch (q.subject) {
            case 'route':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.route.toLowerCase().indexOf(q.name.toLowerCase()) > -1);
                return node.campaigns.length > 0;
              });
              break;
            case 'page domain':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => {
                  return camp.landing_pages.filter(lp => lp.url.indexOf(q.name.toLowerCase()) > -1).length > 0 ||

                  // depending on node version, some campaigns have no noipfraud landing pages
                  (camp.noipfraud.hasOwnProperty('landing_pages') &&
                  camp.noipfraud.landing_pages.filter(lp => lp.url.indexOf(q.name.toLowerCase()) > -1).length > 0);
                });
                return node.campaigns.length > 0;
              });
              break;
            case 'campaign name':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.name.toLowerCase().indexOf(q.name.toLowerCase()) > -1);
                return node.campaigns.length > 0;
              });
              break;
          }
          break;
        case 'smart_tag':
          switch (q.subject) {
            case 'fraud-detection-on':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.noipfraud.enabled === true);
                return node.campaigns.length > 0;
              });
              break;
            case 'fraud-detection-off':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.noipfraud.enabled === false);
                return node.campaigns.length > 0;
              });
              break;
            case 'fraud-detection-blocking':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.noipfraud.enabled && camp.noipfraud.block_all === true);
                return node.campaigns.length > 0;
              });
              break;
            case 'fraud-detection-exp-on':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => camp.noipfraud.enabled && camp.noipfraud.exp_enabled === true);
                return node.campaigns.length > 0;
              });
              break;
            case 'profitable-this-period':
              nrcopy = nrcopy.filter(node => {
                if (!node.campaigns) { node.campaigns = []; }
                node.campaigns = node.campaigns.filter(camp => this.campCounts[node.id][camp.id].pl > 0);
                return node.campaigns.length > 0;
              });
              break;
          }
          break;
      }
    }

    this.updateSearchTotals(nrcopy);

    return nrcopy;
  }

  async updateSearchTotals(nodes: NodeListResponse[]) {
    this.searchTotals = {};
    const r: any = {};
    for (const node of nodes) {
      if (!node.campaigns) { continue; }
      for (const camp of node.campaigns) {
        if (!this.campCounts[node.id] || !this.campCounts[node.id][camp.id]) { continue; }
        r['total'] = this.campCounts[node.id][camp.id]['total'] + (r['total'] || 0);
        r['blocked'] = this.campCounts[node.id][camp.id]['blocked'] + (r['blocked'] || 0);
        r['allowed'] = this.campCounts[node.id][camp.id]['allowed'] + (r['allowed'] || 0);
        r['leads'] = this.campCounts[node.id][camp.id]['leads'] + (r['leads'] || 0);
        r['ctr'] = r['allowed'] > 0 ? (r['leads']  / r['allowed']) * 100 : (r['ctr'] || 0);
        r['conversions'] = this.campCounts[node.id][camp.id]['conversions'] + (r['conversions'] || 0);
        r['cost'] = this.campCounts[node.id][camp.id]['cost'] + (r['cost'] || 0);
        r['revenue'] = this.campCounts[node.id][camp.id]['revenue'] + (r['revenue'] || 0);
        r['pl'] = this.campCounts[node.id][camp.id]['pl'] + (r['pl'] || 0);

        r['blocked_percent'] = r['total'] > 0 ? (r['blocked'] / r['total']) * 100 : 0;
        r['roi'] = r['total'] > 0 ? (r['blocked'] / r['total']) * 100 : 0;

        if (r['revenue'] === r['cost']) {
          r['roi'] = 0;
        } else if (r['cost'] > 0) {
          r['roi'] = ((r['revenue'] - r['cost']) / r['cost']) * 100;
        } else {
          r['roi'] = 100;
        }

        r['camps'] = 1 + (r['camps'] || 0);
      }
    }
    this.searchTotals = r;
    this.userSettingsService.cache['overview'].searchTotals = r;
  }

  dtChanged(dt: DateStruct) {
    this.userSettingsService.selectedDates = dt;
    this.loadOverview();
  }

  refreshOverview() {
    this.refreshTimeRange$.next();
  }

  loadOverview() {
    this.reloading = true;

    forkJoin(
      this.apiService.getOverview(),
      this.apiService.getCounts(
        this.userSettingsService.fromNano,
        this.userSettingsService.toNano)
    ).finally( () => {
      this.reloading = false;
    }).subscribe(([overviewResp, countResp]) => {
      // store retrieved data
      this.allNodes = <NodeListResponse[]>overviewResp.data;
      this.campCounts = <NodesClickStatsReponse>countResp.data;
      this.userSettingsService.cache['overview'].campCounts = this.campCounts;
      this.userSettingsService.cache['overview'].nodes = this.allNodes;

      // apply search
      this.nodes = this.applySearch(this.allNodes);

      // group nodes
      this.groupedItems = this.nodesIntoGroups(this.nodes);

      // sort single group, no need to sort the user is not looking at
      this.sortGroupedItems(this.groupedItems[this.groupBy], this.groupBy, this.sortBy.stat, this.sortBy.asc);

      this.ensureGroupValid();

      // update caches
      this.userSettingsService.cache['overview'].nodeData = this.nodes;
      this.userSettingsService.cache['overview'].groupedItems = this.groupedItems;
      this.userSettingsService.cache['grouping'].set('sortBy', this.sortBy);
      this.userSettingsService.cache['grouping'].set('groupBy', this.groupBy);
      this.userSettingsService.updateSearchCache(this.allNodes);
      this.userSettingsService.updateRouteCache(this.allNodes);
    });
  }

  isProduction(): boolean {
    return environment.production
  }

  nodeOK(status: string): boolean {
    return (status === 'ok');
  }

  allowEdit(status: string): boolean {
    return status !== 'error'
  }

  nodeIssue(status: string): boolean {
    return (status !== 'ok' && status !== 'error');
  }

  nodeStatusText(status: string): string {
    switch (status) {
      case 'ok':
        return 'OK';
      case 'update_available':
        return 'Update Available';
      case 'updating':
        return 'Updating...';
      default:
        return `Status: ${status}`;
    }
  }

  editNodeName(index: number, node: NodeListResponse) {
    if (this.nodeOK(node.status)) {
      this.enn[index] = true;
      this.focusedIndex = index;
    }
    return false;
  }

  saveNodeName(idx: number, node: NodeListResponse) {
    this.enn[idx] = false;
    this.apiService.updateNodeName(node.team_id, node.id, node.name).subscribe();
  }

  updateNode(teamID: string, nodeID: string): void {
    this.apiService.updateNode(teamID, nodeID).subscribe(
      result => {
        // set status
        const i = this.nodes.findIndex(n => n.id === nodeID);
        this.nodes[i].status = 'updating';
      }
    );
  }

  updateNodes(teamID: string): void {
    const nodesToUpdate = this.nodes.filter(n => n.status === 'update_available').length;
    if (nodesToUpdate === 0) {
      return;
    }
      
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.message = `You are about to update ${nodesToUpdate} node(s)! We recommend you update 1 first and test your campaigns.`;
    modalRef.result.catch((confirmed: any)  => {
      if (confirmed !== true) {
        return;
      }
      this.nodesUpdating = true;
      this.apiService.updateNodes(teamID).subscribe(
        result => {
          this.nodes.map(function(n) {
            if(n.status === 'update_available') {n.status = 'updating'}
          });
        }
      ); 

    });
  }

  showChargebeePortal() {
    openPortal();
  }

  showExport() {
    const modalRef = this.modalService.open(MigrateDialogComponent, {keyboard: false, backdrop: 'static', container: 'body'});
    modalRef.componentInstance.action = "export";
    modalRef.componentInstance.exported = this.account.exported;
    modalRef.result.catch(res => {
      if (!res) {return};
      // redirect to import page
      window.location.href = 'https://newdash.n2.app/importaccount?aid='+this.account.id;
    }); 
  }

  changePassword() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.message = `This will start the password reset flow.
      You will receive an email at ${this.authService.user.email} with instructions on how to continue.`;
    modalRef.result.catch((confirmed: boolean)  => {
      if (!confirmed) {
        return;
      }
      this.apiService.changePassword().subscribe();
    });
  }

  open() {
    if (!this.account.active) {
      return;
    }
    const modalRef = this.modalService.open(AddNodeModalComponent);
    modalRef.result.catch(() => {
      this.loadOverview();
    });
  }

  openLinksModal(node: Node, camp: Campaign) {
    this.userSettingsService.cache.campSelected = camp;
    this.userSettingsService.cache.nodeSelected = node;
    const modalRef = this.modalService.open(LinksModalComponent);
    modalRef.result.catch(() => {
    });
  }

  deleteCampaign(teamID: string, nodeID: string, campaignID: string) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.message = 'Campaigns CAN NOT be recovered after they are deleted! So make sure you want to really delete this.';
    modalRef.result.catch((confirmed: any)  => {
      if (confirmed !== true) {
        return;
      }
      this.apiService.deleteCampaign(teamID, nodeID, campaignID).subscribe(
        () => {
          const i = this.nodes.findIndex(n => n.id === nodeID);
          const campPos = this.nodes[i].campaigns.findIndex(c => c.id === campaignID);
          const campRoute = this.nodes[i].campaigns[campPos].route;
          // remove from local array and cache
          this.userSettingsService.removeFromRouteCache(nodeID, campRoute);
          this.nodes[i].campaigns = this.nodes[i].campaigns.filter(function(el) {
            return el.id !== campaignID;
          });

          // rebuild groups
          this.groupedItems = this.nodesIntoGroups(this.nodes);
          // re-apply sort
          this.sortGroupedItems(this.groupedItems[this.groupBy], this.groupBy, this.sortBy.stat, this.sortBy.asc);

          // check there is still data to display for selected group
          this.ensureGroupValid();
        }
      );
    });
  }

  deleteNode(teamID: string, nodeID: string) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.message = 'This will HIDE your node from the dashboard. It wont be deleted or shut down. Please destroy the server to permanently remove it. To unhide it, please restart your node with: systemctl restart n2';
    modalRef.result.catch((confirmed: any)  => {
      if (confirmed !== true) {
        return;
      }
      this.apiService.deleteNode(teamID, nodeID).subscribe(
        result => {
          // remove from local array
          this.nodes = this.nodes.filter(function(el) {
            return el.id !== nodeID;
          });

          // rebuild groups
          this.groupedItems = this.nodesIntoGroups(this.nodes);
          // re-apply sort
          this.sortGroupedItems(this.groupedItems[this.groupBy], this.groupBy, this.sortBy.stat, this.sortBy.asc);
        });
    });
  }

  // this exists because template functions error if either node or camp ID
  // do not exist in campCounts
  getCampCount(nodeId: string, campId: string, target: string, deflt: any): any {
    if (!this.campCounts || this.campCounts === null) { return deflt; }
    if (!this.campCounts.hasOwnProperty(nodeId)) { return deflt; }
    if (!this.campCounts[nodeId].hasOwnProperty(campId)) { return deflt; }
    if (!this.campCounts[nodeId][campId].hasOwnProperty(target)) { return deflt; }
    return this.campCounts[nodeId][campId][target];
  }

  refreshAccount() {
    this.authService.refreshAccount().subscribe(
      account => {
        this.account = account.data;
        this.accountLoaded$.next();
      }
    );
  }

  showSubscribePrompt() {
    const modalRef = this.modalService.open(SubscribeDialogComponent, {keyboard: false, backdrop: 'static', container: 'body'});
    modalRef.componentInstance.account = this.account;
  }

  showNotice() {
    return (!this.notificationState.get('dash') || this.notificationState.get('dash') < this.currentNotice )
  }

  hideNotice() {
    this.notificationState.set('dash',Date.now());
  }

  ngOnInit() {

    /*
    this.nodes = [
      {
        id: '111.222.111.222',
        name: 'node1',
        status: 'ok',
        campaigns: [
          {
            id: 'dkjngjndgl',
            name: 'camp1',
            route: '/blah',
            nodeID: '111.222.111.222',
            traffic_source_id: 'adsf',
            primary_pages: [],
            secondary_pages: []
          }
        ]
      }
    ];
    */

    // subscribe to first event only
    this.accountLoaded$.pipe(first()).subscribe(() => {
      // check if account has been exported 
      if (this.account.exported) {
        this.showExport();
      }
      if (this.account.migrated && this.account.cb_id === '') {
        this.showSubscribePrompt();
      }
    })

    this.groupBy = this.userSettingsService.cache['grouping'].get('groupBy') || 'node';
    this.sortBy = this.userSettingsService.cache['grouping'].get('sortBy') || {stat: 'name', asc: false};

    // get nodes list initially so that we have something to display
    if (!this.userSettingsService.cache['overview'].nodeData) {
      this.loading = true;
      this.apiService.getNodeList()
      .finally( () => this.loading = false )
      .subscribe(
        result => {
          this.nodes = <NodeListResponse[]>result.data;

          // load overview immediately and then every 20s
          TimerObservable.create(0, 2000000).pipe(
            takeWhile(() => this.alive)
          )
          .subscribe(() => {
            this.refreshOverview();
          });
        }
      );
    } else {
      this.groupedItems = this.userSettingsService.cache['overview'].groupedItems;
      this.nodes = this.userSettingsService.cache['overview'].nodeData;
      this.campCounts = this.userSettingsService.cache['overview'].campCounts;
      this.searchTotals = this.userSettingsService.cache['overview'].searchTotals;
      this.loadOverview();
    }

    if (this.authService.userProfile) {
      this.profile = this.authService.userProfile;
    } else {
      this.authService.getProfile((err, profile) => {
        this.profile = profile;
      });
    }

    if (this.authService.user) {
      this.user = this.authService.user;
    } else {
      this.authService.refreshUser().subscribe(
        user => {
          this.user = user;
        }
      );
    }

    if (this.authService.account) {
      this.account = this.authService.account;
      this.accountLoaded$.next();
    } else {
      this.refreshAccount();
    }

    if (this.authService.teams) {
      this.user = this.authService.user;
    } else {
      this.authService.refreshUserTeams().subscribe(
        teams => {
          this.teams = teams;
        }
      );
    }
  }

  ngAfterViewChecked() {
    if (this.focusedIndex !== null) {
      const inputs = this.nodeNameInputs.toArray();
      if (inputs[this.focusedIndex]) {
        inputs[this.focusedIndex].nativeElement.focus();
        inputs[this.focusedIndex].nativeElement.select();
        this.focusedIndex = null;
      }
    }
  }
  
  ngOnDestroy() {
    this.alive = false;
  }

}
