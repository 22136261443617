import { Component, OnInit, OnDestroy } from '@angular/core';

import { ApiService, ClickStatsResponse } from '../../api.service';
import { UserSettingsService } from '../../user-settings.service';
import { CampaignDetailService } from '../campaign-detail.service';
import { Campaign } from '../../campaigns';
import { CountStats } from '../../stats';

import { DateStruct } from '../../date-range.component';

import * as shape from 'd3-shape';

import { Timezone } from '../../timezones';

import { forkJoin, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { TimerObservable } from 'rxjs/observable/TimerObservable';

@Component({
  selector: 'app-campaign-overview',
  templateUrl: './campaign-overview.component.html',
  styleUrls: ['./campaign-overview.component.css']
})
export class CampaignOverviewComponent implements OnInit, OnDestroy {

  shape = shape;
  campaign: Campaign;
  counts: any = {};
  landingPageStats: CountStats[];
  offerStats: CountStats[];
  loadingCounts: boolean;
  loadingLandingPages: boolean;
  loadingOffers: boolean;
  selectedTimezone: Timezone;
  reloading: boolean;
  alive: boolean;
  sortOrder = {
    landingPages: {
      stat: 'name',
      asc: false
    },
    offers: {
      stat: 'name',
      asc: false
    },
  };
  refreshTimeRange$ = new Subject<void>();

  graphData: any = [];

  series = [
    { key: 'allowed', display: 'Allowed' },
    { key: 'blocked', display: 'Blocked' },
    { key: 'leads', display: 'LP Clicks' },
    { key: 'conversions', display: 'Conversions' },
    { key: 'cost', display: 'Cost' },
    { key: 'revenue', display: 'Revenue' },
    { key: 'pl', display: 'P/L' }
  ];

  countries = [
    { name: 'Allowed' },
    { name: 'Blocked' },
    { name: 'LP Clicks' },
    { name: 'Conversions' },
    { name: 'Cost' },
    { name: 'Revenue' },
    { name: 'P/L' }
  ];

  seriesColors = [
    { name: 'Allowed', value: '#3f51b5' },
    { name: 'Blocked', value: '#ff4515' },
    { name: 'LP Clicks', value: '#00b861' },
    { name: 'Conversions', value: '#afe007' },
    { name: 'Cost', value: '#f4e561' },
    { name: 'Revenue', value: '#ff9700' },
    { name: 'P/L', value: '#2395f3' }
  ];

  activeEntries: any[] = [];

  constructor(
    public userSettingsService: UserSettingsService,
    private campaignDetailService: CampaignDetailService,
    private apiService: ApiService
  ) {
    this.alive = true;
  }

  log(ev: any) {
    console.log(ev);
  }

  dtChanged(dt: DateStruct) {
    this.userSettingsService.selectedDates = dt;
    this.loadStats();
  }

  activateSeries(name: string) {
    if (!name.length) {
      this.activeEntries = [];
      return;
    }
    this.activeEntries = [ {name: name} ];
  }

  getSeriesColor(name: string) {
    for (const r of this.seriesColors) {
      if (r.name === name) { return r.value; }
    }
  }

  sortLandingPages(sortBy: string) {
    if (sortBy === this.sortOrder.landingPages.stat) {
      this.sortOrder.landingPages.asc = !this.sortOrder.landingPages.asc;
    }
    this.sortOrder.landingPages.stat = sortBy;

    // persist in cache
    this.userSettingsService.cache['campDetailSort'].set(this.campaignDetailService.campID, this.sortOrder);

    this.sortLandingPageStats(this.sortOrder.landingPages.stat, this.sortOrder.landingPages.asc);
  }

  private sortLandingPageStats(sortBy: string, sortAsc: boolean) {
    this.landingPageStats.sort(function(a, b) {
        if (a[sortBy] > b[sortBy]) {
          return sortAsc ? -1 : 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return sortAsc ? 1 : -1;
        }
        // equal
        return 0;
    });
  }

  sortOffers(sortBy: string) {
    if (sortBy === this.sortOrder.offers.stat) {
      this.sortOrder.offers.asc = !this.sortOrder.offers.asc;
    }
    this.sortOrder.offers.stat = sortBy;

    // persist in cache
    this.userSettingsService.cache['campDetailSort'].set(this.campaignDetailService.campID, this.sortOrder);

    this.sortOfferStats(this.sortOrder.offers.stat, this.sortOrder.offers.asc);
  }

  private sortOfferStats(sortBy: string, sortAsc: boolean) {
    this.offerStats.sort(function(a, b) {
        if (a[sortBy] > b[sortBy]) {
          return sortAsc ? -1 : 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return sortAsc ? 1 : -1;
        }
        // equal
        return 0;
    });
  }

  loadStats() {
    // use hourly if period is <= 1d
    let interval = 'd';
    if (this.userSettingsService.diffHours() <= 24) {
      interval = 'h';
    }

    this.reloading = true;
    forkJoin(
      this.apiService.getCampaignCounts(
        this.campaignDetailService.teamID,
        this.campaign.nodeID,
        this.campaign.id,
        this.userSettingsService.fromNano,
        this.userSettingsService.toNano,
        interval)
      .finally( () => { this.loadingCounts = false; } )
      .map(
        counts => {
          this.counts = {};
          this.graphData = [];

          for (const p of counts.data) {
            // sum all intervals together to get totals for display
            for (const key of Object.keys(p.counts)) {
              if (!this.counts.hasOwnProperty(key)) {
                this.counts[key] = 0;
              }
              this.counts[key] += p.counts[key];
            }

            // build graph data
            for (let i = 0; i < this.series.length; i++) {
              if (!this.graphData[i]) { this.graphData[i] = { series: [] }; }
              this.graphData[i]['name'] = this.series[i].display;
              this.graphData[i]['series'].push({
                name: new Date(p.period),
                value: p.counts[this.series[i].key]
              });
            }
          }

          // CTR and CVR calc
          this.counts['ctr'] = 0;
          this.counts['cvr'] = 0;
          if (this.counts['allowed'] > 0) {
            this.counts['ctr'] = this.counts['leads'] / this.counts['allowed'] * 100;
            this.counts['cvr'] = this.counts['conversions'] / this.counts['allowed'] * 100;
          }

          // blocked percent
          this.counts['blocked_percent'] = 0;
          if (this.counts['total'] > 0) {
            this.counts['blocked_percent'] = (this.counts['blocked'] / this.counts['total']) * 100.0;
          }

          // ROI calc
          // rev=0, cost=0, roi = 0
          // rev=1, cost=0, roi = 100
          // rev=0, cost=1, roi = -100
          if (this.counts['revenue'] === this.counts['cost']) {
            this.counts['roi'] = 0;
          } else if (this.counts['cost'] > 0) {
            this.counts['roi'] = ((this.counts['revenue'] - this.counts['cost']) / this.counts['cost']) * 100;
          } else {
            this.counts['roi'] = 100;
          }

          // store to cache
          this.userSettingsService.cache['campDetailStats']['cd.' + this.campaign.id].counts = this.counts;
          this.userSettingsService.cache['campDetailStats']['cd.' + this.campaign.id].graphData = this.graphData;
        }
      ),
      this.apiService.getLandingPageStats(
        this.campaignDetailService.teamID,
        this.campaign.nodeID,
        this.campaign.id,
        this.userSettingsService.fromNano,
        this.userSettingsService.toNano
      )
      .finally( () => {this.loadingLandingPages = false; } )
      .map(
        counts => {
          this.landingPageStats = <CountStats[]>counts.data;
          this.sortLandingPageStats(this.sortOrder.landingPages.stat, this.sortOrder.landingPages.asc);
          this.userSettingsService.cache['campDetailStats']['cd.' + this.campaign.id].landingPageStats = this.landingPageStats;
        }
      ),
      this.apiService.getOfferStats(
        this.campaignDetailService.teamID,
        this.campaign.nodeID,
        this.campaign.id,
        this.userSettingsService.fromNano,
        this.userSettingsService.toNano
      )
      .finally( () => {this.loadingOffers = false; } )
      .map(
        counts => {
          this.offerStats = <CountStats[]>counts.data;
          this.sortOfferStats(this.sortOrder.offers.stat, this.sortOrder.offers.asc);
          this.userSettingsService.cache['campDetailStats']['cd.' + this.campaign.id].offers = this.offerStats;
        }
      ))
    .subscribe(() => {
      this.reloading = false;
    });
  }

  refreshStats() {
    this.refreshTimeRange$.next();
  }

  ngOnInit() {
    // populate from cache
    const cache = this.userSettingsService.cache['campDetailStats']['cd.' + this.campaignDetailService.campID] || null;
    if (cache) {
      this.counts = cache.counts || null;
      this.graphData = cache.graphData || null;
      this.landingPageStats = cache.landingPageStats || null;
      this.offerStats = cache.offerStats || null;
    } else {
      // init a new cache
      this.userSettingsService.cache['campDetailStats']['cd.' + this.campaignDetailService.campID] = {};
    }

    this.sortOrder = this.userSettingsService.cache['campDetailSort'].get(this.campaignDetailService.campID) ||
    {
      landingPages: {
        stat: 'name',
        asc: false
      },
      offers: {
        stat: 'name',
        asc: false
      },
    };

    // loading?
    if (!this.counts) {
      this.loadingCounts = true;
    }
    if (!this.landingPageStats) {
      this.loadingLandingPages = true;
    }
    if (!this.offerStats) {
      this.loadingOffers = true;
    }

    this.campaignDetailService.getCampaign()
    .subscribe(
      camp => {
        this.campaign = camp;
         // load stats immediately and then every 20s
         TimerObservable.create(0, 20000)
         .pipe( takeWhile(() => this.alive) )
         .subscribe(() => {
           this.refreshStats();
         });
      }
    );
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
