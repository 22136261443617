import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Route } from '@angular/compiler/src/core';
@Injectable()
export class ImportGuard implements CanActivate {
    constructor(
        private authService: AuthService, 
        private router: Router,
        private apiService: ApiService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|Observable<boolean>{
        if (this.authService.isAuthenticated()) {
            // user is already authenticated - move away from import screen
            this.router.navigate(['/']);
            return false;
        }
        return this.accountExists(route.queryParamMap.get('aid'));
    }

    accountExists(accountID: string): Observable<boolean> {
        let res = new Subject<boolean>();
        this.apiService.checkAccountExists(accountID).subscribe(() => {
            // success, redirect to login
            this.authService.login();
            res.next(false);
        }, () => {
            // error - does not exist
            res.next(true);
        });
        return res.asObservable();
    }



}
