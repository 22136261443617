import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';

export interface SearchPopoverListItem {
  header: boolean;
  display: string;
  value: string;
}

@Component({
  selector: 'app-search-popover',
  templateUrl: './search-popover.component.html',
  styleUrls: ['./search-popover.component.css']
})
export class SearchPopoverComponent implements OnInit, OnChanges {

  constructor() { }

  listItems: SearchPopoverListItem[] = [];
  search = '';

  @Input() items: SearchPopoverListItem[];
  @Input() title: string;
  @Input() iconClass: string;
  @Input() disabled: boolean;
  @Input() exclude: string[];
  @Input() buttonStyle: {};
  @Input() buttonClass: string;
  @Input() placement: string;
  @Output() itemSelected = new EventEmitter<SearchPopoverListItem>();

  update() {
    // show all results
    this.copyItems();

    // a map object iterates it's elements in insertion order, so it's ok to use a map to organise results here
    let activeHeader = '';
    const headers = new Map();
    headers.set(activeHeader, []);

    // updates the list based on the search
    for (const item of this.listItems) {
      if (item.header) {
        activeHeader = item.value;
        headers.set(activeHeader, [item]);
        continue;
      }

      if (this.exclude && this.exclude.includes(item.value)) {
        continue;
      }

      if (item.display.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
        headers.get(activeHeader).push(item);
      }
    }

    const filteredItems = [];
    headers.forEach(function(items, header) {
      if (header !== '' && items.length < 2) {
        return;
      }
      if (header === '') {
        // empty - no header, just push items
        filteredItems.push(...items);
        return;
      }
      filteredItems.push(...items);
    });
    this.listItems = filteredItems;
  }

  selectItem(item) {
    this.search = '';
    this.itemSelected.emit(item);
  }

  copyItems() {
    if (this.items) {
      this.listItems = this.items.slice();
    } else {
      this.listItems = [];
    }
  }

  ngOnInit() {
   this.update();
  }

  ngOnChanges(changes) {
    this.update();
  }

}
