import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';

import { Subject, Observable } from 'rxjs';

import { NgbModal, NgbActiveModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { LandingPage, OfferPage} from '../../../pages';
import { countries } from '../countries';

@Component({
  selector: 'app-flow-item-content',
  template: `
  <div class="addmodal modal-body">
    <ngb-tabset #tabs (tabChange)="clearEditMode(); tabChange($event)" type="pills">
      <ngb-tab *ngIf="allowedItemTypes.indexOf('page') > -1" id="page" title="Landing page">
        <ng-template ngbTabContent>
          <div class="row mt-2">
            <div class="col">
              <div class="list-group">
                <ng-container *ngFor="let p of pages">
                <button *ngIf="p.name != ''" class="list-group-item list-group-item-action"
                [ngClass]="{'active': item.options.selected && item.options.selected.id === p.id}"
                (click)="item.options.selected = p"><strong>{{p.name}}</strong>
                <small class="ml-1 text-muted">{{p.url}}</small></button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="allowedItemTypes.indexOf('offer') > -1" id="offer" title="Offer">
        <ng-template ngbTabContent>
          <div class="row mt-2">
            <div class="col">
              <div class="list-group">
                <ng-container *ngFor="let o of offers">
                <button *ngIf="o.name != ''" class="list-group-item list-group-item-action"
                [ngClass]="{'active': item.options.selected && item.options.selected.id === o.id}"
                (click)="item.options.selected = o"><strong>{{o.name}}</strong>
                <small class="ml-1 text-muted">{{o.url}}</small></button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="allowedItemTypes.indexOf('percent_split') > -1" id="percent_split" title="Percent split">
        <ng-template ngbTabContent>
          <hr class="mb-3">
          <div class="row mt-2">
            <ng-container *ngFor="let pct of item.options.percentages; let i = index; trackBy: trackByIndex">
              <div class="col-6">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{i+1}}.</span>
                  </div>
                  <input type="number" class="form-control" [(ngModel)]="item.options.percentages[i]"
                  [ngClass]="{'input-is-invalid': item.options.percentages[i] == null}">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                  <div *ngIf="item.options.percentages.length > 2" class="input-group-append">
                    <button
                      (click)="item.options.percentages.splice(i, 1); evenPercentSplit()"
                      class="ml-2 btn btn-outline-danger">
                        <span class="oi oi-circle-x"></span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col">
                  <button
                    (click)="evenPercentSplit()"
                    class="btn btn-outline-secondary btn-block btn-sm">==</button>
                </div>
                <div class="col">
                  <button
                    (click)="item.options.percentages.push(0); evenPercentSplit()"
                    class="btn btn-primary btn-sm btn-block"><span class="oi oi-plus"></span></button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="allowedItemTypes.indexOf('token_split') > -1" id="token_split" title="Token split">
        <ng-template ngbTabContent>
          <hr class="mb-3">
          <div class="row mt-2 mb-3">
            <div class="col">
              <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-primary"
                [disabled]="mode == 'editing'"
                ngbDropdownToggle>{{item.options.selected.name || 'Select token'}}</button>
                <div ngbDropdownMenu>
                  <ng-container *ngFor="let token of tokens">
                    <ng-container [ngSwitch]="token.name">
                      <ng-container *ngSwitchCase="'State'">
                        <button *ifVersion="'>=2.1.0';compare:nodeVersion"
                          (click)="item.options.value = []; item.options.selected = token"
                          class="dropdown-item">{{token.name}}</button>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <button
                        (click)="item.options.value = []; item.options.selected = token"
                        class="dropdown-item">{{token.name}}</button>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!item.options.selected" class="row mt-1">
            <div class="col">
              <span class="text-muted">Select a token to split traffic by</span>
            </div>
          </div>
          <ng-container [ngSwitch]="item.options.selected?.type">
            <ng-container *ngSwitchCase="'country'">
              <div class="row">
                <div class="col">
                  <span *ngFor="let val of item.options.value; index as j"
                  class="badge tag badge-light mr-2 mb-3"
                  [ngClass]="{'editing': editing == j}">
                    <span class="tag-text">{{ val }}</span>
                    <a (click)="setEditMode(j); false" class="text-dark" href="">
                      <span class="oi oi-pencil ml-2"></span>
                    </a>
                    <a (click)="editing > -1 ? clearEditMode() : this.item.options.value.splice(j, 1); false" class="text-dark" href="">
                      <span class="oi oi-circle-x ml-2"></span>
                    </a>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input type="text"
                  autocomplete=off
                  [placeholder]="getHelpText(item)"
                  name="dnacInput"
                  class="form-control"
                  [(ngModel)]="countryInput"
                  (selectItem)="countrySelected($event)"
                  [ngbTypeahead]="countrySearch"
                  [resultFormatter]="countryFormatter"
                  [editable]="false"
                  autofocus>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'text'">
              <ng-container *ngFor="let val of item.options.value; index as j; trackBy: trackByIndex">
                <div class="row">
                  <div class="col">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">{{j+1}}.</span>
                      </div>
                      <input type="text" class="form-control" [(ngModel)]="item.options.value[j]"
                      [ngClass]="{'input-is-invalid': !item.options.value[j]}">
                      <div class="input-group-append">
                        <button
                          (click)="item.options.value.splice(j, 1)"
                          class="ml-2 btn btn-outline-danger">
                            <span class="oi oi-circle-x"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="row">
                <div class="col">
                  <div class="input-group">
                    <input #textInput type="text" class="form-control" [placeholder]="getHelpText(item)" autofocus>
                    <div class="input-group-append">
                      <button
                        (click)="textInput.value.trim().length > 0 && item.options.value.push(textInput.value.trim()); textInput.value = ''"
                        class="btn btn-outline-primary">
                          <span class="oi oi-plus"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'kv'">
              <ng-container *ngFor="let val of item.options.value; index as j; trackBy: trackByIndex">
                <div class="row">
                  <div class="col">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">{{j+1}}.</span>
                      </div>
                      <input type="text" class="form-control"
                      [(ngModel)]="item.options.value[j].key"
                      [ngClass]="{'input-is-invalid': !item.options.value[j].key}">

                      <input type="text" class="form-control"
                      [(ngModel)]="item.options.value[j].val"
                      [ngClass]="{'input-is-invalid': !item.options.value[j].val}">

                      <div class="input-group-append">
                        <button
                          (click)="item.options.value.splice(j, 1)"
                          class="ml-2 btn btn-outline-danger">
                            <span class="oi oi-circle-x"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="row">
                <div class="col">
                  <div class="input-group">
                    <input #paramInput name="urlfparam" type="text" class="form-control" placeholder="Param" autofocus>
                    <input #valueInput name="urlfval" type="text" class="form-control" placeholder="Value">
                    <div class="input-group-append">
                      <button (click)="paramInput.value.trim().length > 0 && valueInput.value.trim().length > 0
                      && addKV(paramInput.value.trim(), valueInput.value.trim()); paramInput.value = ''; valueInput.value = ''"
                      class="btn btn-outline-primary"><span class="oi oi-plus"></span></button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'fixed'">
              <div class="row">
                <div class="col"><span class="text-muted">{{getHelpText(item)}}</span></div>
              </div>
            </ng-container>

          </ng-container>

        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!itemValid(item)" (click)="activeModal.close('ok')">OK</button>
  </div>
`,
  styles: [`
    .tag {
      padding: 10px;
      border: 2px solid #edeeef;
    }
    .tag-text {
      font-size: 1rem;
      font-weight: 400;
    }
    .tag .oi {
      top: 0px;
    }
    .tag.editing {
      border: 2px solid red;
    }
    .list-group-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .list-group-item.active {
      border-color: #2680E3 !important;
      color: #FFF;
    }
    .list-group-item.active small {
      color: #FFF !important;
    }
  `]
})
export class FlowItemContentComponent {
  item: any;
  tabChange: (event: any) => {};
  allowedItemTypes: any;
  tokens: any[];
  countries = countries;
  countryInput: string;
  nodeVersion: string;
  editing: number;
  mode: string;
  offers: any;
  pages: any;

  constructor(public activeModal: NgbActiveModal) {}

  countrySearch = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .map(term => term === '' ? []
        : countries.filter(v =>
            v.search.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.val.toLowerCase().indexOf(term.toLowerCase()) > -1 )
          .slice(0, 10))

  countryFormatter = (x: { search: string; val: string; }) => `${x.search} (${x.val})`;

  itemValid(item): boolean {
    switch (item.type) {
      case 'page':
      case 'offer':
        // must have page or offer selected
        return (item.options.selected);
      case 'percent_split':
        // percentage split must sum to 100
        let t = 0;
        for (const p of item.options.percentages) {
          if (p == null) {
            return false;
          }
          t += p;
        }
        return (t === 100);
      case 'token_split':
        const name = item.options.selected.name;
        // none selected
        if (!name) {
          return false;
        }

        switch (name) {
          case 'ISP':
          case 'State':
          case 'Domain':
            if (item.options.value.length === 0) {
              return false;
            }
            for (const v of item.options.value) {
              if (!v) {
                return false;
              }
            }
            break;
          case 'Country':
            return (item.options.value.length > 0);
          case 'URL Param':
            if (item.options.value.length === 0) {
              return false;
            }
            // check to see if any url params are empty
            for (const kv of item.options.value) {
              if (!kv.key || !kv.val) {
                return false;
              }
            }
        }
        return true;
      default:
        return false;
    }
  }

  setEditMode(index: number) {
    if (this.editing === index) {
      // already editing - clear
      this.clearEditMode();
      return;
    }
    this.editing = index;
  }

  clearEditMode() {
    this.editing = -1;
  }

  getHelpText(item): string {
    switch (item.options.selected.name) {
      case 'ISP':
      case 'State':
      case 'Domain':
        return 'Case incensitive exact match...';
      case 'Country':
        return 'Start typing to add...';
      case 'Device':
        return 'Split traffic by mobile or non-mobile';
    }

    // shouldn't happen but return empty if token is unrecognised
    return '';
  }

  countrySelected(rule: NgbTypeaheadSelectItemEvent) {
    rule.preventDefault();
    this.countryInput = null;

    const val = rule.item.val;

    // initialise if empty
    if (!this.item.options.value) {
      this.item.options.value = [];
    }

    // no duplicates
    if (this.item.options.value.indexOf(val) > -1) {
      return;
    }

    // add item
    if (this.editing > -1) {
      this.item.options.value[this.editing] = val;
      this.clearEditMode();
    } else {
      this.item.options.value.push(val);
    }
  }

  addKV(key, val) {
    this.item.options.value.push({key: key, val: val});
  }

  formatKV(val) {
    return val.replace('=', ' equals ');
  }

  getKVKey(val: string): string {
    return decodeURIComponent(val.split('=')[0]);
  }

  getKVVal(val: string): string {
    return decodeURIComponent(val.split('=')[1]);
  }

  evenPercentSplit() {
    if (!this.item.options.percentages || !this.item.options.percentages.length) {
      return;
    }

    // set percents for all but last, rounding
    const t = this.item.options.percentages.length;
    let pct = Math.round(100 / t);
    for (let i = 0; i < t - 1; i++) {
      this.item.options.percentages[i] = pct;
    }

    // set last to remainder
    pct = 100 - (Math.round(100 / t) * (t - 1));
    this.item.options.percentages[t - 1] = pct;
  }

  trackByIndex(index, item) {
    return index;
  }
}

@Component({
  selector: 'app-flow-item',
  template: ``
})
export class FlowItemComponent implements OnInit, OnDestroy {
  @Input() showAdd$: Subject<any>;
  @Input() showEdit$: Subject<any>;
  @Input() graph: any;
  @Input() nodeVersion: string;
  @Input() landingPages: LandingPage[];
  @Input() offers: OfferPage[];
  @Input() expEnabled: boolean;
  @Output() added = new EventEmitter<any>(true);
  @Output() edited = new EventEmitter<any>(true);

  constructor(private modalService: NgbModal) {}

  item = {
    original: {},
    type: '',
    options: {}
  };

  private tokens = [
    {
      name: 'ISP',
      value: 'isp',
      type: 'text',
    },
    {
      name: 'State',
      value: 'state',
      type: 'text',
    },
    {
      name: 'Domain',
      value: 'domain',
      type: 'text',
      version: '3.2.0'
    },
    {
      name: 'Country',
      value: 'country',
      type: 'country',
    },
    {
      name: 'Device',
      value: 'mobile',
      type: 'fixed',
      options: ['Mobile', 'Desktop'],
    },
    {
      name: 'URL Param',
      value: 'url_param',
      type: 'kv',
    }
  ];

  private findNode(nodeID: string): any {
    for (const n of this.graph.nodes) {
      if (n.id === nodeID) {
        return n;
      }
    }
    return null;
  }

  private isOnBlockedBranch(nodeID: string): boolean {
    for (const link of this.graph.links) {
      if (link.target === nodeID) {
        const srcNode = this.findNode(link.source);
        if (srcNode === null) { continue; }
        if (srcNode.type === 'noipfraud_blocked_item') {
          return true;
        } else if (srcNode.type === 'noipfraud_allowed_item') {
          return false;
        } else {
          // keep navigating backwards
          return this.isOnBlockedBranch(link.source);
        }
      }
    }
  }

  openAddNode(node) {
    this.item.original = node;

    const pagesExist = (this.landingPages.length >= 1 && this.landingPages[0].name !== '' && this.landingPages[0].url !== '');
    const offersExist = (this.offers.length >= 1 && this.offers[0].name !== '' && this.offers[0].url !== '');

    // determine allowable items at this position
    let types = [];
    switch (node.type) {
      case 'page':
        types = ['percent_split', 'token_split'];
        if (offersExist) {
          types.unshift('offer');
        }
        break;
      case 'noipfraud_blocked_item':
      case 'noipfraud_allowed_item':
        types = ['percent_split', 'token_split'];
        if (offersExist) {
          types.unshift('offer');
        }
        if (pagesExist) {
          types.unshift('page');
        }
        break;
      case 'percent_split_item':
      case 'token_split_item':
        types = ['percent_split', 'token_split'];
        if (offersExist) {
          types.unshift('offer');
        }
        // there can only be 1 page in a branch.
        // look back through the branch to see if there is already a landing page
        const hasPageInBranch = (nodeID: string): boolean => {
          for (const link of this.graph.links) {
            if (link.target === nodeID) {
              const srcNode = this.findNode(link.source);
              if (srcNode === null) { continue; }
              if (srcNode.type === 'page') {
                return true;
              } else {
                return hasPageInBranch(link.source);
              }
            }
          }
        };
        if (!hasPageInBranch(node.id) && pagesExist) {
          types.unshift('page');
        }
        break;
      default:
        // should not happen - invalid type or can not add at this position
        return;
    }
    this.tabChange({nextId: types[0]});

    const modalRef = this.modalService.open(FlowItemContentComponent);
    modalRef.componentInstance.tabChange = this.tabChange;
    modalRef.componentInstance.allowedItemTypes = types;
    modalRef.componentInstance.item = this.item;
    modalRef.componentInstance.nodeVersion = this.nodeVersion;
    modalRef.componentInstance.pages = this.landingPages;
    modalRef.componentInstance.offers = this.offers;
    modalRef.componentInstance.tokens = this.tokens.filter(t => {
      // url params are always allowed
      if (t.name === 'URL Param') {
        return true;
      }

      //exclude tokens not available in node version, ignoring any -beta tags in node version
      if (t.version && this.nodeVersion.replace('-beta', '') < t.version) {
        return false;
      }

      return (!this.expEnabled || !this.isOnBlockedBranch(node.id));
    });
    modalRef.componentInstance.mode = 'adding';

    modalRef.result.then(res => {
      if (res === 'ok') {
        if (this.item.type === 'token_split' && this.item.options['selected'].type === 'kv') {
          const nv = [];
          for (let i = 0, ol = this.item.options['value'].length; i < ol; i++) {
            const kv = this.item.options['value'][i];
            nv.push(kv.key + '=' + kv.val);
          }
          this.item.options['value'] = nv;
        }
        this.added.emit(this.item);
      }
    }, er => {});
  }

  openEditNode(node) {
    this.item.original = node;

    this.item.type = node.type;
    const types = [ node.type ];
    switch (node.type) {
    case 'percent_split':
      this.item.options = {
        percentages: []
      };

      // copy percentages (no reference)
      for (const pct of node.percentages) {
        this.item.options['percentages'].push(pct);
      }
      break;

    case 'token_split':
      // if kv, modify format to { key, value } array
      let nv = [];
      if (node.token.type === 'kv') {
        for (let i = 0; i < node.values.length; i++) {
          const s = node.values[i].split('=');
          nv.push({key: decodeURIComponent(s[0]), val: decodeURIComponent(s[1])});
        }
        // node.values = nv;
      } else {
        nv = node.values;
      }

      // copy values so not working with reference
      this.item.options = {
        selected: {},
        value: []
      };
      this.item.options['selected'] = JSON.parse(JSON.stringify(node.token));
      this.item.options['value'] = JSON.parse(JSON.stringify(nv));
      break;
    }

    const modalRef = this.modalService.open(FlowItemContentComponent);
    modalRef.componentInstance.allowedItemTypes = types;
    modalRef.componentInstance.item = this.item;
    modalRef.componentInstance.tokens = this.tokens;
    modalRef.componentInstance.mode = 'editing';
    modalRef.componentInstance.nodeVersion = this.nodeVersion;

    modalRef.result.then(res => {
      // convert to key=value if KV
      if (this.item.type === 'token_split' && this.item.options['selected'].type === 'kv') {
        const nv = [];
        for (let i = 0; i < this.item.options['value'].length; i++) {
          const kv = this.item.options['value'][i];
          nv.push(kv.key + '=' + kv.val);
        }
        this.item.options['value'] = nv;
      }

      if (res === 'ok') {
        // emit item
        this.edited.emit(this.item);
      }
    }, er => {});
  }

  tabChange(event: any) {
    const type = event.nextId;

    // fill default options
    switch (type) {
      case 'page':
        break;
      case 'offer':
        break;
      case 'percent_split':
        this.item.options = {
          percentages: [50, 50]
        };
        break;
      case 'token_split':
        this.item.options = {
          selected: '',
          value: []
        };
        break;
    }

    this.item.type = type;
  }

  ngOnInit() {
    if (this.showAdd$) {
      this.showAdd$.subscribe(node => this.openAddNode(node));
    }
    if (this.showEdit$) {
      this.showEdit$.subscribe(node => this.openEditNode(node));
    }
  }

  ngOnDestroy() {
  }
}
