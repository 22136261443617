import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../api.service';
import { saveAs } from 'file-saver';
import { AlertService } from '../alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-migrate-dialog',
  templateUrl: './migrate-dialog.component.html',
  styleUrls: ['./migrate-dialog.component.css']
})
export class MigrateDialogComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private alertService: AlertService,
    private router: Router
  ) { }

  performingAction: boolean;
  action: string;
  exported: boolean;
  step: number = 0;

  importFile: File;

  exportData() {
    this.performingAction = true;
    // do http request, then close active modal
    this.apiService.exportAccount()
    .finally(() => this.performingAction = false)
    .subscribe(data => {
      const blob = new Blob([data], { type: 'application/json' });
      saveAs(blob, "N2-Account-Data.json");
      this.step = this.step + 1;
    })
  }
  prepareFile(fileList: File[]) {
    if (fileList && fileList.length) {
      this.importFile = fileList[0];
    }
  }

  navigateToLogin() {
    this.router.navigate(['/'])
  }

  importData() {
    // convert file to form data
    const formData = new FormData();
    formData.append('import', this.importFile);
    
    // post account data
    this.performingAction = true;
    this.apiService.importAccount(formData)
    .finally(() => this.performingAction = false)
    .subscribe(() => {
      // import successful
      this.step = this.step + 1;
    }, error => {
      this.alertService.add('Something went wrong importing your data. Please contact support.', 'danger');
    });
  }

  ngOnInit() {
    // skip exporting step
    if (this.action === 'export') {
      this.step = (this.exported) ? 2 : 0;
    };
  }
  
}
