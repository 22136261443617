import { Component, ViewChild, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { Campaign } from '../../campaigns';

import { NgbTypeahead, NgbTypeaheadSelectItemEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';

import { UserSettingsService } from '../../user-settings.service';
import { NoipfraudOptionsComponent } from './noipfraud-options/noipfraud-options.component';
import { CampaignEditService } from '../../campaign-edit-service';

export interface PageStateChange {
  state?: string;
  action: string;
}

const PRESERVED_ROUTES: string[] = ['/cv', '/ping', '/events'];

@Component({
  selector: 'app-campaign-add-edit-v2',
  templateUrl: './campaign-add-edit.component.html',
  styleUrls: ['./campaign-add-edit.component.css']
})
export class V2CampaignAddEditComponent implements OnInit {
  @Input() campaign: Campaign;
  @Input() nodeVersion: string;
  @Input() campImported: boolean;
  @Input() existingRoutes: string;
  @Output() saved = new EventEmitter<Campaign>();

  @ViewChild('campForm') campForm;
  @ViewChild('tagSearchInstance') tagSearchInstance: NgbTypeahead;

  oex: boolean;
  sc1: boolean;
  tab = 0;
  campaignPagesState: string;
  campaignOfferPagesState: string;
  flowGenerating: boolean;
  flowTouched: boolean;
  flowValid: boolean;
  tagEntry: any;
  tagFocus$ = new Subject<string>();
  tagClick$ = new Subject<string>();
  autoGenerate$ = new Subject();
  updateFlow$ = new Subject();
  inputEventDebouncer = new Subject();

  constructor(
    private userSettingsService: UserSettingsService,
    private modalService: NgbModal,
    private campaignEditService: CampaignEditService
  ) { }

  tagSearch = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .merge(this.tagFocus$)
      .merge(this.tagClick$.filter(() => !this.tagSearchInstance.isPopupOpen()))
      .map(term => {
        term = term.trim();
        const tags = this.userSettingsService.cache['tags'].data;
        const add: any[] = [ { action: 'add', actionDesc: `Add new tag`, name: term.toLocaleLowerCase() } ];
        const search = tags.filter(v =>
            v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
        if (term.length > 0 && this.userSettingsService.cache['tags'].data.indexOf(term.toLowerCase()) === -1) {
          return add.concat(search);
        } else {
          return search;
        }
      })

  addTag(ev: NgbTypeaheadSelectItemEvent) {
    ev.preventDefault();
    this.tagEntry = null;

    const val = ev.item;

    let name = '';
    if (val.action && val.action === 'add') {
      name = val.name.trim();
      // add to tags list so that new tags appear in the list
      // even though they can't be re-added to the same camp, i think it's more intuitive
      if (name.length > 0 && this.userSettingsService.cache['tags'].data.indexOf(name) === -1) {
        this.userSettingsService.cache['tags'].push(name);
      }
    } else {
      name = val.trim();
    }

    // init array if not found
    if (!this.campaign.user_tags) {
      this.campaign.user_tags = [];
    }

    // exit if duplicate or invalid
    if (name.length === 0 || this.campaign.user_tags.indexOf(name) > -1) {
      return;
    }

    // add tag
    this.campaign.user_tags.push(name);
  }

  campaignPagesValid(): boolean {
    if (this.campaignPagesState === 'invalid' || this.campaignOfferPagesState === 'invalid') {
      return false;
    }
    if (this.campaignPagesState === 'empty' && this.campaignOfferPagesState === 'invalid') {
      return false;
    }
    if (this.campaignOfferPagesState === 'empty' && this.campaignPagesState === 'invalid') {
      return false;
    }
    return true;
  }

  routeValid(): boolean {
    const r = (this.campaign.route.length > 0 && this.campaign.route.substr(0,1) === "/") ? this.campaign.route : '/' + this.campaign.route; 
    return PRESERVED_ROUTES.concat(this.existingRoutes).indexOf(r) === -1;
  }

  showNoipfraudOptions() {
    const modalRef = this.modalService.open(NoipfraudOptionsComponent, {size: 'lg'});

    // deep copy noipfraud settings
    modalRef.componentInstance.settings = JSON.parse(JSON.stringify(this.campaign.noipfraud));
    modalRef.componentInstance.nodeVersion = this.nodeVersion;

    modalRef.result.then(res => {
      if (res !== 'cancel') {
        this.campaign.noipfraud = res;
        this.updateFlow$.next();
      }
    }, er => {});
  }


  flowUpdatedEvent(flow: any) {
    this.flowTouched = flow.touched;
    this.campaign.flow.objects = flow.graph.nodes;
    this.campaign.flow.links = flow.graph.links;
    this.flowValid = flow.valid;
    this.flowGenerating = false;

    this.emitValidState();
  }

  pageInputEvent(inputEvent: string) {
    this.flowGenerating = true;

    if (inputEvent === 'modify') {
      this.inputEventDebouncer.next();
      return;
    }

    if (inputEvent === 'reset') {
      this.autoGenerate$.next();
    }
  }

  cpStateChange(s: PageStateChange) {
    if (s.state) {
      this.campaignPagesState = s.state;
    }
    if (this.campaignPagesState !== 'invalid' && this.campaignOfferPagesState !== 'invalid') {
      this.pageInputEvent(s.action);
    }

    this.emitValidState();
  }

  coStateChange(s: PageStateChange) {
    if (s.state) {
      this.campaignOfferPagesState = s.state;
    }
    if (this.campaignOfferPagesState !== 'invalid' && this.campaignPagesState !== 'invalid') {
      this.pageInputEvent(s.action);
    }

    this.emitValidState();
  }

  emitValidState() {
    this.campaignEditService.campaignSetupValid.emit((!this.flowGenerating &&
      this.flowValid &&
      this.campaignPagesValid() &&
      this.routeValid() && 
      this.campForm.valid));
  }

  ngOnInit() {
    this.flowValid = true;
    if (this.campaign.id === '') {
      // new campaign, flow starts invalid
      this.flowValid = false;
    }

    if (this.campaign.id !== '') {
      this.flowTouched = true;
    }

    this.inputEventDebouncer.debounceTime(500)
    .subscribe(() => {
      if (this.flowTouched) {
        this.updateFlow$.next();
      } else {
        this.autoGenerate$.next();
      }
    });

  }
}
