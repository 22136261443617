import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { openCheckout } from '../chargebee';
import { AuthService } from '../auth/auth.service';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-subscribe-dialog',
  templateUrl: './subscribe-dialog.component.html',
  styleUrls: ['./subscribe-dialog.component.css']
})
export class SubscribeDialogComponent implements OnInit {

  // account is passed to modal 
  account: Account;

  alive: boolean;
  subscribed: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService
  ) {
    this.alive = true;
  }

  subscribe() {
    openCheckout(this.account.id, () => {
      var that = this;
      return {
        success: function() {
          that.subscribed = true;
        },
      }
    });
  }

  backgroundSubscriptionCheck() {
    TimerObservable.create(0, 5000)
    .pipe( takeWhile(() => this.alive) )
    .subscribe(() => {
      // observable timer with refresh account call
      this.authService.refreshAccount().subscribe(
        account => {
          // account has an active subscription once it has a cb_id
          if (account.data.cb_id != '') {
            this.activeModal.close();
          }
        }
      );
    });
  }
    
  ngOnInit() {
    this.backgroundSubscriptionCheck();
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
