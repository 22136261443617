// https://auth0.com/docs/quickstart/spa/angular2/

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { of, timer } from 'rxjs';

import * as auth0 from 'auth0-js';

import { environment } from '../../environments/environment';

import { setPortalSession } from '../chargebee';

const API_URL = environment.apiUrl;
const CLIENT_ID = environment.auth0.clientID;
const LOGOUT_URI = environment.auth0.logoutUri;
const AUTH0_DOMAIN = environment.auth0.domain;

@Injectable()
export class AuthService {

  auth0 = new auth0.WebAuth(environment.auth0);

  auth0Manage: any;

  refreshSubscription: any;
  userProfile: any;
  user: any;
  account: any;
  teams: any;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        // set session
        this.setSession(authResult);

        // get user
        this.refreshUser().subscribe(
          result => {
            // check that user has access to the selected account
            let hasAccess = false;
            for (let i = 0; i < this.user.access.length; i++) {
              if (this.user.access[i].id === this.user.selected_account_id) {
                hasAccess = true;
                break;
              }
            }

            // if user does not have access, redirect to account selection
            // else, go to overview
            if (!hasAccess) {
              this.router.navigate(['/accounts']);
            } else {
              this.router.navigate(['/']);
            }
          },
          error => {
            this.logout();
          }
        );
      }
    });
  }

  public renewToken() {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        this.setSession(result);
      }
    });
  }

  private setSession(authResult): void {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    this.auth0Manage = new auth0.Management({
      domain: AUTH0_DOMAIN,
      token: authResult.accessToken
    });

    this.scheduleRenewal();
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.unscheduleRenewal();

    // Go back to the login page
    this.auth0.logout({
      returnTo: LOGOUT_URI,
      client_id: CLIENT_ID
    });
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  public getProfile(cb): void {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access Token must exist to fetch profile');
    }

    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  public refreshUser(): Observable<any> {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access Token must exist to fetch profile');
    }

    return new Observable(observer => {
      return this.http.get<any>(`${API_URL}/user`, {
        headers: {'Authorization': 'Bearer ' + accessToken}
      }).subscribe(
        result => {
          this.user = result.data;
          observer.next(result);
          observer.complete();
        }
      );
    });
  }

  public refreshAccount(): Observable<any> {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access Token must exist to fetch account');
    }

    return new Observable(observer => {
      return this.http.get<any>(`${API_URL}/account`, {
        headers: {'Authorization': 'Bearer ' + accessToken}
      }).subscribe(
        result => {
          this.account = result.data;
          observer.next(result);
          observer.complete();

          setPortalSession(() => {
            return this.http.post<any>(`${API_URL}/generateportalsession`, {
              headers: {'Authorization': 'Bearer ' + accessToken}
            }).toPromise();
          });
        }
      );
    });
  }

  public refreshUserTeams(): Observable<any> {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access Token must exist to fetch profile');
    }

    return new Observable(observer => {
      return this.http.get<any>(`${API_URL}/teams`, {
        headers: {'Authorization': 'Bearer ' + accessToken}
      }).subscribe(
        result => {
          this.teams = result.data;
          observer.next(result);
          observer.complete();
        }
      );
    });
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) { return; }
    this.unscheduleRenewal();

    const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));

    const expiresIn$ = of(expiresAt).pipe(
      mergeMap(
        expires => {
          const now = Date.now();
          // Use timer to track delay until expiration
          // to run the refresh at the proper time
          return timer(Math.max(1, expires - now));
        }
      )
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = expiresIn$.subscribe(
      () => {
        this.renewToken();
        this.scheduleRenewal();
      }
    );
  }

  public unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

}
